//
// Main
//

// Body
body {
  background-color: #F2F2F2;
  font-family: Poppins, 'sans-serif';
 }
 body {
  .aside-dark .hover-scroll-overlay-y:hover::-webkit-scrollbar-thumb {
  background-color: #f1bc00;
  }
  .aside-dark .hover-scroll-overlay-y::-webkit-scrollbar-thumb {
  background-color: #f1bc00;
  }
}

// Font color from Content background color
.text-page-bg {
  color: $page-bg;
}

.welcome-banner-sec.banner-sec .slick-slider{
    height: 68px;
    @media  only screen and (max-width:991.98px) {
       height: auto;
   }
}
.overflowy-auto{
        overflow-x: hidden;
        overflow-y: auto;
}
.overflowx-auto{
        overflow-y: hidden;
        overflow-x: auto;
}
.btn-theme{
background:#F3B521;
color: #49484c;
font-weight: 500;
      border-radius: 5px;
      border: 1px solid #F3B521 !important;
}
.btn-theme{
&:hover,
&:focus{
      background:#49484c;
      color: #FFFFFF;
      border-radius: 5px;
      border: 1px solid #49484c !important;
      }
}
.filter-btns{
  display: flex;
  .btn-outline{
      border-radius: 5px;
      padding: 7px 20px;
      cursor: pointer;
      height: 40px;
      text-align: center;
      margin-right: 12px;
      @media  only screen and (max-width:575.98px) {
          height: 35px;
          font-size: 12px;
          padding: 7px 0;
      }
  }
  .price-clear-btn{
      color: #fff;
      background: #49484c;
      border-radius: 5px;
      padding: 7px 20px;
      cursor: pointer;
      height: 40px;
      @media  only screen and (max-width:575.98px) {
          height: 35px;
          font-size: 12px;
          padding: 7px 0;
      }
  }
}
#reschedule-popup{
  .modal-dialog{
    max-width:fit-content;
  }
}
.modal-dialog{
  max-width: 460px;
}
.close-icon{
  float: right;
}.price-slider-home {
  width: 254px;
  border-radius: 5px !important;
  padding: 10px 6px !important;
  @media (min-width: 1500px) {
     max-width: 296px;
     width: 100%;
  }
  .ant-tooltip {
      z-index: 9999999 !important;
  }
  .filter-btns {
      display: inline-flex;
      justify-content: flex-end;
      width: 100%;
      .price-clear-btn {
          max-width: 80px;
          width: 80px;
          margin: 8px 0 0;
          font-size: 13px;
          border-radius: 5px;
          height: 34px;
          @media (min-width: 1500px) {
              max-width: 100px;
              width: 100px;
          }
      }
      .btn-outline {
          background-color: #ffffff;
          border: 1px solid #49484c;
          color: #49484c;
          max-width: 80px;
          width: 80px;
          margin: 8px 8px 0;
          font-size: 13px;
          border-radius: 5px;
          height: 34px;
          @media (min-width: 1500px) {
              max-width: 100px;
              width: 100px;
          }
          &:hover {
              background-color: #49484c;
              border: 1px solid #49484c;
              color: #ffffff;
              transition: inherit;
          }
      }    
  }
  .ant-slider {
      margin-bottom: 3px;
      &:hover {
          .ant-slider-track {
              background-color: #49484c;
          }
          .ant-slider-handle:not(.ant-tooltip-open) {
              border-color: #49484c;
          }  
      }
      .ant-slider-track {
          background-color: #49484c;
          height: 2px;
      }
      .ant-slider-handle {
          border: solid 1px #707070;
          width: 18px;
          height: 18px;
          margin-top: -9px;
      }
  }
  .ant-dropdown-menu-item {
      padding-top: 0;
      .min-text {
          display: block;
          font-size: 13px;
          color: #252525;
          font-weight: 400;
      }
      .price-value {
          display: flex;
          .ant-input {
              color: #252525;
              border-radius: 5px;
              padding-top: 0;
              border: 0;
              font-size: 12px;
              padding-bottom: 0;
              padding-left: 3px;
              padding-right: 0;
              &:focus,
              &:hover {
                  box-shadow: none;
                  outline: 0;
                  border-color: inherit;
              }
          }
      }
      .fil-title-sec {
          .ant-typography {
              font-size: 14px;
              color: #252525;
              font-weight: 400;    
              margin: 0 auto;
          }
          h5.ant-typography {
              @media  only screen and (max-width:575.98px) {
                  font-size: 14px;
              }
          }
          .close-icon {
              span {
                  color: rgba(0,0,0,0.5);    
              }
          }
      }
      .bottom-border {
          border-bottom: 1px solid rgba(0,0,0,0.1);
          padding-bottom: 12px;
          margin-bottom: 2px;    
      }
  }
  .ant-dropdown-menu-item:hover, 
  .ant-dropdown-menu-submenu-title:hover {
      background-color: unset;
  }
}
@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100%;
  }
}

// Desktop Mode
@include media-breakpoint-up(lg) {

  // Containers
  .container,
  .container-fluid {
    padding: 0 get($content-spacing, desktop);
  }

  .container .page-title[data-kt-swapper='true'] {
    display: none !important;
  }

  .container-fluid .page-title[data-kt-swapper='true'] {
    display: none !important;
  }

  // Wrapper
  .wrapper {
    transition: padding-left get($aside-config, transition-speed) ease,
      margin-right get($aside-config, transition-speed) ease;

    // Aside default enabled and aside fixed modes
    .aside-enabled.aside-fixed & {
      transition: padding-left get($aside-config, transition-speed) ease;
      padding-left: get($aside-config, width);
    }

    // Aside default enabled, aside fixed and aside minimize modes
    .aside-enabled.aside-fixed[data-kt-aside-minimize='on'] & {
      transition: padding-left get($aside-config, transition-speed) ease;
      padding-left: get($aside-config, minimized-width);
    }

    // Fixed header mode
    .header-fixed & {
      padding-top: get($header-config, fixed, height, desktop);
    }

    // Fixed header & fixed toolbar modes
    .header-fixed.toolbar-fixed & {
      padding-top: calc(55px + var(--kt-toolbar-height));
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {

  // Containers
  .container,
  .container-fluid {
    max-width: none;
    padding: 0 get($content-spacing, tablet-and-mobile);
  }

  .container .page-title[data-kt-swapper='true'] {
    display: flex !important;
  }

  .container-fluid .page-title[data-kt-swapper='true'] {
    display: flex !important;
  }

  // Wrapper
  .wrapper {

    // Fixed header mode
    .header-tablet-and-mobile-fixed & {
      padding-top: 20px;

      @media (max-width:575.98px) {
        // padding-top: 20px;
      }
      @media  (min-width:575.98px) and (max-width: 767.98px) {
        padding-top: 20px;
      }
      @media  (min-width:767.98px) and (max-width: 991.98px) {
        padding-top: 70px;
      }
    }

    // Fixed header & fixed toolbar modes
    //.header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed & {
    //  padding-top: calc(
    //          #{get($header-config, fixed, height, tablet-and-mobile)} + var(--kt-toolbar-height-tablet-and-mobile)
    //  );
    //}
  }
}

.text-theme {
  color: #49484c;
}

.text-primary {
  color:#49484c !important;
}

.text-primary2 {
  color: #009ef7 !important
}

.text-theme-primary {
  color: #000 !important;
}

.text-blue-theme {
  color: #49484c;
}

.text-theme-gray {
  color: #AEAEAE;
}

.text-theme-black {
  color: #252525 !important;
}

.text-black {
  color: #000000 !important;
}

.link-theme {
  color: #BEBEBE;
}

.scrolltop {
  background-color: #49484c;
  right: 90px;
}

.bg-light-gray {
  background-color: rgba(242, 242, 242, 0.5);
}

.bg-white {
  background-color: #FFFFFF;
}

.c-pointer {
  cursor: pointer;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.btn-outline-primary {
  border-color: #49484c;
  color:#49484c;
  border-radius: 5px;
  border: 1px solid;

  &:active {
    color: #fff;
    background-color: #00387f;
    border-color: #00387f;
    text-align: center !important;
  }

  .active {
    color: #fff;
    background-color: #00387f;
    border-color: #00387f;
    text-align: center !important;
  }
}

.btn-check:checked+.btn-outline-primary,
.btn-check:active+.btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
  color: #ffffff;
  background-color: #00387f;
  border-color: #00387f;
}

.btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
  border: 1px solid;
}

.btn-primary {
  background-color: #49484c !important;
  border-color: #49484c !important;
  color: #ffffff !important;
  border-radius: 5px;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-check:checked+.btn.btn-primary,
.btn-check:active+.btn.btn-primary,
.btn.btn-primary:focus:not(.btn-active),
.btn.btn-primary:hover:not(.btn-active),
.btn.btn-primary:active:not(.btn-active),
.btn.btn-primary.active,
.btn.btn-primary.show,
.show>.btn.btn-primary {
  background-color: #F3B521 !important;
  border-color: #F3B521 !important;
  color: #000 !important;
}

.btn-outline-primary:hover,
.btn.btn-outline-primary:focus:not(.btn-active),
.btn.btn-outline-primary:hover:not(.btn-active),
.btn.btn-outline-primary:active:not(.btn-active),
.btn.btn-outline-primary.active,
.btn.btn-outline-primary.show,
.show>.btn.btn-outline-primary {
  background-color: #49484c  !important;
  border-color: #49484c  !important;
-webkit-transition: all 0.6s ease;
transition: all 0.6s ease;
  color: #ffffff !important;
}


.btn-check:checked+.btn.btn-active-light-primary,
.btn-check:active+.btn.btn-active-light-primary,
.btn.btn-active-light-primary:focus:not(.btn-active),
.btn.btn-active-light-primary:hover:not(.btn-active),
.btn.btn-active-light-primary:active:not(.btn-active),
.btn.btn-active-light-primary.active,
.btn.btn-active-light-primary.show,
.show>.btn.btn-active-light-primary {
  color: #49484c !important;
  border-color: #EFF2F6 !important;
  background-color: #EFF2F6 !important;
}

.menu-state-primary .menu-item.hover>.menu-link:not(.disabled):not(.active),
.menu-state-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  color: #49484c !important;
  background-color: #F2F2F3 !important;
}

.menu-state-primary .menu-item.hover>.menu-link:not(.disabled):not(.active) .menu-title,
.menu-state-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title {
  color: #49484c !important;
}

.menu-state-primary .menu-item .menu-link.active {
  color: #49484c !important;
}

.font-9 {
  font-size: 9px;
}

.font-10 {
  font-size: 10px;
}

.font-11 {
  font-size: 11px;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px;

  @media only screen and (max-width: 767.98px) {
    font-size: 14px;
  }
}

.font-16 {
  font-size: 16px;
}

.font-17 {
  font-size: 17px !important;

  @media only screen and (max-width: 767.98px) {
    font-size: 15px !important;
  }
}

.font-18 {
  font-size: 18px !important;

  @media only screen and (max-width: 767.98px) {
    font-size: 16px !important;
  }
}

.font-19 {
  font-size: 19px !important;
}

.font-20 {
  font-size: 20px;
}

.font-21 {
  font-size: 21px;
}

.font-22 {
  font-size: 22px;
}

.font-23 {
  font-size: 23px;
}

.font-24 {
  font-size: 24px;
}

.font-25 {
  font-size: 25px;
}

.font-26 {
  font-size: 26px;
}

.font-30 {
  font-size: 30px;
}

.font-34 {
  font-size: 34px;
}

.font-40 {
  font-size: 40px;
}

.rounded-12 {
  border-radius: 12px;
}

.text-light-theme {
  color: #A09F9F;
}

.rounded-6 {
  border-radius: 6px;
}

.top-80 {
  top: 80% !important;
}

.rounded-10 {
  border-radius: 10px;
}

.rounded-23 {
  border-radius: 23px;
}

.rounded-5 {
  border-radius: 5px;
}

.rounded-15 {
  border-radius: 15px !important;
}

.min-h-195px {
  min-height: 195px;
}

.min-h-435px {
  min-height: 435px;
  max-height: 435px;
  height: 100%
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.make-offer-notification,
.create-offer-tabs {
  .nav-link.active,
  .show>.nav-link {
    background-color: #49484c;
    color: #ffff;
  }
  .nav-link {
    background: #E4E4E4;
    font-size: 15px;
    color: #707070;
    border: 0;
    border-radius: 0.475rem;
  }
  .tab_nav_li {
    width: 49%;
  }
}

.min-h-475px {
  min-height: 475px;
}

.min-h-439px {
  min-height: 439px;
}

.min-h-525px {
  min-height: 448px;
}

.min-h-387px {
  min-height: 387px;
}

.h-35 {
  height: 35px;
}

.h-70 {
  height: 70px;
}

.w-95 {
  width: 95%;
}

.float-right {
  float: right;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.bi-volume-mute-fill,
.bi-volume-up-fill {
  color: #49484c;
  font-size: 42px;
  cursor: pointer;
}

.text-capitalize {
  text-transform: capitalize;
}

hr {
  background-color: rgba(0, 0, 0, 0.4);
}

.va-baseline {
  vertical-align: baseline;
}

.btn.btn-light {
  color: #49484c;
  border-color: #E5E9F0 !important;
  background-color: #E5E9F0;
}

.add-btn-form {
  color: #000000 !important;
  border: 0 !important;
  background-color: #FAFAFA !important;

  .svg-icon {
    margin-right: 0;
    margin-left: 7px;

    svg {
      height: 22px !important;
      width: 26px !important;

      rect {
        fill: #000000 !important;
      }
    }
  }
}

.noUi-horizontal {
  height: 5px;
}

.noUi-target {
  background: #F3B521;
  border-radius: 30px;
  border: 0px solid transparent;
  box-shadow: none;
}

.noUi-horizontal .noUi-handle {
  width: 18px;
      height: 18px;
      top: -7px;
      right: -7px;
  border-radius: 50%;
  border: 1px solid #F3B521;
  background: #FFF;
  box-shadow: 0 .1rem 0.8rem rgba(0, 0, 0, .15) !important;
  cursor: pointer;
}

.noUi-handle:before,
.noUi-handle:after {
  content: "";
  background: inherit;
  position: absolute;
  width: 200px;
  left: 0;
  height: 4px;
  background-color: transparent;
}

.aside-dark .menu .menu-item.hover>.menu-link:not(.disabled):not(.active) .menu-title,
.aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title {
  color:#FFA300 !important;
}

// Login screen
.login{
    .card-body{padding: 10px 0 10px 10px !important;}
}
.login-left {
  img {
    border-radius: 8px !important;
    min-height: calc(100vh - 20px);
    height: calc(100vh - 20px);
    object-fit: cover;

    @media (max-width: 767px) {
      display: none;
    }
  }
}

.login-right {
  .form-control.form-control-solid {
    background-color: #F5F5F5;
    border-color: #F5F5F5;
    color: #808186;
    font-weight: 500;
    font-size: 12px;
    min-height: calc(1.5em + (2rem + 2px));
  }
}

.footer {
  //   margin-left: 22px;
  margin-right: 15px;
  margin-top: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.login-password {
  position: relative;

  button {
    position: absolute;
    right: 0;
    top: 0;
  }
}

// Main Left Navbar
@media (min-width: 992px) {
  .aside {
    width: 245px !important;
    //margin-left: 20px;
    margin-top: 20px;
    border-radius: 8px 40px 8px 8px;
    margin-bottom: 20px;
    height: calc(100vh - 40px);
    box-shadow: 5px 0px 16px 0px #00000029;
    .aside-menu {
      width: 245px !important;
      .hover-scroll-overlay-y {
        height: calc(100vh - 150px) !important;
        overflow-y: auto;
        overflow-x: hidden;
     }
    }
    .aside-menu.flex-column-fluid {
      flex: inherit;
    }
  }
  // .aside-enabled.aside-fixed.header-fixed .header.align-items-stretch {
  //   margin-left: 25px;
  //   margin-right: 15px;
  //   padding-top: 20px;
  //   //border-radius: 10px;
  //   //box-shadow: 0 10px 30px 0 rgb(82 63 105 / 5%) !important;
  //   //border: 1px solid #F2F2F2;
  //   background-color: #F2F2F2;
  //   border-radius: 0 0 10px 10px;
  //   height: 85px;
    .aside-enabled.aside-fixed.header-fixed .header.align-items-stretch {
      margin-left: 0;
      margin-right: 0;
      background-color: #F2F2F2;
      border-radius: 0;

    .start-70 {
      left: 70%;
    }

    .top-5 {
      top: 5px;
    }

    .bg-warning {
      background-color: #FED713 !important;
    }

    .bg-danger {
      background-color: #FE7C96 !important;
    }

    span.user-name {
      margin-left: 30px;
      margin-right: 20px;

      img {
        border: 1px solid #f2f2f2;
      }
    }

    span.user-name::before {
      content: "";
      position: absolute;
      border-left: 1px solid #DDDDDD;
      display: inline-block;
      height: 35px;
      left: 0;
      top:12px;
    }
  }

  .aside-enabled.aside-fixed.toolbar-fixed .toolbar {
    display: none;
  }

  .content {
    padding: 12px 0 !important;

    .container,
    .container-fluid,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl,
    .container-xxl {
      padding: 0 15px 0 0;
    }
  }

  .modal-lg{
    max-width: 800px;
  }

}

.aside-enabled.aside-fixed.toolbar-fixed .toolbar {
  display: none;
}

.aside-enabled.aside-fixed.header-fixed .header.align-items-stretch {
  >.container-fluid {
    padding: 0 15px 0 15px;
    border-radius: 0px;
    box-shadow: 0 10px 30px 0 rgb(82 63 105 / 5%) !important;
    border: 1px solid #F2F2F2;
    background-color: #FFFFFF;
  }

  .form-select.form-select-solid {
    background-color: #FAFAFA;
    border-color: #FAFAFA;
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    background-image: url("../../../assets/images/Dropdown_circle.png") !important;
    background-size: 13px 13px !important;
    background-position: right 1.2rem center;
    padding-left: 15px;
    min-width: 250px;
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    option{
      padding: 10px;
      &:hover{
        background-color: #fde3a4;
      }
    }
  }

  .top-search-wrapper {
    .wrapper {
      padding: inherit;
      position: inherit;
      display: inherit;
      box-shadow: inherit;
      background-color: #FAFAFA;
      border-color: #FAFAFA;
      color: #000000;
      font-size: 14px;
      font-weight: 400;
      border-radius: 5px;
      max-width: 100%;
      width: 250px;
      font-family: Poppins, "sans-serif";

      svg.search-icon {
        position: absolute;
        right: 0;
        margin: 0 10px 0 22px;
        fill: #707070;
        cursor: pointer;
      }

      .clear-icon {
        margin: 0 35px 0 0;

        svg {
          fill: #707070;
        }
      }

      input {
        background-color: transparent;
        color: #000000;
        font-size: 14px;
        font-weight: 400;
        font-family: Poppins, "sans-serif";
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &::-webkit-input-placeholder {
          color: #000000;
          font-size: 14px;
          font-weight: 500;
        }

        &:-ms-input-placeholder {
          color: #000000;
          font-size: 14px;
          font-weight: 500;
        }

        &::placeholder {
          color: #000000;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .input-group-text {
      background-color: #FAFAFA !important;
      border-color: #FAFAFA !important;
      cursor: pointer;
    }

    .form-control {
      background-color: #FAFAFA;
      border-color: #FAFAFA;
      color: #000000;
      font-size: 14px;
      font-weight: 400;

      &::-webkit-input-placeholder {
        color: #000000;
        font-size: 14px;
        font-weight: 500;
      }

      &:-ms-input-placeholder {
        color: #000000;
        font-size: 14px;
        font-weight: 500;
      }

      &::placeholder {
        color: #000000;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .bg-warning {
    background-color: #FED713 !important;
  }

  .bg-danger {
    background-color: #F6A41C !important;
  }

  .notif-ioc::after {
    content: "";
    background-position: inherit;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url("../../../assets/images/Notification.png");
    width: 23px;
    height: 25px;
  }

  .email-ioc::after {
    content: "";
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url("../../../assets/images/Email.png");
    width: 17px;
    height: 13px;
  }
}

.menu-gray-600 .menu-item .menu-link {
  color: #49484c;
  font-size: 14px;
  white-space: normal;
  justify-content: space-between;
  display: flex;
  padding: 13px;

  &:hover:not(.disabled):not(.active)>span.user-ioc::after {
    content: "";
    background-image: url("../../../assets/images/property-management-icons/User_active.svg");
    // filter: brightness(0.5);
  }

  &:hover:not(.disabled):not(.active)>span.doc-ioc::after {
    content: "";
    background-image: url("../../../assets/images/property-management-icons/Documents_active.svg");
    // filter: brightness(0.5);
  }

  &:hover:not(.disabled):not(.active) > span.chat-ioc::after {
    content: "";
    background-image: url("../../../assets/images/property-management-icons/Chat_with_us_active.svg");
  }

  &:hover:not(.disabled):not(.active) > span.srch-ioc::after {
    content: "";
    background-image: url("../../../assets/images/property-management-icons/property-search-active.svg");
  }

  &:hover:not(.disabled):not(.active)>span.my-activity-ioc::after {
    content: "";
    background-image: url("../../../assets/images/property-management-icons/My_offer_Active.svg");
    // filter: brightness(0.5);
  }

  &:hover:not(.disabled):not(.active)>span.my-alert-ioc::after {
    content: "";
    background-image: url("../../../assets/images/property-management-icons/My_alert_Active.svg");
  }

  &:hover:not(.disabled):not(.active) > span.quest-ioc::after {
    content: "";
    background-image: url("../../../assets/images/property-management-icons/Question.svg");
  }

  &:hover:not(.disabled):not(.active) > span.settings-ioc::after {
    content: "";
    background-image: url("../../../assets/images/property-management-icons/Settings_active.svg");
  }

  &:hover:not(.disabled):not(.active)>span.logout-ioc::after {
    content: "";
    background-image: url("../../../assets/images/property-management-icons/power_active.svg");
    // filter: brightness(0.5);
  }

  .item-icon::after {
    content: "";
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    //margin-right: 15px;
  }

  .user-ioc::after {
    content: "";
    background-image: url("../../../assets/images/property-management-icons/User_inactive.svg");
    width: 18px;
    height: 18px;
  }

  .doc-ioc::after {
    content: "";
    background-image: url("../../../assets/images/property-management-icons/Documents_inactive.svg");
    width: 18px;
    height: 18px;
  }

  .chat-ioc::after {
    content: "";
    background-image: url("../../../assets/images/property-management-icons/Chat_with_us _inactive.svg");
    width: 18px;
    height: 18px;
    background-size: contain;
  }

  .srch-ioc::after {
    content: "";
    background-image: url("../../../assets/images/property-management-icons/property-search-inactive.svg");
    width: 18px;
    height: 18px;
    background-size: contain;
  }

  .my-activity-ioc::after {
    content: "";
    background-image: url("../../../assets/images/property-management-icons/My_Offer_Inactive.svg");
    width: 18px;
    height: 18px;
    background-size: contain;
  }

  .my-alert-ioc::after {
    content: "";
    background-image: url("../../../assets/images/property-management-icons/My_Alerts_Inactive.svg");
    width: 18px;
    height: 18px;
    background-size: contain;
  }

  .quest-ioc::after {
    content: "";
    background-image: url("../../../assets/images/property-management-icons/Question_inactive.svg");
    width: 18px;
    height: 18px;
    background-size: contain;
  }

  .settings-ioc::after {
    content: "";
    background-image: url("../../../assets/images/property-management-icons/Settings_Inactive.svg");
    width: 18px;
    height: 18px;
    background-size: contain;
  }

  .logout-ioc::after {
    content: "";
    background-image: url("../../../assets/images/property-management-icons/power_inactive.svg");
    width: 18px;
    height: 18px;
  }
}

.aside.aside-dark {
  .aside-footer {
    .form-control.form-control-solid {
      background-color: #ffffff !important;
      border-color: rgba(0, 0, 0, 0.1);
      color: #9C9FA6;
      min-width: 207px;
      width: 207px;
      margin: 0 auto;
      font-weight: 500;
      border-radius: 7px;
      font-size: 14px;
    }
  }

  .menu .menu-item .menu-link .menu-title {
    color: #9C9FA6;
    font-weight: 600;
    font-size: 14px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .menu .menu-item .menu-link.active .menu-title,
  .menu .menu-item:hover .menu-link .menu-title,
  .menu .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title,
  .menu .menu-item .menu-link:hover .menu-title {
    color:#FFA300;
  }

  .menu .menu-item .menu-link.active,
  .menu .menu-item .menu-link:hover {
    // background-color:#EFF2F6;
    background-color: transparent;
    color:#49484c;
    border-radius: 10px;
    //margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .aside-menu .menu-item .menu-link {
    // padding-top: 15px;
    // padding-bottom: 15px;

    span.menu-icon::after {
      content: "";
      background-position: center;
      background-repeat: no-repeat;
      display: inline-block;
    }
  }

  .aside-menu .dash-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/Dashboard_inactive.svg");
      width: 20px;
      height: 20px;
    }
  }
  // .menu-item .menu-link.active, .menu-item .menu-link:hover {
  //   span.menu-icon::after{
  //     filter: brightness(0.5);
  //   }
  // }

  .aside-menu .dash-icon-menu .menu-item .menu-link.active,
  .aside-menu .dash-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/Dashboard.svg");
    }
  }

  .aside-menu .activity-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/Work_inactive.svg");
      width: 20px;
      height: 20px;
    }
  }

  .aside-menu .activity-icon-menu .menu-item .menu-link.active,
  .aside-menu .activity-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/Work_active.svg");
    }
  }

  .aside-menu .alert-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/Notification_inactive.svg");
      width: 20px;
      height: 20px;
    }
  }

  .aside-menu .alert-icon-menu .menu-item .menu-link.active,
  .aside-menu .alert-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/Notification_active.svg");
    }
  }

  .aside-menu .announcement-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/announcement_InActive.png");
      width: 18px;
      height: 18px;
    }
  }

  .aside-menu .announcement-icon-menu .menu-item .menu-link.active,
  .aside-menu .announcement-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/announcement_Active.png");
    }
  }

  .aside-menu .fmsm-myteam-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/fmsm-team-inactive.png");
      width: 18px;
      height: 18px;
    }
  }

  .aside-menu .fmsm-myteam-icon-menu .menu-item .menu-link.active,
  .aside-menu .fmsm-myteam-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/fmsm-team-active.png");
    }
  }

  .aside-menu .reports-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/reports_InActive.png");
      width: 20px;
      height: 20px;
    }
  }

  .aside-menu .reports-icon-menu .menu-item .menu-link.active,
  .aside-menu .reports-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/reports_Active.png");
    }
  }

  .aside-menu .calender-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/calendar_InActive.png");
      width: 20px;
      height: 20px;
    }
  }

  .aside-menu .calender-icon-menu .menu-item .menu-link.active,
  .aside-menu .calender-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/calendar_Active.png");
    }
  }

  .aside-menu .workorder-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/workOrders_InActive.png");
      width: 18px;
      height: 18px;
    }
  }

  .aside-menu .workorder-icon-menu .menu-item .menu-link.active,
  .aside-menu .workorder-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/workOrders_Active.png");
    }
  }

  .aside-menu .myteam-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/MyTeam_InActive.png");
      width: 18px;
      height: 18px;
    }
  }

  .aside-menu .myteam-icon-menu .menu-item .menu-link.active,
  .aside-menu .myteam-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/team_active.svg");
    }
  }

  .aside-menu .unit-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/My_unit_inactive.svg");
      width: 22px;
      height: 18px;
      background-size: contain;
    }
  }

  .aside-menu .unit-icon-menu .menu-item .menu-link.active,
  .aside-menu .unit-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/My_unit_active.svg");
    }
  }

  .aside-menu .mp-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/Marketplace_InActiv.svg");
      width: 21px;
      height: 21px;
    }
  }

  .aside-menu .mp-icon-menu .menu-item .menu-link.active,
  .aside-menu .mp-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/Marketplace_Active.svg");
    }
  }

  .aside-menu .maint-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/Maintenance_inactive.svg");
      width: 21px;
      height: 21px;
    }
  }
  .aside-menu .maint-icon-menu .menu-item .menu-link.active,
  .aside-menu .maint-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/Maintenance_active.svg");
    }
  }

  .aside-menu .my-serv-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/My_service_INactive.svg");
      width: 20px;
      height: 20px;
      background-size: contain;
    }
  }

  .aside-menu .my-serv-icon-menu .menu-item .menu-link.active,
  .aside-menu .my-serv-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/My_Services_Active.svg");
    }
  }

  .aside-menu .payment-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/Payments_In_active.svg");
      width: 21px;
      height: 21px;
      background-size: contain;
    }
  }

  .aside-menu .payment-icon-menu .menu-item .menu-link.active,
  .aside-menu .payment-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/Payments_Active.svg");
    }
  }

  .aside-menu .announce-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/Annoucement_inactive.svg");
      width: 20px;
      height: 20px;
    }
  }
  .aside-menu .announce-icon-menu .menu-item .menu-link.active,
  .aside-menu .announce-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/Annoucement_active.svg");
    }
  }

  .aside-menu .incidents-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/incident-inactive.png");
      width: 20px;
      height: 20px;
    }
  }

  .aside-menu .incidents-icon-menu .menu-item .menu-link.active,
  .aside-menu .incidents-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/incident-active.png");
    }
  }

  .aside-menu .mta-myteam-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/mta_myteam_in_active.png");
      width: 22px;
      height: 22px;
    }
  }

  .aside-menu .mta-myteam-icon-menu .menu-item .menu-link.active,
  .aside-menu .mta-myteam-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/mta_myteam_active.png");
    }
  }

  .aside-menu .mta-properties-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/properties_inactive.png");
      width: 20px;
      height: 20px;
    }
  }

  .aside-menu .mta-properties-icon-menu .menu-item .menu-link.active,
  .aside-menu .mta-properties-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/properties_active.png");
    }
  }

  .aside-menu .tenant-search-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/tenant-search-in-active.svg");
      width: 20px;
      height: 20px;
    }
  }

  .aside-menu .tenant-search-icon-menu .menu-item .menu-link.active,
  .aside-menu .tenant-search-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/tenant-search-active.svg");
    }
  }

  .aside-menu .offers-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/Offers_Inactive.svg");
      width: 20px;
      height: 20px;
    }
  }

  .aside-menu .offers-icon-menu .menu-item .menu-link.active,
  .aside-menu .offers-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/Offers_Active.svg");
    }
  }

  ////////////// START Profile Icons /////////////////////

  .aside-menu .personal-information-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/User_inactive.svg");
      width: 20px;
      height: 20px;
      background-size: contain;
    }
  }
  .aside-menu .personal-information-icon-menu .menu-item .menu-link.active,
  .aside-menu .personal-information-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/User_active.svg");
    }
  }

  .aside-menu .my-documents-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/Documents_inactive.svg");
      width: 20px;
      height: 20px;
      background-size: contain;
    }
  }
  .aside-menu .my-documents-icon-menu .menu-item .menu-link.active,
  .aside-menu .my-documents-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/Documents_active.svg");
    }
  }

  .aside-menu .notifications-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/icons/profile-iIcons/notification-inactive.png");
      width: 20px;
      height: 20px;
    }
  }

  .aside-menu .notifications-icon-menu .menu-item .menu-link.active,
  .aside-menu .notifications-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/icons/profile-iIcons/notification-active.png");
    }
  }

  .aside-menu .chat-with-us-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/icons/profile-iIcons/chat-inactive.png");
      width: 20px;
      height: 20px;
    }
  }

  .aside-menu .chat-with-us-icon-menu .menu-item .menu-link.active,
  .aside-menu .chat-with-us-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/icons/profile-iIcons/chat-active.png");
    }
  }

  .aside-menu .faq-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/Question_inactive.svg");
      width: 20px;
      height: 20px;
    }
  }

  .aside-menu .faq-icon-menu .menu-item .menu-link.active,
  .aside-menu .faq-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/Question.svg");
    }
  }

  .aside-menu .settings-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/Settings_Inactive.svg");
      width: 20px;
      height: 20px;
      background-size: contain;
    }
  }

  .aside-menu .settings-icon-menu .menu-item .menu-link.active,
  .aside-menu .settings-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/Settings_active.svg");
    }
  }

  .aside-menu .logout-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/power_inactive.svg");
      width: 20px;
      height: 20px;
      background-size: contain;
    }
  }
  .aside-menu .logout-icon-menu .menu-item .menu-link.active,
  .aside-menu .logout-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/power_active.svg");
    }
  }

  .aside-menu .visitors-staff-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/icons/profile-iIcons/staff-inactive.png");
      width: 20px;
      height: 20px;
    }
  }

  .aside-menu .visitors-staff-icon-menu .menu-item .menu-link.active,
  .aside-menu .visitors-staff-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/icons/profile-iIcons/staff-active.png");
    }
  }

  .aside-menu .fe-su-myteam-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/MyTeam.png");
      width: 23px;
      height: 20px;
    }
  }

  .aside-menu .fe-su-myteam-icon-menu .menu-item .menu-link.active,
  .aside-menu .fe-su-myteam-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/MyTeamActive.png");
    }
  }

  ////////////// END Profile Icons /////////////////////


  .aside-menu .team-act-icon-menu .menu-item {
    &.show {
      .menu-link {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &:hover {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }

    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/services-inactive.png");
      width: 20px;
      height: 20px;
    }

    &.show {
      >.menu-link {
        .menu-title {
          color: #49484c !important;
        }

        // padding-bottom: 0 !important;
      }

      &:hover {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
  }

  .aside-menu .team-act-icon-menu .menu-item.show .menu-link,
  .aside-menu .team-act-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      width: 20px;
    height: 20px;
      background-image: url("../../../assets/images/activity.svg");
    }
  }

  .aside-menu .statement-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/Statement_InActive.svg");
      width: 19px;
      height: 19px;
    }
  }

  .aside-menu .statement-icon-menu .menu-item .menu-link.active,
  .aside-menu .statement-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/state_act.svg");
    }
  }

  .aside-menu .approval-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/Approvals_InActive.png");
      width: 20px;
      height: 20px;
    }
  }

  .aside-menu .approval-icon-menu .menu-item .menu-link.active,
  .aside-menu .approval-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/Approvals_Active.png");
    }
  }

  .aside-menu .scheduled-visit-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/Scheduled_InActive.png");
      width: 22px;
      height: 22px;
    }
  }

  .aside-menu .scheduled-visit-icon-menu .menu-item .menu-link.active,
  .aside-menu .scheduled-visit-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/Scheduled_Active.png");
    }
  }

  .aside-menu .fc-ss-maint-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/FC_SS_Maint-InActive.png");
      width: 22px;
      height: 22px;
    }
  }

  .aside-menu .fc-ss-maint-icon-menu .menu-item .menu-link.active,
  .aside-menu .fc-ss-maint-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/FC_SS_Maint_Active.png");
    }
  }

  .aside-menu .campaign-icon-menu .menu-item {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/Marketplace_Inactive.svg");
      width: 21px;
      height: 23px;
      background-size: contain;
    }
  }

  .aside-menu .campaign-icon-menu .menu-item .menu-link.active,
  .aside-menu .campaign-icon-menu .menu-item .menu-link:hover {
    span.menu-icon::after {
      content: "";
      background-image: url("../../../assets/images/property-management-icons/market_place_active.svg");
    }
  }

  .aside-menu .menu .menu-item .menu-link,
  .aside-menu .menu .menu-item .menu-content {
    padding-left: 25px;
    padding-right: 25px;
    //margin-left: 15px !important;
    margin-right: 15px !important;
  }
}

// Dashboard Screen
.announcement-card {
  height: 100px;
}

.movein-moveout {
  .nav-line-tabs {
    @media (max-width: 767px) {
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
}

.card {
  box-shadow: 0px 0px 10px 0px rgb(76 87 125 / 2%);
}

.bg-gradient-primary-card {
  background-color: rgba(2, 45, 106, 0.5);
  background-image: linear-gradient(45deg, #000 100%, #49484c 80%);
  background-size: cover;
  border-radius: 10px;

  img {
    position: absolute;
    height: 210px;
    right: 0;
  }

  .btn-light {
    background-color: #FFFFFF;
    border-color: #FFFFFF !important;
    color: #49484c;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 0 !important;
  }

  @media only screen and (min-width:641px) and (max-width:991px) {
    h6 {
      margin-bottom: 6px !important;
    }

    .move-in-link {
      margin-bottom: 10px;
      padding: 5px 0 !important;
    }

    .move-in-main-div {
      margin-bottom: 5px !important;
    }

  }

  @media only screen and (min-width:320px) and (max-width:767.98px) {
    .move-in-link {
      margin-bottom: 15px;
      padding: 8px 0 !important;
    }
  }
}
.ct-dash-card{
    min-height: 200px;

}
.lease-banner {
  img {
    height: 100%;
    // border-radius: 10px;
    // object-fit: contain;
    min-height: 155px;
    max-height: 155px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    object-fit: cover;
    min-width: 100%;
    max-width: 100%;
    @media (max-width:1199px) {
      height: 160px;
    }

    @media (max-width:991px) {
      height: 160px;
    }

    @media (max-width:768px) {
      height: 180px;
    }

    @media (max-width:576px) {
      height: 200px;
    }
  }
}

.lease-card.mt-n20 {
  margin-top: -9.8rem !important;

  @media (max-width:1199px) {
    margin-top: -11.4rem !important;
  }

  @media (max-width:991px) {
    margin-top: -12.8rem !important;
  }

  @media (max-width:768px) {
    margin-top: -14.8rem !important;
  }

  @media (max-width:576px) {
    margin-top: -14.9rem !important;
  }

  h6.text-theme-gray {
    color: rgba(0, 0, 0, 0.4);
  }
}

.com-card-section {
  .card {
    border-radius: 10px;

    .card-title {
      color: #000000;
      font-size: 18px;
      font-weight: 600;
    }

    .view-all {
      color: #17437F;
      font-size: 16px;
      font-weight: normal;

      span {
        margin-right: 5px;
      }
    }
  }

  .feedback-workorder {
    .box {
      background-color: #F6F6F6;
      border: 2px solid #DBDBDB;
      border-radius: 13px;

      &:last-child {
        margin-bottom: 0 !important;
      }

      .btn-group {
        label {
          .comm-icon {
            height: 28px;
            width: 28px;
            background-repeat: no-repeat;
            display: inline-block;
            vertical-align: middle;
          }

          span.very-satis-ioc.inactive {
            background-image: url("../../../assets/images/wosatis-inactive.svg") !important;
          }

          span.satis-ioc.inactive {
            background-image: url("../../../assets/images/woneutral-inactive.svg") !important;
          }

          span.not-satis-ioc.inactive {
            background-image: url("../../../assets/images/wounsatis-inactive.svg") !important;
          }

          span.very-satis-ioc.active {
            background-image: url("../../../assets/images/wosatis-active.svg") !important;
          }

          span.satis-ioc.active {
            background-image: url("../../../assets/images/woneutral-active.svg") !important;
          }

          span.not-satis-ioc.active {
            background-image: url("../../../assets/images/wounsatis-active.svg") !important;
          }
        }
      }
    }
  }
}
.com-card-section {
  .card {
    border-radius: 8px;
    .card-title {
      color: #000000;
      font-size: 18px;
      font-weight: 600;
    }
    .view-all {
      color: #000;
      font-size: 12px;
      font-weight: normal;
      text-decoration: underline;
      span {
        margin-right: 0;
      }
      img{
        filter : grayscale(1);
        display: none;
      }
    }
  }
  .feedback-workorder {
    .box {
      background-color: #F6F6F6;
      border: 2px solid #DBDBDB;
      border-radius: 13px;
      &:last-child {
        margin-bottom: 0 !important;
      }
      .btn-group {
        label {
          .comm-icon {
            height: 28px;
            width: 28px;
            background-repeat: no-repeat;
            display: inline-block;
            vertical-align: middle;
          }
          span.very-satis-ioc.inactive {
            background-image: url("../../../assets/images/wosatis-inactive.svg") !important;
          }
          span.satis-ioc.inactive {
            background-image: url("../../../assets/images/woneutral-inactive.svg") !important;
          }
          span.not-satis-ioc.inactive {
            background-image: url("../../../assets/images/wounsatis-inactive.svg") !important;
          }
          span.very-satis-ioc.active {
            background-image: url("../../../assets/images/wosatis-active.svg") !important;
          }
          span.satis-ioc.active {
            background-image: url("../../../assets/images/woneutral-active.svg") !important;
          }
          span.not-satis-ioc.active {
            background-image: url("../../../assets/images/wounsatis-active.svg") !important;
          }
        }
      }
    }
  }
}
.payment-txt {
  @media screen and (max-width:1367px) {
    font-size: 14px;
  }

  @media screen and (max-width:768px) {
    font-size: 12px;
  }

  @media screen and (max-width:576px) {
    font-size: 10px;
  }
}

.mini-txt {
  @media (max-width: 1200px) {
    font-size: 11px !important;
  }

  @media (max-width: 991px) {
    font-size: 10px !important;
  }

  @media (max-width: 575px) {
    font-size: 9px !important;
  }
}

.tenant-sub-txt {
  @media (max-width: 1200px) {
    font-size: 12px !important;
  }

  @media (max-width: 991px) {
    font-size: 11px !important;
  }

  @media (max-width: 575px) {
    font-size: 10px !important;
  }
}

.tenant-txt {
  @media (max-width: 1200px) {
    font-size: 14px !important;
  }

  @media (max-width: 991px) {
    font-size: 13px !important;
  }

  @media (max-width: 575px) {
    font-size: 12px !important;
  }
}

.need-txt {
  @media screen and (max-width:991px) {
    font-size: 13px !important;
  }

  @media screen and (max-width:576px) {
    font-size: 14px !important;
  }
}

.need-sec-txt {
  @media screen and (max-width:991px) {
    font-size: 12px !important;
  }

  @media screen and (max-width:576px) {
    font-size: 13px !important;
  }
}

.text-title {
  @media screen and (max-width:1199px) {
    font-size: 16px !important;
  }

  @media screen and (max-width:991px) {
    font-size: 14px !important;
  }

  @media screen and (max-width:576px) {
    font-size: 13px !important;
  }
}

.text-sub-title {
  @media screen and (max-width:1199px) {
    font-size: 20px !important;
  }

  @media screen and (max-width:991px) {
    font-size: 18px !important;
  }

  @media screen and (max-width:576px) {
    font-size: 16px !important;
  }
}

.alert-txt {
  @media screen and (max-width:991px) {
    font-size: 13px !important;
  }

  @media screen and (max-width:576px) {
    font-size: 12px !important;
  }
}
.announce-list .announcements-card:hover {
    background-color: #F5F5F5;
}
.rent-reduction .form-check-input {
    width: 1.25rem;
    height: 1.25rem;
    margin-top: 0.125rem;
    vertical-align: middle;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.25);
}
.rent-reduction .form-check-input:checked {
    background-color: #F3B521 !important;
    border-color: #F3B521 !important;
}
.dashboard-page {
  .com-card-section {
    .view-all {
      color: #1D1D1F;
      filter: brightness(0.5);
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      text-decoration: underline !important;

      @media only screen and (max-width: 767.98px) {
        font-size: 14px;
      }

      span {
        margin-right: 5px;
      }

      img {
        @media only screen and (max-width: 767.98px) {
          width: 13px;
        }
      }
    }
    .card {
      border-radius: 8px;
      //overflow: hidden;

      .card-title {
        color: #000000;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0;
        line-height: 28px;

        @media (max-width: 1200px) {
          font-size: 16px;
        }
      }

      .view-all {
        color: #1D1D1F;
        filter: brightness(0.5);
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        text-decoration: underline !important;

        @media only screen and (max-width: 767.98px) {
          font-size: 14px;
        }

        span {
          margin-right: 5px;
        }

        img {
          @media only screen and (max-width: 767.98px) {
            width: 13px;
          }
        }
      }
    }
  }

  .quick-link-sec {
    .slick-slider {
      .slick-list {
        padding: 0 60px 10px 0 !important;
      }

      .slick-prev {
        right: 45px;
        left: inherit;
      }

      .slick-next {
        right: 0;
      }

      .slick-prev,
      .slick-next {
        top: -14px;
        width: 30px;
        height: 30px;
    border-radius: 3px;
    background: #F0F0F0;
      }

      .slick-prev:not(.slick-disabled):before,
      .slick-next:not(.slick-disabled):before {
        opacity: 0.5;
      }

      .slick-prev:before,
      .slick-next:before {
        font-size: 15px;
        color: #000000;
        font-weight: 600;
        font-family: 'Font Awesome 5 Free';
      }

      .slick-next:before {
        content: '\f054';
      }

      .slick-prev:before {
        content: '\f053';
      }

      .card {
        border-radius: 11px;
        border: 1px solid #F0F0F0;
        margin: 20px 17px 0 0;

        .card-body {
          border-radius: 11px;
          img {
    width: 50px;
    height: 50px;
    margin: 0 auto 4px auto;
            object-fit: contain;
          }
        }

        .card-title {
          color: #000000;
          font-size: 13px;
          line-height: 1.4;
          font-weight: 500;
          margin-left: 8px;
        }
      }

      .card:hover {
    box-shadow: 0px 4px 10px rgba(0,0,0,0.1);
        //background-color: #F5F5F5;
//         border: 1px solid #49484c;
      }
    }

    &.my-activity-sec {
      &.new-sec {
        .slick-slider {
          .slick-list {
            padding: 0 !important;
          }
    
          .slick-prev {
            right: 27px;
            left: inherit;
          }
    
          .slick-next {
            right: 0;
          }
    
          .slick-prev,
          .slick-next {
            top: -14px;
            width: 23px;
            height: 23px;
          }
    
          .slick-prev:not(.slick-disabled):before,
          .slick-next:not(.slick-disabled):before {
            opacity: 0.5;
          }
    
          .slick-prev:before,
          .slick-next:before {
            font-size: 15px;
            color: #000000;
            font-weight: 600;
            font-family: 'Font Awesome 5 Free';
          }
    
          .slick-next:before {
            content: '\f061';
          }
    
          .slick-prev:before {
            content: '\f060';
          }
    
         
        }
        .slick-slider {
          .card {
            background-color: #ffffff;
            border-color: #ffffff;
  
            &:hover {
              background-color: #ffffff;
              border-color: #ffffff;
            }
            img {
              width: 40px;
              height: 40px;
              margin-bottom: 10px;
              object-fit: contain;
              display: inline-block;
            }
            .card-body {
              span {
                color: #575050;
                font-size: 26px;
              }
  
              h6 {
                font-size: 14px;
              }
            }
  
          }
        }
      }
    }
  }

  .uploadbt {
    vertical-align: baseline;
  }

  .market-place-sec {
    .slick-slider {
      .slick-list {
        padding: 0 60px 10px 0 !important;

        >.slick-track {
          .slick-slide:nth-child(even) {
            >div {
              >div {
                >a {
                  >.card {
                    margin-right: 2px !important;
                  }
                }
              }
            }
          }
        }
      }

      .slick-prev {
        right: 27px;
        left: inherit;
      }

      .slick-next {
        right: 0;
      }

      .slick-prev,
      .slick-next {
        top: -14px;
        width: 23px;
        height: 23px;
      }

      .slick-prev:not(.slick-disabled):before,
      .slick-next:not(.slick-disabled):before {
        opacity: 0.5;
      }

      .slick-prev:before,
      .slick-next:before {
        font-size: 15px;
        color: #000000;
        font-weight: 600;
        font-family: 'Font Awesome 5 Free';
      }

      .slick-next:before {
        content: '\f061';
      }

      .slick-prev:before {
        content: '\f060';
      }

      .card {
        border-radius: 11px;
        border: 1px solid #E3E3E3;
        margin: 0 17px 0 0;
        min-height: 155px;
        overflow-y: hidden;

        @media (max-width:768px) {
          min-height: 245px !important;
        }

        @media (max-width:576px) {
          min-height: 210px !important;
        }

        .card-body {
          border-radius: 11px;
          padding: 18px;

          img {
            z-index: 2;
            width: 97px;

            @media (max-width:767px) {
              width: 100% !important;
              margin-bottom: 8px;
            }

            @media (min-width: 1500px) {
              margin: 0 auto;
              width: 150px;
              height: 118px;
              object-fit: cover;
              border-radius: 9px;
            }
          }

          .img-bg::after {
            background-color: #fff;
            border-radius: 10px;
            height: 115px;
            width: 98px;
            content: "";
            position: absolute;
            left: 17px;
            display: block;
            right: 0;
            top: 19px;
            z-index: 1;

            @media screen and (min-width:640px) and (max-width:993px) {
              width: 0 !important;
            }

            @media only screen and (min-width:320px) and (max-width:640px) {
              height: auto;
            }

            @media (min-width: 1500px) {
              max-width: 125px;
              width: 100%;
            }
          }
        }

        .card-title {
          color: #000000;
          font-size: 18px;
          line-height: normal;
          font-weight: 700;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .sub-title {
          color: #000000;
          font-size: 14px;
          line-height: normal;
          font-weight: 400;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .category {
          color: #ffa300;
          font-size: 14px;
          line-height: normal;
          font-weight: 400;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .count {
          color: #184581;
          font-size: 13px;
          line-height: 25px;
          font-weight: 500;
          background-color: #F2F3F5;
          border-radius: 5px;
          padding: 1px 3px;
          height: 28px;

          @media (max-width:991px) {
            font-size: 12px;
            white-space: nowrap;
          }

          @media (max-width:576px) {
            font-size: 11px;
          }
        }

        .sch-time {
          color: #BEBEBE;
          font-size: 12px;
          font-weight: 400;

          .bullet {
            top: 6px;
            margin-right: 8px;
            position: relative;
            left: 6px;
          }

          .bg-success {
            background-color: #49484c !important;
          }
        }
      }

      .card:hover {
        background-color: #F5F5F5;
      }
    }
  }

  .announcement-sec {
    .slick-slider {
      .slick-list {
        padding: 0 60px 10px 0 !important;

        >.slick-track {
          .slick-slide:nth-child(even) {
            >div {
              >div {
                >a {
                  >.card {
                    margin-right: 2px !important;
                  }
                }
              }
            }
          }
        }
      }

      .slick-prev {
        right: 27px;
        left: inherit;
      }

      .slick-next {
        right: 0;
      }

      .slick-prev,
      .slick-next {
        top: -14px;
        width: 23px;
        height: 23px;
      }

      .slick-prev:not(.slick-disabled):before,
      .slick-next:not(.slick-disabled):before {
        opacity: 0.5;
      }

      .slick-prev:before,
      .slick-next:before {
        font-size: 15px;
        color: #000000;
        font-weight: 600;
        font-family: 'Font Awesome 5 Free';
      }

      .slick-next:before {
        content: '\f061';
      }

      .slick-prev:before {
        content: '\f060';
      }

      .card {
        border-radius: 11px;
        border: 1px solid #E3E3E3;
        margin: 0 17px 0 0;
        min-height: 112px;
        overflow-y: hidden;

        @media only screen and (min-width:320px) and (max-width:640px) {
          min-height: 210px;
        }

        .card-body {
          border-radius: 11px;
          padding: 18px;

          .announce-img {
            border-radius: 5px;
            height: 100%;
            min-height: 70px;
            max-height: 70px;
            width: 100%;
            object-fit: cover;

            @media (max-width:768px) {
              margin-bottom: 10px;
            }
          }
        }

        .card-title {
          color: #000000;
          font-size: 16px;
          line-height: normal;
          font-weight: 700;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .sch-date {
          color: #000000;
          font-size: 12px;
          line-height: normal;
          font-weight: 500;
        }

        .sch-time {
          color: rgba(0, 0, 0, 0.5);
          font-size: 12px;
          font-weight: 400;
          line-height: normal;
        }
      }

      .card:hover {
        background-color: #F5F5F5;
      }
    }
  }

  .data_table {
    .MuiDataGrid-root {
      border: none;

      .MuiDataGrid-columnHeaders {
        border: none;
        display: block;
        background-color: #F6F6F6;
        border-radius: 8px 8px 0px 0px;
    height: 50px;
    line-height: 50px !important;
    min-height: 50px !important;

        .MuiDataGrid-columnHeadersInner {
          border: 0;
          font-size: 16px;

          @media (max-width: 767px) {
            font-size: 13px;
          }

          .MuiDataGrid-columnHeaderTitle {
            font-weight: 600;
          }

          .MuiDataGrid-columnHeader {
            &:focus-within {
              outline: none !important;
            }
          }
          .MuiDataGrid-columnSeparator--sideRight{display:none;}
        }
      }

      .MuiDataGrid-row {

        border-bottom: 1px solid #F3F4F6;
        .MuiDataGrid-cell {
          border: none;
          font-size: 15px;
//           padding-left: 18px;
          overflow: visible;

          @media (max-width: 767px) {
            font-size: 13px;
          }

          &:focus-within {
            outline: none !important;
          }
        }
      }

      .MuiDataGrid-row:nth-of-type(even) {
        background-color: #FFFFFF !important;
        /* border: 0 !important; */
        border-bottom: 1px solid #F3F4F6;
        /* border-radius: 10px;*/
        &:hover{
    background-color: rgba(0, 0, 0, 0.04) !important;
    }
      }

      .MuiDataGrid-row {
        &:hover {
          border-radius: 10px;
        }
      }

      .MuiDataGrid-footerContainer {
        .MuiTablePagination-root {
          .MuiToolbar-root {
            .MuiTablePagination-displayedRows {
              font-size: 14px;
              line-height: 32px;
              margin-bottom: 0;
        border-bottom: 1px solid #F3F4F6;
            }
          }
        }
      }
    }

    .new-theme {
      color: #B20000;
    }

    .upcoming-theme {
      color: #E6B906;
    }

    .cleared-theme {
      color: #3DBC06;
    }
  }

  .datagrid-sec-styles {

    table,
    .MuiDataGrid-root {
      color: #1D1D1F;
      font-size: 15px;
      font-weight: 500;
      font-family: Poppins, "sans-serif";
      border: 0;

      thead,
      .MuiDataGrid-columnHeaders {
        background-color: #F6F6F6;
        border-radius: 6px;
        border: 0;

        .MuiDataGrid-columnHeadersInner {
          width: 100% !important;
        }

        th,
        .MuiDataGrid-columnHeader {
          font-weight: 600;
          color: #1D1D1F;
          font-size: 15px;
          outline: 0 !important;

          @media (min-width: 1500px) {
            min-width: inherit !important;
            max-width: inherit !important;
            width: 100% !important;
          }

          .MuiDataGrid-columnSeparator {
            display: none;
          }

          .MuiButtonBase-root {
            color: #1D1D1F;
          }
        }

        th:first-child,
        .MuiDataGrid-columnHeader:first-child {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }

        th:last-child,
        .MuiDataGrid-columnHeader:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }

      tbody,
      .MuiDataGrid-row {
        width: 100% !important;

        &:hover {
          background-color: transparent;
        }

        td,
        .MuiDataGrid-cell {
          font-weight: 500;
          color: #1D1D1F;
          font-size: 15px;
          border-bottom: 0;
          outline: 0 !important;
          padding: 0 16px;

          @media (min-width: 1500px) {
            min-width: inherit !important;
            max-width: inherit !important;
            width: 100% !important;
          }

          &.present-theme {
            color: #0BB016;
          }

          &.absent-theme {
            color: #FA1212;
          }

          span.absent-theme {
            color: #FA1212;
          }

          &.ellipsis {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 200px;
          }

          span.cleared-theme {
            color: #3DBC06;
          }

          span.upcoming-theme {
            color: #E6B906;
          }

          span.new-theme {
            color: #B20000;
          }

          span.resolved-theme {
            color: #B20000;
          }

          span.lease-active {
            color: #2AC940;
          }

          span.lease-terminate {
            color: #FF0004;
          }

          a {
            span.edit-icon {
              background-image: url("../../../assets/images/edit-icon-normal.svg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: 14px;
              display: inline-block;
              height: 14px;
              width: 14px;
              margin-right: 8px;
            }

            span.view-icon {
              background-image: url("../../../assets/images/view-icon-normal.svg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: 15px;
              display: inline-block;
              height: 15px;
              width: 15px;
              margin-right: 8px;
            }

            span.download-icon {
              background-image: url("../../../assets/images/download-ioc.svg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: 15px;
              display: inline-block;
              height: 15px;
              width: 15px;
              margin-right: 8px;
            }

            &:hover {
              span.edit-icon {
                background-image: url("../../../assets/images/edit-icon-hover.svg");
              }

              span.view-icon {
                background-image: url("../../../assets/images/view-icon-hover.svg");
              }

              span.download-icon {
                background-image: url("../../../assets/images/download-ioc-hover.svg");
              }
            }
          }

          .menu-column {
            top: 12px !important;
            right: 12px !important;

            &::before {
              position: absolute;
              top: -11px;
              left: 120px;
              display: inline-block;
              border-right: 13px solid transparent;
              border-bottom: 13px solid #FFF;
              border-left: 13px solid transparent;
              border-bottom-color: #FFF;
              content: '';
            }

            .menu-link {
              span.edit-icon {
                background-image: url("../../../assets/images/edit-icon-normal.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: 14px;
                display: inline-block;
                height: 14px;
                width: 14px;
                margin-right: 8px;
              }

              span.view-icon {
                background-image: url("../../../assets/images/view-icon-normal.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: 15px;
                display: inline-block;
                height: 15px;
                width: 15px;
                margin-right: 8px;
              }

              span.download-icon {
                background-image: url("../../../assets/images/download-ioc.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: 15px;
                display: inline-block;
                height: 15px;
                width: 15px;
                margin-right: 8px;
              }

              &:hover {
                span.edit-icon {
                  background-image: url("../../../assets/images/edit-icon-hover.svg");
                }

                span.view-icon {
                  background-image: url("../../../assets/images/view-icon-hover.svg");
                }

                span.download-icon {
                  background-image: url("../../../assets/images/download-ioc-hover.svg");
                }
              }
            }
          }
        }

        tr:nth-of-type(even),
        .MuiDataGrid-row:nth-of-type(even) {
          background-color: #F9F9F9;
          border: 0 !important;
          border-radius: 10px;
        }

        tr,
        .MuiDataGrid-row {

          td:first-child,
          .MuiDataGrid-cell:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }

          td:last-child,
          .MuiDataGrid-cell:first-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }

        tr:nth-of-type(odd),
        .rdt_TableRow:nth-of-type(odd) {
          background-color: #ffffff;
          --bs-table-accent-bg: #fff;
          color: #1D1D1F;
          border: 0 !important;
        }
      }
    }

    .MuiDataGrid-virtualScrollerRenderZone {
      .MuiDataGrid-row {
        &:nth-of-type(even) {
          background-color: #F9F9F9 !important;
          border: 0 !important;
          border-radius: 10px;
        }

       
      }
    }

    .MuiDataGrid-footerContainer {
      min-height: inherit;

      .MuiToolbar-root {
        min-height: inherit;

        .MuiTablePagination-displayedRows {
          margin-bottom: 0 !important;
        }

        .MuiButtonBase-root {
          padding: 6px 6px 6px;
        }
      }
    }
  }

  .rec-payment-act-sec {

    table,
    .rdt_Table {
      color: #1D1D1F;
      font-size: 15px;
      font-weight: 500;
      font-family: Poppins, "sans-serif";

      thead,
      .rdt_TableHeadRow {
        background-color: #F6F6F6;
        border-radius: 6px;
        border: 0;

        th,
        .rdt_TableCol {
          font-weight: 600;
          color: #1D1D1F;
          font-size: 15px;
          min-width: 90px;
          white-space: nowrap;

          @media only screen and (max-width:991px) {
            font-size: 13px;
            padding: 0.7rem;
          }

          @media only screen and (max-width:1199px) {
            font-size: 13px;
            padding: 0.7rem;
          }
        }

        th:first-child,
        .rdt_TableCol:first-child {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }

        th:last-child,
        .rdt_TableCol:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }

      tbody,
      .rdt_TableBody {

        td,
        .rdt_TableCell {
          font-weight: 500;
          color: #1D1D1F;
          font-size: 15px;
          min-width: 90px;
          white-space: nowrap;

          @media only screen and (max-width:1199px) {
            font-size: 13px;
            padding: 0.7rem;
          }

          &.present-theme {
            color: #0BB016;
          }

          &.absent-theme {
            color: #FA1212;
          }

          span.absent-theme {
            color: #FA1212;
          }

          &.ellipsis {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 200px;
          }

          span.cleared-theme {
            color: #3DBC06;
          }

          span.upcoming-theme {
            color: #E6B906;
          }

          span.new-theme {
            color: #B20000;
          }

          span.resolved-theme {
            color: #B20000;
          }

          span.lease-active {
            color: #2AC940;
          }

          span.lease-terminate {
            color: #FF0004;
          }

          a {
            span.edit-icon {
              background-image: url("../../../assets/images/edit-icon-normal.svg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: 14px;
              display: inline-block;
              height: 14px;
              width: 14px;
              margin-right: 8px;
            }

            span.view-icon {
              background-image: url("../../../assets/images/view-icon-normal.svg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: 15px;
              display: inline-block;
              height: 15px;
              width: 15px;
              margin-right: 8px;
            }

            span.download-icon {
              background-image: url("../../../assets/images/download-ioc.svg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: 15px;
              display: inline-block;
              height: 15px;
              width: 15px;
              margin-right: 8px;
            }

            &:hover {
              span.edit-icon {
                background-image: url("../../../assets/images/edit-icon-hover.svg");
              }

              span.view-icon {
                background-image: url("../../../assets/images/view-icon-hover.svg");
              }

              span.download-icon {
                background-image: url("../../../assets/images/download-ioc-hover.svg");
              }
            }
          }

          .menu-column {

            //top: 12px !important;
            //left: 12px !important;
            &::before {
              position: absolute;
              top: -11px;
              left: 120px;
              display: inline-block;
              border-right: 13px solid transparent;
              border-bottom: 13px solid #FFF;
              border-left: 13px solid transparent;
              border-bottom-color: #FFF;
              content: '';
            }

            .menu-link {
              span.edit-icon {
                background-image: url("../../../assets/images/edit-icon-normal.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: 14px;
                display: inline-block;
                height: 14px;
                width: 14px;
                margin-right: 8px;
              }

              span.view-icon {
                background-image: url("../../../assets/images/view-icon-normal.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: 15px;
                display: inline-block;
                height: 15px;
                width: 15px;
                margin-right: 8px;
              }

              span.download-icon {
                background-image: url("../../../assets/images/download-ioc.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: 15px;
                display: inline-block;
                height: 15px;
                width: 15px;
                margin-right: 8px;
              }

              &:hover {
                span.edit-icon {
                  background-image: url("../../../assets/images/edit-icon-hover.svg");
                }

                span.view-icon {
                  background-image: url("../../../assets/images/view-icon-hover.svg");
                }

                span.download-icon {
                  background-image: url("../../../assets/images/download-ioc-hover.svg");
                }
              }
            }
          }
        }

        tr:nth-of-type(even),
        .rdt_TableRow:nth-of-type(even) {
          background-color: #F9F9F9;
          border: 0 !important;
          border-radius: 10px;
        }

        tr,
        .rdt_TableRow {

          td:first-child,
          .rdt_TableCell:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }

          td:last-child,
          .rdt_TableCell:first-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }

        tr:nth-of-type(odd),
        .rdt_TableRow:nth-of-type(odd) {
          background-color: #ffffff;
          --bs-table-accent-bg: #fff;
          color: #1D1D1F;
          border: 0 !important;
        }
      }
    }

    .rdt_Pagination {
      border: 0 !important;
      min-height: auto;
      margin-top: 12px;

      button {
        padding: 0;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;

        &:hover:not(:disabled) {
          background-color: rgba(0, 44, 106, 0.1);
        }
      }
    }
  }

  .move-out-payment-sec {
    table {
      font-size: 15px;
      font-weight: 500;

      @media (max-width: 767px) {
        font-size: 13px;
      }

      thead {
        background-color: #F6F6F6;

        th {
          font-weight: 600;
          color: #000000;
        }

        th:first-child {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }

        th:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }

      tbody {
        td {
          font-weight: 500;
          color: #AEAEAE;
          padding-top: 0.8rem;
          padding-bottom: 0.8rem;
        }

        tr {
          td:first-child {
            padding-left: 2.5rem !important;
          }

          td:last-child {
            padding-right: 2.5rem !important;
          }
        }

        tr:nth-of-type(odd) {
          background-color: #ffffff;
          --bs-table-accent-bg: #fff;
          color: #AEAEAE;
        }

        tr {
          &:last-child>td:first-child {
            color: #536475;
          }

          &:last-child>td:nth-child(2) {
            background: #F6F6F6;
            color: #536475;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            padding-top: 1.4rem;
            padding-bottom: 1.4rem;
          }

          &:last-child>td:last-child {
            background: #F6F6F6;
            color: #536475;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            padding-top: 1.4rem;
            padding-bottom: 1.4rem;
          }
        }
      }
    }
  }
}
.quick-link-sec.maintainence{
        .card {
              min-height: 120px !important;
              height: 120px !important;
              max-height: 120px !important;
        }
    }
.maint-page {
  .com-card-section {
    .quick-link-sec {
      .slick-slider {
        .slick-list {
          padding: 0 60px 10px 0 !important;
        }

        .slick-prev {
          right: 45px;
          left: inherit;
        }

        .slick-next {
          right: 0;
        }

        .slick-prev,
        .slick-next {
          top: -14px;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background: #F0F0F0;
        }

        .slick-prev:not(.slick-disabled),
        .slick-next:not(.slick-disabled),
        .slick-prev:not(.slick-disabled):before,
        .slick-next:not(.slick-disabled):before {
          opacity: 0.8;
        }
.slick-prev.slick-disabled, .slick-next.slick-disabled {
    opacity: 0.25;
}
        .slick-prev:before,
        .slick-next:before {
          font-size: 15px;
          color: #000000;
          font-weight: 600;
          font-family: 'Font Awesome 5 Free';
        }
.slick-prev:not(.slick-disabled),
        .slick-next:not(.slick-disabled),
        .slick-prev:not(.slick-disabled):before,
        .slick-next:not(.slick-disabled):before {
          opacity: 0.8;
        }
.slick-prev.slick-disabled, .slick-next.slick-disabled {
    opacity: 0.25;
}
        .slick-next:before {
          content: '\f054';
        }

        .slick-prev:before {
          content: '\f053';
        }

        .card {
          min-height: 90px;
          height: 90px;
          max-height: 90px;

          .card-title {
            font-size: 14px;
            margin: 2px;
            text-align:center;
            white-space: inherit;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: block;
            display: -webkit-box;
            -webkit-box-orient: vertical;

            @media only screen and (min-width:991px) and (max-width:1367px) {
              font-size: 12px;
            }
          }
        }
      }
    }

   

  }
}

.myunit-page {
  .lease-card {
    background-color: #F5F5F5;

    @media (max-width: 575px) {
      h6 {
        font-size: 12px;
      }

      .text-black {
        font-size: 14px;
      }
    }

    .bg-light-grey {
      background-color: #FAFAFA;
    }

    .lease_card_img {
      border-radius: 9px;
      width: 100%;
      object-fit: cover;
      height: 136.7px;
      margin-bottom: 15px;
    }

    .lease_card_span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .wo-img {
    object-fit: cover;
    max-height: 160px;
    min-height: 160px;
  }
}

.feed-design {
  .satis-wdw {
    .btn-group {
      background-color: #F5F5F5;
      align-items: center;

      label.btn {
        border: 0 !important;
        color: #000000 !important;
        background-color: transparent !important;

        .comm-icon {
          height: 19px;
          width: 19px;
          background-repeat: no-repeat;
          display: inline-block;
          vertical-align: middle;
        }

        span.very-satis-ioc {
          background-image: url("../../../assets/images/Very_satisfied_InActive.png") !important;
        }

        span.satis-ioc {
          background-image: url("../../../assets/images/Satisfied_InActive.png") !important;
        }

        span.not-satis-ioc {
          background-image: url("../../../assets/images/Not_Satisfied_InActive.png") !important;
        }
      }

      .btn-check:checked+.btn-outline-primary,
      .btn-check:active+.btn-outline-primary,
      .btn-outline-primary:active,
      .btn-outline-primary.active {
        color: #000000;
        background-color: transparent !important;
        border-color: inherit !important;
        border: 0 !important;
      }

      .btn-check:checked+.btn-outline-primary span.very-satis-ioc,
      .btn-check:active+.btn-outline-primary span.very-satis-ioc,
      .btn-outline-primary:active span.very-satis-ioc,
      .btn-outline-primary.active span.very-satis-ioc {
        background-image: url("../../../assets/images/Very_satisfied_Active.png") !important;
      }

      .btn-check:checked+.btn-outline-primary span.satis-ioc,
      .btn-check:active+.btn-outline-primary span.satis-ioc,
      .btn-outline-primary:active span.satis-ioc,
      .btn-outline-primary.active span.satis-ioc {
        background-image: url("../../../assets/images/Satisfied_Active.png") !important;
      }

      .btn-check:checked+.btn-outline-primary span.not-satis-ioc,
      .btn-check:active+.btn-outline-primary span.not-satis-ioc,
      .btn-outline-primary:active span.not-satis-ioc,
      .btn-outline-primary.active span.not-satis-ioc {
        background-image: url("../../../assets/images/Not_Satisfied_Active.png") !important;
      }
    }
  }

  .form-control {
    color: #000000;
    background-color: inherit;
    border-color: #DDDDDD;

    &:focus {
      background-color: inherit;
      border-color: #DDDDDD;
      color: #000000;
    }
  }

  .bg-light {
    background-color: #F5F5F5 !important;
  }
}

.comm-lease {
  .card {
    background-color: #FAFAFA;
    border: 1px solid #ECECEC;
    border-radius: 10px;

    .lease-card {
      background-color: #FFFFFF;
      border: 0;
    }

    .lease_card_img {
      border-radius: 9px;
      width: 100%;
      object-fit: cover;
      height: 136.7px;
      margin-bottom: 15px;
    }

    .lease_card_span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.nav-line-tabs .nav-item:last-child>.nav-link {
  margin-right: 0 !important;
}

.nav-line-tabs .nav-item .nav-link {
  font-weight: normal;
  font-size: 15px;
  color: #8B8B8B;
  border-bottom-width: 0 !important;

  .form-select.tab-drop {
    background-image: url("../../../assets/images/Dropdown_arrow_1.png") !important;
    background-size: 10px 6px;
    padding-right: 35px !important;
    color: #8B8B8B;
    font-weight: 400;
  }
}

.nav-line-tabs .nav-item .nav-link.text-active-primary.active {
  color: #1A1A1A !important;
  //border-bottom-width: 0 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  //background-color: #D9D9D9;

  &::before{
    border: 2px solid #F3B521;
    content: "";
    display: block;
    // left: calc(10% - 15px);
    right:0;
    position: absolute;
    width: 100%;
    top: 46px;
    z-index: 10;
    border-radius: 4px;
  }

  .form-select.tab-drop {
    color: #000 !important;
    font-weight: 700 !important;
  }
}

.subtabs{
  padding-bottom: 6px;
  border-bottom: 1px solid #DBDBDB;
  margin-bottom: 10px;
}  

.nav-line-tabs .nav-item .nav-link.text-active-primary.actives {
  color: #1A1A1A !important;
  border-bottom-width: 0 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  background-color: #ffe0a1;
}

.access-cd-tabs .nav-line-tabs .nav-item .nav-link.text-active-primary.active {
  &::after {
    content: "";
    width: 23px;
    display: none;
  }
}

.nav-line-tabs .nav-item .nav-link.text-active-primary:hover,
.nav-line-tabs .nav-item .nav-link.text-active-primary:focus {
  border-bottom-width: 0 !important;
  color: #000 !important;
}

.w-200px {
  width: 200px !important;
}

.w-145px {
  width: 160px !important;
}

.form-add-design {
  label {
    color: rgba(0,0,0,0.40);
    font-size: 14px;
    font-weight: 500 !important;

    @media (max-width: 575px) {
      font-size: 13px;
    }
  }

  .intl-tel-input {
    width: 100%;
  }

  .cal-ioc {
    position: absolute;
    right: 10px;
    top: 42px;
  }

  .btn-group {
    label.btn.btn-outline-primary {
      border: 1px solid #DDDDDD !important;
      border-radius: 6px !important;
      color: #000;
      font-weight: 500;
      padding: 7px 17px 7px 43px !important;
      font-size: 14px !important;
    }

    .btn-check:checked+.btn-outline-primary,
    .btn-check:active+.btn-outline-primary,
    .btn-outline-primary:active,
    .btn-outline-primary.active,
    .btn-outline-primary.dropdown-toggle.show,
    .btn-outline-primary:hover {
      color: #ffffff !important;
      background-color: #49484c;
      border-color: #49484c;
    }

    .btn-check[disabled]+.btn-outline-primary,
    .btn-check:disabled+.btn-outline-primary {
      color: rgba(0, 0, 0, 0.5) !important;
      background-color: #F1F1F1;
      border-color: #F1F1F1 !important;

      &::before {
        background-color: transparent;
      }
    }

    .btn-outline-primary::before {
      content: "";
      border: 1px solid #707070;
      border-radius: 50%;
      height: 14px;
      width: 14px;
      position: absolute;
      left: 18px;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: #fff;
    }
  }

  .locat-group {
    .btn-outline-primary::before {
      content: "";
      border: 0;
      height: unset;
      width: unset;
      position: unset;
      margin: 0;
      background-color: unset;
    }

    label.btn.btn-outline-primary {
      border-radius: 6px !important;
      color: #000;
      font-weight: normal;
      padding: 0 !important;
      font-size: 12px !important;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
//       flex-direction: column;
      width: 174px;
    }

    .btn-check:checked+.btn-outline-primary,
    .btn-check:active+.btn-outline-primary,
    .btn-outline-primary:active,
    .btn-outline-primary.active,
    .btn-outline-primary.dropdown-toggle.show,
    .btn-outline-primary:hover {
      color: #000 !important;
      background-color: rgba(168, 191, 225, 0.2) !important;
      border: 0 !important;
    }
  }

  .form-control {
    background-color: inherit;
    border-color: #DDDDDD;
    font-weight: 400 !important;
    color: rgba(128, 128, 128, 0.8);

    &:focus {
      background-color: inherit;
      border-color: #DDDDDD;
      color: #000000;
    }
  }

  .form-select {
    background-image: url("../../../assets/images/Dropdown_arrow_1.png") !important;
    background-size: 10px 6px;
  }

  .input-group-text {
    border: 1px solid #DDDDDD;
    color: #6E6E6E;
  }

  .upload-input {
    .btn {

    background-color: transparent !important;
    border: 1px dashed #A4A4A4 !important;
    color: #707070 !important;
    border-radius: 7px !important;
    height: 80px !important;
    -webkit-box-pack: space-evenly;
    -webkit-justify-content: space-evenly;
    -ms-flex-pack: space-evenly;
    align-items: center;
    display: flex;
    justify-content: space-evenly;
      font-size: 14px;
      font-weight: 600;
       span.txt{
    align-items: center;
    display: flex;
    width: 120px;
    justify-content: space-around;

       }
      &:hover,
      &:focus,
      &:active {
    background-color: transparent !important;
    border-color: #A4A4A4 !important;
        i {
          color: #ffffff !important;
        }
      }
    }
  }

  .form-check.custom .form-check-input {
    margin-left: -1.85rem;
    width: 15px;
    height: 15px;
    margin-top: 0.186rem;

    &:checked {
      background-color: #49484c;
      border-color: #49484c;
    }

    &[type=checkbox] {
      width: 16px;
      height: 16px;
      border-radius: 0;
      border-color: #707070;
      background-size: 100% 60%;
    }
  }

  .form-check-input:checked {
    background-color: #49484c;
    border-color: #49484c;
  }

  .form-switch {
    padding-left: 0;

    .form-check-input {
      position: absolute;
    margin-left: 5px;
    background-color: transparent;
    width: 32px;
    height: 17px;
    margin-top: 2px;
    border: 1px solid #49484C;

      &:checked {
        background-color: #49484c;
      }
    }
  }

  .ant-checkbox {
    margin-right: 10px;
  }

  .vacat-btn {
    border-color: #F14242;
    background-color: #FEECEC;
    color: #EE1212;

    &:hover,
    &:focus {
      border-color: #F14242;
      background-color: #F14242;
      color: #FFFFFF;
    }
  }

  .ns-btn {
    border-color: #49484c;
    background-color: #E8ECF2;
    color: #49484c;
  }

  .card-pay-bg {
    background-color: #F5F5F5;
    height: 325px;

    .help-text {
      color: #B4B4B4;
      position: absolute;
      right: 0;
      top: 4px;
    }

    label {
      font-size: 10px;
    }

    .enter-card-number-label {
      font-size: 14px;
    }

    .form-check-label {
      font-size: 11px;
    }
  }

  #maintanance_time {
    overflow-x: scroll;
    display: -webkit-inline-box;
    position: inherit;

    .btn-outline-primary {
      padding: 7px 17px 7px 28px !important;
      font-size: 11px !important;
      margin-bottom: 10px;
      position: relative;
      display: block;

      &:before {
        left: 7px;
      }
    }

    .btn-check:checked+.btn-outline-primary {
      color: #000000 !important;
      background-color: #fdfeff;

      &:hover {
        color: #fff !important;
      }
    }

    .btn-check:checked+.btn-outline-primary::before {
      background-color: #49484c;
      border: 1px solid #49484c;

      &:hover {
        background-color: #ffffff;
      }
    }

    @media only screen and (min-width:320px) and (max-width:640px) {
      display: grid;

      label {
        margin-right: 0 !important;
        margin-bottom: 10px;
      }
    }
  }

  .maintanance_time_bt {
    img{
      filter: brightness(0.5);
    }
    @media only screen and (min-width:320px) and (max-width:640px) {
      display: grid;

      label.btn.btn-outline-primary {
        width: 100% !important;
        margin-bottom: 10px;
      }
    }
  }
}

.date-picker {
  .react-date-picker {
    padding: 6px;

    .react-date-picker__wrapper {
      border: thin solid transparent;
    }

    .react-calendar__tile {
      padding: 6px 6px;
      border: 1px solid #e1e1e1 !important;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
      color: #dadadb;
    }

    .react-calendar__navigation {
      margin-bottom: 0;
      background: #f3f3f3;
    }

    .react-calendar__tile--active {
      background: #000;
    }

    .react-calendar__month-view__weekdays__weekday {
      color: #49484c;
    }

    .react-calendar__tile--hasActive {
      background: #000;
      color: #fff;
    }
  }
}

.card-pay-bg {
  background-color: #F5F5F5;

  h5 {
    @media (max-width: 575px) {
      font-size: 16px !important;
    }
  }
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  img {
    width: 30px;
    height: 30px;
  }
}

.modal-backdrop.show {
  opacity: 0.6;
}

.notif-popup {
  .modal-title {
    color: #202020;
    font-size: 22px;
  }

  .btn-close {
    opacity: 1;
    font-size: 15px !important;
    font-weight: bolder !important;

    &:focus,
    &:hover {
      outline: 0 !important;
      box-shadow: none;
      color: #49484c;
    }
  }

  .modal-body {
    background-color: #F4F7FB;

    .notif-title {
      color: #666666;

      @media only screen and (max-width: 767.98px) {
        font-size: 13px !important;
      }
    }

    .notif-desc {
      color: #666666;

      @media only screen and (max-width: 767.98px) {
        font-size: 13px !important;
        margin-bottom: 0 !important;
      }
    }

    .notif-time {
      color: #9D9D9D;

      @media only screen and (max-width: 767.98px) {
        font-size: 12px !important;
      }
    }

    .notif-btn-group {
      .btn {
        font-size: 10px;
        font-weight: 500;
        padding: 6px 8px !important;
      }

      .btn-primary.disabled {
        border-color: #49484c !important;
        color: #FFFFFF !important;
        background-color: #49484c !important;
        opacity: 1;
      }

      .btn-outline-danger.disabled {
        border-color: #FF0000 !important;
        color: #FF0000 !important;
        opacity: 1;
      }

      .btn-outline-primary.disabled {
        border-color: #49484c !important;
        color: #49484c !important;
        opacity: 1;
      }

      .btn-outline-secondary.disabled {
        border-color: #707070 !important;
        color: #707070 !important;
        opacity: 1;
      }

      .btn-outline-secondary {
        border-color: #707070 !important;
        color: #707070 !important;
      }

      .btn-outline-secondary:hover,
      .btn-outline-secondary:focus {
        border-color: #707070 !important;
        background-color: #707070 !important;
        color: #FFFFFF !important;
      }

      .btn-outline-danger {
        border-color: #FF0000 !important;
        color: #FF0000 !important;
      }

      .btn-outline-danger:hover,
      .btn-outline-danger:focus {
        border-color: #FF0000 !important;
        background-color: #FF0000 !important;
        color: #FFFFFF !important;
      }
    }
  }

  .modal-footer {
    background-color: #49484c;
    color: #FFFFFF;
    height: 69px;

    .h-21px {
      height: 21px;
    }

    .w-21px {
      width: 21px;
    }
  }

  .bg-secon {
    background-color: #F5F5F5;
    border: none;
  }

  .security-deposit {

    .nav-link.active,
    .show>.nav-link {
      background-color: #ffffff;
      color: #000;
      border-bottom: 5px solid #49484c;

      .icons {
        display: block;
        background: #49484c;
        width: 30px;
        height: 30px;
        padding: 6px;
        border-radius: 50px;
        margin: 0 auto;

        i {
          color: #fff;
        }
      }
    }

    .nav-link {
      background: #fff;
      font-size: 13px;
      color: #707070;
      border: 0;
      border-radius: 0.475rem;

      .icons {
        display: block;
        background: #F5F5F5;
        width: 30px;
        height: 30px;
        padding: 6px;
        border-radius: 50px;
        margin: 0 auto;

        i {
          color: #49484c;
        }
      }
    }

    .tab_nav_li {
      width: 32%;
    }
  }






}
.noc{
  .btn-danger{
    border-radius: 50%;
    padding: calc(0.5rem + 1px) calc(1rem + 1px) !important;

  }
}
.feed-design {
  .modal-header {
    background-color: #F5F5F5;

    .modal-title {
      color: #202020;
      font-size: 22px;
    }

    .btn-close {
      opacity: 1;
      font-size: 15px !important;
      font-weight: bolder !important;

      &:focus,
      &:hover {
        outline: 0 !important;
        box-shadow: none;
        color: #49484c;
      }
    }
  }

  .modal-body {
    .title {
      color: #121213;
      font-size: 14px;
    }

    .satis-wdw {
      .btn-group {
        background-color: #F5F5F5;
        align-items: center;

        label.btn {
          border: 0 !important;
          color: #000000 !important;
          background-color: transparent !important;

          .comm-icon {
            height: 19px;
            width: 19px;
            background-repeat: no-repeat;
            display: inline-block;
            vertical-align: middle;
          }

          span.very-satis-ioc {
            background-image: url("../../../assets/images/Very_satisfied_InActive.png") !important;
          }

          span.satis-ioc {
            background-image: url("../../../assets/images/Satisfied_InActive.png") !important;
          }

          span.not-satis-ioc {
            background-image: url("../../../assets/images/Not_Satisfied_InActive.png") !important;
          }
        }

        .btn-check:checked+.btn-outline-primary,
        .btn-check:active+.btn-outline-primary,
        .btn-outline-primary:active,
        .btn-outline-primary.active {
          color: #000000;
          background-color: transparent !important;
          border-color: inherit !important;
          border: 0 !important;
        }

        .btn-check:checked+.btn-outline-primary span.very-satis-ioc,
        .btn-check:active+.btn-outline-primary span.very-satis-ioc,
        .btn-outline-primary:active span.very-satis-ioc,
        .btn-outline-primary.active span.very-satis-ioc {
          background-image: url("../../../assets/images/Very_satisfied_Active.png") !important;
        }

        .btn-check:checked+.btn-outline-primary span.satis-ioc,
        .btn-check:active+.btn-outline-primary span.satis-ioc,
        .btn-outline-primary:active span.satis-ioc,
        .btn-outline-primary.active span.satis-ioc {
          background-image: url("../../../assets/images/Satisfied_Active.png") !important;
        }

        .btn-check:checked+.btn-outline-primary span.not-satis-ioc,
        .btn-check:active+.btn-outline-primary span.not-satis-ioc,
        .btn-outline-primary:active span.not-satis-ioc,
        .btn-outline-primary.active span.not-satis-ioc {
          background-image: url("../../../assets/images/Not_Satisfied_Active.png") !important;
        }
      }
    }

    .form-control {
      color: #000000;
      background-color: inherit;
      border-color: #DDDDDD;

      &:focus {
        background-color: inherit;
        border-color: #DDDDDD;
        color: #000000;
      }
    }
  }
}

//market-place
.btn-outline-secondary {
  background-color: #ffffff;
  border: 1px solid #696969;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  color: #696969;
}

.btn-outline-secondary:hover {
  background-color: #696969;
  border: 1px solid #696969;
  color: #ffffff;
}

.mt-15px {
  margin-top: 15px;
}

.mp-carousel-text {
  background-color: #173F78;
  border-radius: 0 9px 9px 0;

  @media (max-width: 575px) {
    border-radius: 0px 0px 9px 9px;
  }

  h4 {
    margin-bottom: 10px;

    &::after {
      content: "";
      position: absolute;
      right: 0px;
      top: 30px;
      left: 0px;
      background-color: #ffffff;
      width: 60px;
      height: 2px;
      margin: auto;

      @media (max-width: 575px) {
        top: 25px;
      }

      @media (max-width: 991px) {
        top: 20px;
      }
    }
  }

  .mp-redeem-button {
    background-color: #ffffff;
    padding: 10px 12px;
    font-size: 10px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: #173F78;
    border-radius: 5px;
    border: none;
    width: 100%;
    max-width: 150px;

    @media (max-width: 575px) {
      max-width: 120px;
      padding: 5px 12px;
    }
  }
}

.nav-line-tabs.mp-list .nav-item .nav-link {
  padding: 10px 20px;
}

.MPOffer-text {
  top: 20px;
  left: 10px;
  right: 10px;

  @media (max-width: 991px) {
    top: 20px;
  }

  h4 {
    margin-bottom: 50px;
  }

  .offer-overlay {
    background-color: rgba(0, 0, 0, 0.43);
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    margin: 20px 0px;
  }

  .offer-btn {
    .btn-redeem {
      font-size: 12px;
      font-family: "Montserrat", sans-serif;
      border-radius: 5px;
      padding: 5px 22px;
      border: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }

    .btn-refresh {
      padding: 4px 6px;
      border-radius: 3px;
      border: none;
      background-color: #ffffff;
    }
  }
}

@media (max-width: 575px) {
  .mp-carousel-text {
    padding-bottom: 30px !important;

    h4 {
      font-size: 16px;
    }
  }

  .mp-carousel-text {
    h5 {
      font-size: 18px;
    }
  }

  .mp-carousel-text {
    p {
      font-size: 16px;
    }
  }
}

@media (max-width: 1199px) {
  .mp-carousel-text {
    h4 {
      font-size: 18px;
    }
  }

  .mp-carousel-text {
    h5 {
      font-size: 24px;
    }
  }

  .mp-carousel-text {
    p {
      font-size: 16px;
    }
  }
}

.offer-img {
  max-width: 100%;
  height: 245px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 5px;
}

.MPCarousel {
  @media (max-width: 767px) {
    margin-bottom: 15px;
  }

  img {
    -webkit-border-radius: 9px 0px 0px 9px;
    -moz-border-radius: 9px 0px 0px 9px;
    border-radius: 9px 0px 0px 9px;
    object-fit: cover;
    height: 245px;

    @media (max-width: 575px) {
      -webkit-border-radius: 9px 9px 0px 0px;
      -moz-border-radius: 9px 9px 0px 0px;
      border-radius: 9px 9px 0px 0px;
    }
  }

  .carousel-indicators {
    [data-bs-target] {
      box-sizing: content-box;
      flex: 0 1 auto;
      width: 10px;
      height: 10px;
      padding: 0;
      margin-right: 5px;
      margin-left: 5px;
      text-indent: -999px;
      cursor: pointer;
      background-color: #ffffff;
      background-clip: padding-box;
      border: 0;
      opacity: 1;
      transition: opacity 0.6s ease;
      border-radius: 50%;
    }

    .active {
      opacity: 1;
      width: 17px;
      height: 9px;
      border-radius: 6px;
      border: 1px solid #FFFFFF;
      background-color: #173F78;
    }

  }
}

.mp-image-overlay {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: .5s ease;
  background-color: rgba(0, 0, 0, 0.33);
  border-radius: 5px;
}

.offer-section {
  .offer-img {
    position: relative;
    height: 186px;
    border-radius: 5px 5px 0px 0px;
  }

  .offer-icon {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #ffffff;
    padding: 5px;
    border-radius: 10px;
    width: 58px;
    height: 58px;

    img {
      text-align: center;
      margin: auto;
      vertical-align: middle;
      height: 100%;
      object-fit: contain;
      display: table;
    }
  }

  .offer-details {
    .offer-price {
      font-size: 11px;
      color: #252525;
    }

    .offer-exclusive {
      background-color: #8DB654;
      color: #ffffff;
      font-size: 9px;
      text-align: center;
      padding: 3px 10px;
      text-transform: uppercase;
    }

    .offer-desc p {
      color: #474747;
      font-size: 14px;
      font-weight: 500;
    }

    .user-info {
      img {
        height: 100%;
        min-height: 34px;
        max-height: 34px;
        width: 34px;
      }

      h6 {
        color: #474747;
      }
    }
  }
}

.custom-modal {
  .modal-header {
    background-color: #fff;
    position: relative;
    justify-content: left;
    padding: 15px;
    border-radius: 10px 10px 0 0;

    .modal-title {
      color: #000;
      font-size: 18px;
      text-align: center;
      font-weight: 500;
      padding: 0;
    }

    .btn-close {
      width: 32px;
      height: 32px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background-color: #ffffff;
      color: #000;
      position: absolute;
      right:24px;
      top:24px;
      opacity: 1;
      padding: 0;
      font-size: 27px;
      z-index: 10;
      line-height: 1.25;
      background-image: inherit;

      &:hover {
        background-color:#49484c;
        color: #ffffff;
      }
    }
  }

  .redeem-offer-percent {
    .img {
      margin-bottom: 15px;
    }

    h1 {
      font-size: 35px;
    }

    .form-check .form-check-label {
      cursor: pointer;
      color: #959595;
    }

    .form-check .form-check-input {
      margin-left: -2.8 rem;
      width: 15px;
      height: 15px;
      margin-top: 0.186rem;

      &:checked {
        background-color: #49484c;
        border-color: #959595;
      }

      &[type=checkbox] {
        width: 16px;
        height: 16px;
        border-radius: 0;
        border-color: #707070;
        background-size: 100% 60%;
      }
    }

    .btn-primary {
      padding: 10px 50px;
    }
  }
}

.redeem-QR {
  .modal-dialog {
    max-width: 520px;

    .modal-body {
      img.qr-code-img {
        max-height: 185px;
        min-height: 185px;
        height: 100%;
      }

      .redeem-offer-QR {
        p {
          color: #8F8C8C;
        }
      }
    }
  }
}

.redeem-offer {
  .modal-dialog {
    max-width: 611px;
  }
}

.offer-details-section {
  top: 30px;
  left: 30px;
  right: 30px;

  @media (max-width: 991px) {
    top: 35px;
  }

  @media (max-width: 767px) {
    top: 35px;
    position: inherit !important;
    margin: 20px 0;
  }

  .off-text-left {
    @media (max-width: 575px) {
      text-align: center !important;
    }

    .logo-img {
      height: 86px;
      width: 86px;

      .offer-logo-img {
        padding: 8px 6px;
        height: 86px;
        object-fit: contain;
        width: 100%;
      }
    }

    img {
      @media (max-width: 1199px) {
        width: 60px;
        height: 58px;
      }

      @media (max-width: 991px) {
        width: 50px;
        height: 48px;
      }

      @media (max-width: 575px) {
        margin: auto;
      }
    }

    h3 {
      font-size: 18px;
      color: #fff;
      font-weight: bold;
      margin: 22px 0 12px;

      @media (max-width: 1199px) {
        font-size: 16px;
      }

      @media (max-width: 767px) {
        color: #fff;
        font-size: 14px;
      }
    }

    p.banner-text {
      font-size: 14px;
      margin-bottom: 8px;
      color: #fff;

      @media (max-width: 1199px) {
        font-size: 13px;
      }

      @media (max-width: 991px) {
        margin-bottom: 0;
        font-size: 12px;
      }

      @media (max-width: 767px) {
        font-size: 11px;
      }
    }

    .available-time-offer small {
      position: relative;
      padding-left: 15px;

      &::before {
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        background-color: #B7DA1D;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        left: 0px;
        margin: 5px 0px;

      }
    }
  }
}

.market-place-ht {
  @media screen and (min-width:767px) {
    height: 300px !important;
  }
}

.off-text-right {
  @media (max-width: 575px) {
    text-align: center !important;
  }

  .offer {
    background-color: #ffffff;
    color: #000;
    width: fit-content;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    align-self: end;

    @media (max-width: 767px) {
      color: #000;
    }

    @media (max-width: 575px) {
      align-self: center;
    }
  }

  h2 {
    font-size: 53px;
    font-weight: 500;
    color: #fff !important;
    margin: 26px 0 24px 0;

    @media (max-width: 1199px) {
      font-size: 40px;

    }

    @media (max-width: 767px) {
      font-size: 30px;
    }

    @media (max-width: 575px) {
      font-size: 25px;

    }
  }

  h5 {
    font-size: 23px;
    color: #fff !important;
    margin: 22px 0 12px;

    @media (max-width: 1199px) {
      font-size: 18px;
    }

    @media (max-width: 767px) {
      font-size: 16px;
    }

    @media (max-width: 575px) {
      font-size: 14px;
    }

    span.offer-timer {
      font-size: 37px;
      padding-left: 15px;
      color: #fff !important;

      @media (max-width: 1199px) {
        font-size: 25px;

      }

      @media (max-width: 767px) {
        font-size: 20px;
      }

      @media (max-width: 575px) {
        font-size: 16px;

      }
    }
  }
}

.redeemed-members {
  .card {
    background-color: #F7F7F7;
    border-radius: 7px;

    .offer_main_img {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
        min-height: 85px;
        max-height: 85px;
      }
    }

    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.dashboard-page .com-card-section .offerDescription {
  .card-title {
    font-size: 24px;
  }
}

.offer-desc {
  p {
    @media (max-width: 768px) {
      font-size: 14px !important;
    }

    @media (max-width: 575px) {
      font-size: 12px !important;
    }
  }

  label {
    font-size: 16px;
    font-weight: 500;
    color: #919293;

    @media (max-width:768px) {
      font-size: 14px;
    }

    @media (max-width:575px) {
      font-size: 12px;
    }
  }

  strong {
    font-size: 16px;
    font-weight: 500;
    color: #000000;

    @media (max-width: 768px) {
      font-size: 14px;
    }

    @media (max-width: 576px) {
      font-size: 12px;
    }
  }
}
.form-check-input:checked + .payment-group {
    border-color: #49484C !important;
}
.make-an-offer-wdw.installment{
    .form-check-input {
        width: 1.5rem;
        height: 1.5rem;
        border: 1px solid rgba(0, 0, 0, 0.1) !important;
        &:checked{
        background-color: #F3B521 !important;
        border-color: #F3B521 !important;
        }
    }
    }
.make-an-offer-wdw {
  .payment-group {
    padding: 1rem !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
  .form-check-input {
    margin-left: 0;
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    &:checked{
    background-color: #F3B521 !important;
    border-color: #F3B521 !important;
    }
  }
    label.employe{
    width: 90%;
    }
    label {
      border-radius: 6px !important;
    color: #000000;
    font-weight: normal;
    padding: 0 !important;
    font-size: 12px !important;
    height: 50px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    width: 65%;
    border-color: #CBD6E2 !important;
    img{
    height: 28px;
    width: 28px;
    }
      @media only screen and (max-width: 575.98px) {
        height: 80px;
      }

      &:before {
        content: none !important;
        border: 1px solid #707070;
        border-radius: 50%;
        height: 14px;
        width: 14px;
        position: absolute;
        left: 18px;
        top: 10px;
        background-color: #fff;
      }

      span.cash-ioc {
//         background-image: url("../../../assets/images/cash_1.png");
        height: 26px;
        width: 26px;
        background-repeat: no-repeat;
        background-position: center;

      }

      span.cheque-ioc img {
//         background-image: url("../../../assets/images/cheque_1.png");
        height: 26px;
        width: 26px;
        background-repeat: no-repeat;
        background-position: center;
//     right: 0;
//     position: absolute;
      }

      span.emp-pay-ioc {
//         background-image: url("../../../assets/images/employer-payment.png");
        height: 26px;
        width: 26px;
        background-repeat: no-repeat;
        background-position: center;
//     right: 0;
//     position: absolute;
      }
    }

    .btn-check:checked+.btn-outline-primary,
    .btn-check:active+.btn-outline-primary,
    .btn-outline-primary:active,
    .btn-outline-primary.active,
    .btn-outline-primary.dropdown-toggle.show,
    .btn-outline-primary:hover {
      color: #FFFFFF !important;
      background-color: #49484c !important;
      border-color: #CBD6E2 !important;
    }

    .btn-check:checked+.btn-outline-primary>span.cash-ioc,
    .btn-check:active+.btn-outline-primary>span.cash-ioc,
    .btn-outline-primary:active>span.cash-ioc,
    .btn-outline-primary.active>span.cash-ioc,
    .btn-outline-primary:hover>span.cash-ioc {
      background-image: url("../../../assets/images/cash_1_White.png") !important;
    }

    .btn-check:checked+.btn-outline-primary>span.cheque-ioc,
    .btn-check:active+.btn-outline-primary>span.cheque-ioc,
    .btn-outline-primary:active>span.cheque-ioc,
    .btn-outline-primary.active>span.cheque-ioc,
    .btn-outline-primary:hover>span.cheque-ioc {
      background-image: url("../../../assets/images/cheque_1_White.png") !important;
    }

    .btn-check:checked+.btn-outline-primary>span.emp-pay-ioc,
    .btn-check:active+.btn-outline-primary>span.emp-pay-ioc,
    .btn-outline-primary:active>span.emp-pay-ioc,
    .btn-outline-primary.active>span.emp-pay-ioc,
    .btn-outline-primary:hover>span.emp-pay-ioc {
      background-image: url("../../../assets/images/employer-payment_white.png") !important;
    }
  }

  .num-pay-group {
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    overflow-x: auto;
    overflow-y: hidden;

    .btn-check:checked+.btn-outline-primary,
    .btn-check:active+.btn-outline-primary,
    .btn-outline-primary:active,
    .btn-outline-primary.active,
    .btn-outline-primary.dropdown-toggle.show,
    .btn-outline-primary:hover {
      color: #FFFFFF !important;
      background-color: #49484c !important;
      border-color: #49484c !important;
    }

    label.btn.btn-outline-primary {
      border-radius: 5px !important;
      color: #000000;
      font-weight: normal;
      font-size: 12px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border: 0 !important;

      &:before {
        content: none !important;
        border: 1px solid #707070;
        border-radius: 50%;
        height: 14px;
        width: 14px;
        position: absolute;
        left: 18px;
        top: 10px;
        background-color: #fff;
      }
    }
  }

  .form-range {
    height: 0.5rem;
  }
}

///////////////////// announcement ///////////////////////////
.check-list-scroll {
  min-height: 430px;
  max-height: 430px;
  height: 430px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
    margin: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
    margin: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ffffff;
    border-radius: 10px;
    margin: 10px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: #49484c !important;
  }
}

.announce-list {
  min-height: 665px;
  max-height: 665px;
  height: 665px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
    margin: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
    margin: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ffffff;
    border-radius: 10px;
    margin: 10px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: #49484c !important;
  }

  .announcements-card {
    //background: #EDF0F4;
    border-radius: 11px;
    border: 1px solid #E3E3E3;
    display: flex;
    overflow: hidden;
    border: 1px solid #E3E3E3;
    flex-direction: row;
    @media only screen and (min-width:320px) and (max-width:640px) {
      display: block;

      .announcements-card-img {
        margin-bottom: 10px;

        img {
          width: 100% !important;
          max-width: 100% !important;
        }
      }
    }

    .announcements-card-img {
      img {
        min-height: 125px;
        max-height: 125px;
        object-fit: cover;
        width: 125px;
        max-width: 125px;
        border-radius: 9px !important;

        @media only screen and (min-width:641px) and (max-width:768px) {
          min-height: 152px;
          max-height: 152px;
        }

        @media (max-width: 991.98px) {
          object-fit: contain;
        }
      }
    }

    .announcements-card-content {
      flex: 1;

      .row {
        flex: 1;
      }

      p {
        color: #707070;

        &.text-desc {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-word;
        }
      }

      .text-ellipsis {
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: inherit;
        &.announcements-title{
          -webkit-line-clamp: 1;
        }
      }

      .ShowMoreText {
        cursor: pointer;
        font-size: 14px;
        text-decoration: underline;
      }

      .bg-light {
        background-color: #F5F5F5 !important;

        p {
          color: #C7C7C7;

          i {
            color: #C7C7C7;
          }
        }

        img {
          min-height: 90px;
          max-height: 90px;
          object-fit: cover;
          width: 110px;
          max-width: 110px;
        }
      }
    }
  }

  //.card-body > .row > .col-12:last-child {
  //   .announcements-card {
  //     margin-bottom: 0 !important;
  //   }
  // }
}

////////////////////////////////////////////////

.announce-card {
  .announce-banner {
    min-height: 135px;
    max-height: 135px;
    object-fit: cover;
    width: 135px;
    max-width: 135px;
  }

  .bg-light-theme {
    background-color: #EDF0F4;

    .text-theme {
      color: #17437F;
    }

    .text-desc {
      color: #707070;
    }

    .text-ellipsis {
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.card .col-12:last-child>.announce-card {
  margin-bottom: 0 !important;
}

@media (min-width: 576px) {
  .modal-sm {
    max-width: 350px;
  }
}

//move-in
.movein-page .stepper {
  background-color: #F5F5F5;
  padding: 0;
  border-radius: 10px;
}

.movein-page .stepper.stepper-links .stepper-nav {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  border-radius: 10px 10px 0px 0px;
}

.stepper.stepper-links .stepper-nav .stepper-item .stepper-title {
  font-size: 14px;
  color: #A4A4A4;
}

.stepper.stepper-links .stepper-nav .stepper-item.current .stepper-title {
  color: #000000;
  font-weight: 500;
}

.stepper.stepper-links .stepper-nav .stepper-item.current:after {
  display: none;
}

.stepper.stepper-links .stepper-nav {
  display: flex;
  margin: inherit;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.movein-page .stepper .com-card-section.w-100 {
  padding: 1.5rem;
  background-color: #ffffff;

  .bg-light-gray {
    background-color: #F4F4F4;
  }
}

.movein-page .stepper {
  .clear-card {
    .bg-light-gray {
      background-color: #F2F2F2;
    }
  }
}

.stepper.stepper-links .stepper-nav .stepper-item.current {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #ffffff;
  margin: 0;
}

.stepper.stepper-links .stepper-nav .stepper-item {
  margin: 0;
  padding: 1rem 1.6rem;
  flex: 1;
  text-align: center;
  @media only screen and (max-width: 767.98px) {
    flex: none;
  }
}

.stepper.stepper-links .stepper-nav.move-out-nav .stepper-item {
  padding: 1rem 3rem;
}

.stepper.stepper-links .stepper-nav .stepper-item .stepper-title {
  margin-bottom: 0;
  line-height: 42px;
}

.stepper.stepper-links .stepper-nav .stepper-item {
  .current-step {
    display: none;
  }

  .completed-step {
    display: none;
    img {
      @media only screen and (max-width: 767.98px) {
        width: 17px;
        height: 17px;
      }
    }
  }
}

.stepper.stepper-links .stepper-nav .stepper-item.current {
  .current-step {
    display: inline-block;
  }

  .completed-step {
    display: none;
    img {
      @media only screen and (max-width: 767.98px) {
        width: 17px;
        height: 17px;
      }
    }
  }
}

.stepper.stepper-links .stepper-nav .stepper-item.completed {
  .current-step {
    display: none;
  }

  .completed-step {
    display: inline-block;
  }
}

.bg-light-gray-solid {
  background-color: rgba(242, 242, 242, 1);
}

// .step-3 img {
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   margin: auto;
// }
.step3-card-title {
  font-size: 12px;
}

.parking-slot-tab .nav-item .nav-link {
  margin-bottom: 3px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.475rem;
  border-top-right-radius: 0.475rem;
  font-size: 14px;
  color: rgba(164, 164, 164, 1);
  font-weight: normal;
}

.parking-slot-tab {
  border-bottom: 1px solid rgba(211, 211, 211, 1);
  width: 450px;
}

.parking-slot-tab .nav-item .nav-link span {
  font-weight: bold;
}

.parking-slot-tab .nav-item .nav-link.active {
  color: #000;
  border-color: transparent;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 36px;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #04377f;
    width: 60px;
    height: 2px;
  }
}

.parking-slot-tab .nav-item .nav-link.active span {
  font-weight: bold;
}

.movein-form {
  background-color: #f2f2f2;
  padding: 0;
}

.image-name {
  font-size: 14px;
}

.image-info {
  font-size: 10px;
}

.img-actions {
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    width: 39px;
    height: 39px;
    border-radius: 5px;
    background-color: #fff;
    padding: 10px;
    margin: 0px 3px;
    text-align: center;
    cursor: pointer;

    i {
      font-size: 18px;
    }

    .fa-pencil-alt {
      color: #1A9C1A;
    }

    .fa-trash {
      color: #EF1B1B;
    }

    .fa-plus-circle {
      color: #49484c;
    }
  }
}

.right_arrow {
  i {
    color: #49484c;
    font-size: 26px;
  }
}

.min-height-300px {
  min-height: 300px;
}

.action-btns {
  border-radius: 0 0 10px 10px;
  padding: 0 1.5rem 1.5rem;
}

.rounded-left {
  border-radius: 5px 0 0 5px;
}

.rounded-right {
  border-radius: 0 5px 5px 0;
}

.border-top-right-rounded-0 {
  border-top-right-radius: 0;
}

.border-bottom-right-rounded-0 {
  border-bottom-right-radius: 0;
}
.border-top-left-rounded-0 {
  border-top-left-radius: 0;
}

.border-bottom-left-rounded-0 {
  border-bottom-left-radius: 0;
}

.step3-page {
  border-radius: 0 0 10px 10px;
}

// Profile Details

.profile-new-details {
span {
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.3px;
  color: #000000;
  display: block;
}
 label {
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.3px;
  color: #434343;
  display: block;
  margin-bottom: 12px;
}
}
h3.general-title {
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.3px;
  color: #000000;
  margin-bottom: 23px
}
.profile-img-card {
  // background-color: #F2F2F2;
  position: relative;

  img {
    border: 3px solid white;
    max-height: 180px;
    height: 180px;
    width: 180px;
    object-fit: cover;

    @media (min-width: 992px) and (max-width: 1199px) {
      height: 122px;
      width: 122px;
    }
  }

  span {
    color: #707070;
  }

  .form-select.form-select-solid {
    color: #707070;
    background-image: url("../../../assets/images/Dropdown_circle.png") !important;
    background-size: 9px 9px !important;
    background-position: right 0.5rem center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.prof-det {
  label {
    color: #434343;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: inherit;
    margin-bottom: 10px;

    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 13px !important;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    span {
      font-size: 13px !important
    }
  }
}

.chat-main-page {
  .h-410px {
    height: 430px;
  }

  .chat-contacts {
    ul.nav li a {
      color: #7B7F95 !important;
      font-weight: 500 !important;
      font-size: 16px !important;
    }

    ul.nav li a.active {
      color: #1D1B1B !important;
    }

    .mark-read {
      color: #7B7F95;
    }
  }

  .chat-card-left {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 16px 16px 22px 18px;

    .chat-left {
      padding: 10px 20px 0;
      margin: 5px 0;
      cursor: pointer;

      &.active {
        background-color: #ffffff;
        border-radius: 17px;
      }

      &:hover {
        background-color: #ffffff;
        border-radius: 17px;
      }

      .chat-desc {
        font-size: 10px;
        color: #A9A8B9;
      }

      .active-state {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06);
        font-weight: bold;
        border-radius: 17px;
        background-color: #ffffff;
      }
    }

    .symbol.symbol-45px .symbol-label {
      width: 33px;
      height: 33px;
      border-radius: 11px;
    }

    .symbol.symbol-45px>img {
      width: 33px;
      height: 33px;
      border-radius: 11px;
    }

    .text-hover-primary:hover {
      color: #49484c !important;
    }

    .new-msg-btn {
      background-color: #ffffff;
      color: #0D346E;
      font-size: 10px;
      padding: 15px 25px !important;
      border: none !important;
      width: 100%;
      box-shadow: 0 7px 20px rgba(0, 0, 0, 0.09) !important;
      border-radius: 10px;

      i {
        color: #0D346E;
      }
    }
  }

  .chat-window-pic {
    img {
      width: 33px;
      height: 30px;
      border-radius: 11px;
    }
  }

  .chat-window-det {
    a {
      font-size: 11px;
      color: #0A336D;
    }
  }

  .chat-inner-symbol {
    width: 33px;

    img {
      width: 33px;
      height: 30px;
      border-radius: 11px;
    }
  }

  .chat-text {
    -webkit-box-shadow: 7px 10px 30px rgba(176, 171, 171, 0.14);
    -moz-box-shadow: 7px 10px 30px rgba(176, 171, 171, 0.14);
    box-shadow: 7px 10px 30px rgba(176, 171, 171, 0.14);
    border-radius: 26px 21px 16px 16px;
    font-size: 10px;
    color: #707070;
  }

  .chat-in {
    .chat-inner-symbol {
      order: 2;
    }

    .chat-text {
      order: 1;
      background-color: #eeeeee !important;
      color: #373333 !important;
      font-size: 11px;
      border-radius: 10px 10px 0 10px !important;
      position: relative;

      &::before {
        position: absolute;
        content: '';
        left: -25px;
        right: 0;
        top: 50%;
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 9px;
        height: 9px;
        background-image: url("../../../assets/images/read-msg-icon.png") !important;
      }
    }

  }

  .chat-reply {
    .chat-text {
      border-radius: 10px 10px 10px 0 !important;
    }
  }

  .text-chat-reply-time,
  .text-chat-in-time {
    font-size: 9px;
    color: #707070;
  }

  .card-footer {
    .span.slash {
      color: #707070;
    }

    .typing-area {
      resize: none;
      font-size: 11px;
      position: relative;

      &::placeholder {
        font-size: 11px;
        color: #707070;
      }

    }

    .send-msg {
      border-radius: 19px;
      font-size: 10px;
    }

    .btn-attachment {
      background-color: #f5f5f5;
      border-radius: 50%;
      width: 33px;
      height: 33px;

      &:hover {
        -webkit-box-shadow: 7px 10px 30px rgba(176, 171, 171, 0.14);
        -moz-box-shadow: 7px 10px 30px rgba(176, 171, 171, 0.14);
        box-shadow: 7px 10px 30px rgba(176, 171, 171, 0.14);
      }

      i {
        color: #49484c;
        transform: rotate(45deg);
      }
    }
  }
}

.main-profile {
  // position: absolute;
  //   display: block;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;

  @media (min-width: 992px) and (max-width: 1199px) {
    right: 0%;
    bottom: 0;
  }
  button{
    background: #49484C;
    border-radius: 8px;
    width: 100%;
    padding: 10px;
    color:#fff;
    font-size: 14px;
    font-weight: 500;
  }
}

#profile-check {
  &:checked {
    background-color: #9eadc3;
    border-color: #9eadc3;
  }
}

// Settings
.settings-card {
  border: 1px solid #E4E4E4;

  p {
    color: #707070;
  }

  .form-switch {
    .form-check-input {
      width: 48px;
      height: 24px;
    }
  }

  .trial-ring {
    span {
      color: #707070;
    }

    img {
      width: 35px;
    }
  }

  hr {
    margin: 14px 0 14px 36px;
  }
}

//Faq
.faq-accordian {
  .accordion-item {
    margin-bottom: 15px;
    background-color: #ffffff;
    border: 1px solid #e4e4e4;
    border-radius: 8px;

    &:first-of-type {
      .accordion-button {
        border-radius: 8px;
      }
    }

    .accordion-header {
      .accordion-button {
        border-radius: 8px;
        font-size: 16px;
        color: #000000;
        background-color: #ffffff;
        font-weight: 500;
        padding: 20px;
      }
    }

    .accordion-collapse {
      .accordion-body {
        font-size: 14px;
        color: #707070;
        line-height: 21px;
        padding: 0 30px 30px;
      }
    }
  }
}

.faq-accordian .accordion-item .accordion-header .accordion-button[aria-expanded="false"]::after {
  background-image: url("../../../assets/images/down_arrow.png");
  width: 23px;
  height: 11px;
  background-size: auto;
  transform: none;
  filter: grayscale(1);
}

.faq-accordian .accordion-item .accordion-header .accordion-button[aria-expanded="true"]::after {
  background-image: url("../../../assets/images/up_arrow.png");
  width: 23px;
  height: 11px;
  background-size: auto;
  transform: none;
  filter: grayscale(1);
}

//Documents
.documents-card {
  p {
    color: #757575;
  }

  .btn-outline-primary {
    background-color: #ffffff !important;
    border: 1px dashed #A4A4A4 !important;
    color: #707070 !important;
    border-radius: 7px !important;
    font-size: 12px;
    font-weight: 500;
    height: 118px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #ffffff !important;
      border: 1px dashed #A4A4A4 !important;
      color: #707070 !important;
    }

    &:focus {
      outline: 0 !important;
      box-shadow: none !important;
    }

    &.active {
      //background-color: rgba(0, 44, 106, 0.15) !important;
      background-color:#fff3de !important;
      border: 0 !important;
      background-image: url("../../../assets/images/tick-circle.svg") !important;
      //background-image: url("../../../assets/images/profile-av-lg.jpg") !important;
      background-position: center 28px;
      background-repeat: no-repeat;
      background-size: 25px;
      display: inline-block;

      span {
        margin-top: 47px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.redeem-offer-QR{
  img{
    width: 60px;
    height: 60px;
  }
}

// Intl Input Styles
.intl-tel-input {
  .flag-container {

    //width: auto !important;
    .selected-flag {
      width: auto !important;
      padding: 10px 10px;
      justify-content: left;
      height: 44px !important;
      border-radius: 7px 0 0 7px;
      z-index: 0;
      .iti-flag {
        margin-top: 5px;
      }

      .selected-dial-code {
        padding-left: 8px !important;
      }

      .arrow.down::after {
        position: absolute;
        top: 18px;
      }
    }
  }

  .country-list {
    border-radius: 7px;
    max-width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);

    .country {
      span {
        color: #9C9FA6 !important;
        font-weight: 500 !important;
      }
    }
  }
}

//--------------- Team Activites------------------//

.side-menu-custom .menu-sub>.menu-title::active {
  color: #000 !important;
}

.side-menu-custom .menu-sub {
  width: 88%;
  margin: 0 auto;
  //background: #eff2f6;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  &.show {
    background-color: #EFF2F6 !important;

    .menu-link {
      padding-left: 45px !important;
      padding-right: 0 !important;

      &.active,
      &:hover {
        background-color: rgba(29, 74, 134, 0.1) !important;
        border-radius: 5px !important;

        span {
          color: #000000 !important;
        }
      }

      span {
        color: #000000 !important;
        font-weight: 500 !important;
        font-size: 14px !important;
      }
    }
  }

  .menu-item {
    &:first-child {
      margin-top: 8px;
    }

    &:last-child {
      margin-bottom: 8px;
    }
  }
}

.side-menu-custom .menu-sub a {
  padding: 8px 15px !important;
}

.aside-dark .menu .menu-item.hover>.menu-link:not(.disabled):not(.active),
.aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) {
  background-color:transparent !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.custom-search-form {
  border: 1px solid #ECECEC;
  border-radius: 5px;
  background: #FAFAFA;
  height: 42px;

  .search-input {
    background: transparent;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 400;
    color: #000000;

    &::-webkit-input-placeholder {
      color: #000000;
      font-size: 14px;
      font-weight: 400;
    }

    &:-ms-input-placeholder {
      color: #000000;
      font-size: 14px;
      font-weight: 400;
    }

    &::placeholder {
      color: #000000;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .search_icon {
    background-image: url("../../../assets/images/search_icon.png");
    width: 40px;
    height: 42px;
    background-size: auto;
    transform: none;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid transparent;
    background-color: transparent;
  }

  @media only screen and (min-width:320px) and (max-width:641px) {
    margin-top: 15px;
  }
}

.filter-select {
  border: 1px solid #ECECEC;
  display: block;
  width: 100%;
  padding: 0.75rem 3rem 0.75rem 1rem;
  -moz-padding-start: calc(1rem - 3px);
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  color: #000000;
  background-color: #FAFAFA;
  background-image: url("../../../assets/images/filter.png");
  -webkit-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 19px 17px;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  float: right;
  outline: 0 !important;
}

.upcoming-Div {
  background-color: #fafafa;
  border: 1px solid #ececec;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  .upcoming_img {
    width: 100%;
    height: 170px;
    overflow: hidden;
    border-radius: 9px;

    img {
      width: 100%;
      height: 175px;
      object-fit: cover;
    }

    .map-icon {
      width: 12px;
    }
  }

  .up-co-cl {
    img {
      padding-right: 5px;
    }

    p {
      font-size: 13px;
      font-weight: 600;
    }
  }

  @media only screen and (min-width:320px) and (max-width:641px) {
    .visit-confirm {
      margin-right: 0 !important;
    }

    .upcoming_img {
      width: 100%;
      height: 170px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
}

.visit-confirm {
  background: #fff;
  padding: 15px;
  border-radius: 20px;
  align-items: center;

  .visit-c-img {
    width: 50%;

    .visit-c-img-div {
      height: 50px;
      width: auto;
      overflow: hidden;

      img {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }
    }

    p {
      margin-bottom: 0 !important;
      color: #000 !important;

      span {
        background: #25BE48;
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: 50px;
      }
    }
  }

  .visit-c-icon {
    width: 50%;
    text-align: center;

    img {
      width: 30px;
      height: 30px;
    }
  }
}

/// Filter Start /////////
#filter_btn {
  border: 1px solid #E3E3E3 !important;
  border-radius: 10px;
  width: 100% !important;
  padding: 10px 4px;
  display: grid;

  &:hover {
    background-color: #fff !important;
    border: 1px solid #49484c !important;
  }

  &:active,
  &:focus {
    border: 1px solid #49484c !important;
    background: transparent !important;
    border-radius: 10px;
  }
}

.filterWrap {
  .inner-sec {
    max-height: 235px;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
  }
.property-type{
  label{
    img{
      filter: grayscale(1);
    }
  }
}
  .filterBtnGroup {
    display: inherit;

    label {
      img {
        width: auto;
        height: 40px;
        object-fit: cover;
      }

      span {
        font-size: 11px;
        color: #4F4F4F;

        @media only screen and (min-width:320px) and (max-width:641px) {
          font-size: 10px;
        }
      }
    }
  }

  @media only screen and (min-width:320px) and (max-width:641px) {
    overflow-x: auto;

    .filterBtnGroup {
      width: 33%;
    }
  }
}

.filter_btn:checked+#filter_btn,
.filter_btn:active+#filter_btn,
#filter_btn:active,
#filter_btn.active,
#filter_btn.dropdown-toggle.show {
  border: 1px solid transparent !important;
  background: linear-gradient(95.12deg, #F8E6BB -7.77%, #F3B521 117.7%) !important;
  border-radius: 10px;
}

.bedrooms {
  width: auto !important;
}

.budget {
  align-items: center;

  select {
    font-size: 10px;
    color: #57585B;
  }
}

.monthly {
  font-size: 10px;
  color: #57585B;
}

.budget_min_max {
  label {
    font-size: 14px;
    color: #8F929E;
  }
}




/// Filter END //////////////

// Visit Confirm////
.confirm-visi {
  float: right;
  display: flex;

  @media only screen and (min-width:320px) and (max-width:640px) {
    margin-top: 10px;
  }

  p {
    font-weight: 600;
    padding-left: 10px;
    font-size: 20px;
  }

  .visitconfirm-img {
    img {
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 50px;
    }
  }
}

.isit-confirm-left {
  height: 240px;
  background: #FAFAFA;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.confirm-main-div {
  background: #FAFAFA;
  //height: 240px;
  border: 1px solid #ECECEC;
  border-radius: 10px;
  margin-left: 10px;

  .visit-confirm-right {
    height: 240px;

    @media only screen and (min-width:641px) and (max-width:991px) {
      height: 180px;
    }
  }

  @media only screen and (min-width:320px) and (max-width:641px) {
    height: auto;

    .visit-confirm-right {
      height: auto;
    }
  }
}

.visit-confirm-right-div1 {
  p {
    font-size: 16px;

    span {
      font-weight: 600;
      padding-left: 10px;
    }

    @media only screen and (min-width:320px) and (max-width:640px) {
      text-align: center;
    }
  }
}

.visit-confirm-right-div2 {
  p {
    background-color: #70BF42;
    padding: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 150px;
    text-align: center;
    font-weight: 800;
    color: #fff;
    float: right;
    font-size: 16px;

    @media only screen and (min-width:320px) and (max-width:640px) {
      margin: 0 auto;
      float: left;
      width: 96%;
    }
  }
}

.visit-confirm-date-div {
  .upcoming-date {
    font-weight: 600;
  }

  p {
    font-size: 16px;
  }

  @media only screen and (min-width:641px) and (max-width:991px) {
    margin-top: 0 !important;

    p {
      font-size: 13px;
    }
  }
}

@media only screen and (min-width:641px) and (max-width:991px) {
  .reschedule_main_div {
    margin-top: 0 !important;
  }
}

.confirm-visi2 {
  display: flex;

  .visitconfirm-img2 {
    img {
      width: 30px;
      height: 30px;
      overflow: hidden;
      border-radius: 50px;
    }
  }

  p {
    padding-top: 0;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0 !important;
  }
}

@media only screen and (min-width:320px) and (max-width:640px) {
  .reschedule {
    width: 100%;
    margin-top: 10px;
  }
}

//Team Activity Offer Menu //////////////
.offer_link {
  .offer_main_div {
    background: #FAFAFA;
    border: 1px solid #ECECEC;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    height: 135px;

    @media only screen and (min-width:320px) and (max-width:640px) {
      display: inline-table;
    }

    .offer_main_img {
      overflow: hidden;
      height: 135px;
      width: 37%;

      @media only screen and (min-width:320px) and (max-width:640px) {
        width: 100%;
        border-radius: 10px;
      }

      img {
        height: 135px;

        @media only screen and (min-width:320px) and (max-width:640px) {
          width: 100%;
        }
      }
    }

    .offer_main_details {
      padding: 10px;
      width: 63%;

      @media only screen and (min-width:320px) and (max-width:640px) {
        width: 100%;
      }

      p {
        color: #AFAFAF;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 6px !important;
        img{
          filter: brightness(0.5);
        }
      }

      h3 {
        font-weight: 400;

        small {
          font-size: 13px;
          font-weight: 400;
        }

        span {
          font-weight: 800;
        }
      }

      .offer_main_details_div_2 {
        display: flex;
        border-bottom: 1px solid #E6E6E6;
        padding-bottom: 8px;

        p {
          margin-bottom: 0 !important;
          width: 20%;
          //padding-right: 16px;
          font-size: 15px;
          color: #000;

          img {
            padding-right: 7px;
            width: 23px;
            height: 16px;
          }
        }

        .sqft {
          width: 40%;
        }
      }

      .offer_main_details_div_3 {
        padding-top: 6px;

        p {
          margin-bottom: 0 !important;

          img {
            padding-right: 7px;
          }
        }
      }
    }
  }
}

.all_card_title {
  margin-bottom: 0 !important;
}

/// offers Details////

.offerDetails {
  background: #FAFAFA;
  border: 1px solid #ECECEC;
  border-radius: 10px;
}

.offers-number {
  background: #49484c;
  text-align: center;
  padding: 20px 10px;
  border-radius: 10px;

  h3 {
    font-size: 21px;
    color: #fff;
  }

  h1 {
    font-size: 36px;
    font-weight: 800;
    color: #fff;
  }
}

.offers-tenant {
  background: #fff;
  padding: 32px 38px;
  border-radius: 10px;
  display: flex;

  @media only screen and (min-width:320px) and (max-width:640px) {
    display: contents;
    padding: 10px;

    .tanants {
      width: 100% !important;
      padding: 10px;
      background: #fff;
    }
  }

  .tanants {
    width: 33.3%;

    h4 {
      font-weight: 700;
      font-size: 17px;
      margin-bottom: 0px !important;
    }

    p {
      font-size: 16px;
    }
  }
}

.offers_details_accordian_div1 {
  background-color: #fff !important;
  margin-bottom: 15px;
  border-radius: 10px !important;

  .accordian_bt {
    border-radius: 10px !important;
    font-weight: 800;
    font-size: 17px;

    @media only screen and (max-width: 767.98px) {
      font-size: 15px;
    }
  }

  .accordion-button:not(.collapsed) {
    color: #000;
    background-color: #fff;
    box-shadow: none;
  }
}

.payment-plan-details {
  margin-bottom: 0;
}

.tanantsss {
  padding-left: 25px;
  margin-bottom: 30px;

  @media only screen and (min-width:320px) and (max-width:640px) {
    padding-left: 0;
  }

  p {
    font-size: 15px;
    color: #A6A6A6;
    font-weight: normal;
  }

  h4 {
    font-weight: 700;
    color: #000;
    font-size: 15px;
  }
}

.accordion-button::after {
  width: 2.15rem;
  height: 2.15rem;
  background-size: 1.55rem;
  background-position: center;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../../../assets/images/arrow-down.svg");
}

.offers_bt-buttons {
  width: 80%;
  margin: 0 auto;
  display: block;
  border: none;
  padding: 15px 10px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.extend_offer {
  background: #E5E9F0;
  color: #49484c;
}

.pay_now {
  background: #49484c;
  color: #ffff;
}

.brokers-count {
  border: 1px solid #ECECEC;
  background-color: #FAFAFA;
  // height: 340px;
  // max-height: 340px;

  @media only screen and (min-width:320px) and (max-width:640px) {
    // height: 260px;
  }
}
.max-ht{
  max-height: 200px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 200px;
  z-index: 9999;
}
// Start Broker Admin Dashboard
.broker-dash-page {
  .nav-line-tabs .nav-item .nav-link {
    margin-right: 2rem !important;

    &.text-active-primary.active::after {
      width: 28px;
    }
  }

  .product-card {
    box-shadow: inherit;

    img {
      &.card-img-top {
        width: 100%;
        height: 155px;
        min-height: 155px;
        max-height: 155px;
        object-fit: cover;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border: 0 !important;
      }
    }

    .card-body {
      border-color: #ECECEC;
      background-color: #FAFAFA;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      h5 {
        font-size: 12px;
        margin-bottom: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .price {
        font-size: 12px;
        margin-bottom: 5px;

        span {
          font-size: 19px;
        }
      }

      .facility-box {
        ul {
          padding-left: 0;
          margin-bottom: 10px;

          li {
            display: inline-block;
            padding-right: 20px;

            img {
              width: 16px;
              height: 16px;
              filter:grayscale(1)
            }

            span {
              font-size: 11px;
              font-weight: normal;
              padding-left: 5px;
            }
          }
        }
      }

      .facility-location {
        font-size: 12px;
        padding-top: 8px;
        border-top: 1px solid #e6e6e6;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        >span {
          padding-right: 8px;

          img {
            width: auto;
            height: auto;
          }
        }
      }
    }

    &:hover {
      border-color: transparent;
      -webkit-box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%),
        0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
      box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),
        0 5px 12px 4px rgb(0 0 0 / 9%);
    }
  }

  .brokers-list {
    border: 1px solid #ECECEC;
    background-color: #FAFAFA;
    height: 100%;
    min-height: 300px;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 4px;
      margin: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #FAFAFA;
      border-radius: 10px;
      margin: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #FAFAFA;
      border-radius: 10px;
      margin: 10px;
    }

    &:hover::-webkit-scrollbar-thumb {
      background-color: #49484c !important;
    }

    .card-body {
      .box {
        &:last-child {
          margin-bottom: 0 !important;
        }

        .broker-av {
          img {
            height: 100%;
            max-height: 54px;
            min-height: 54px;
            object-fit: cover;
            width: 100%;

            @media (max-width: 1200px) {
              max-height: unset;
              min-height: unset;
              border-radius: 50%;
              width: 80px;
              height: 80px;
              display: block;
              margin: auto;

              @media (max-width: 320px) {
                width: 40px;
                height: 40px;
              }
            }
          }
        }

        .broker-name {
          h4 {
            color: #1D1D1F;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .brokers-count {
    border: 1px solid #ECECEC;
    background-color: #FAFAFA;
    // height: 340px;
    // max-height: 340px;

    .card-body {
      .nav-line-tabs .nav-item .nav-link {
        padding: 0;
      }

      .nav-line-tabs .nav-item .nav-link.text-active-primary.active::after {
        top: 45px;
      }

      .Doughnut-Chart .inner-text {
        position: absolute;
        left: 0;
        right: 130px;
        top: 43%;
      }
    }
  }

  //.broker-dash-discover{
  //  height:465px;
  //  overflow-y: auto;
  //  overflow-x: hidden;
  //}
}

.Doughnut-Chart .inner-text {
  position: absolute;
  left: 0;
  right: 0;
  // transform: translate(-17%, -50%);
  top: 38%;

  @media only screen and (min-width:320px) and (max-width:1367px) {
    // left: -27px !important;
  }


  // @media only screen and (min-width:641px) and (max-width:1024px){
  //   transform: translate(-11%, -50%) !important;
  // }
  // @media only screen and (min-width:320px) and (max-width:641px){
  //   transform: translate(-24%, -50%) !important;
  // }
}

.broker-admin {
  overflow: hidden;

  .Doughnut-Chart {
    width: 80%;
    margin: 0 auto;

    // .inner-text {
    //   transform: translate(-19%, -50%);
    // }

    @media only screen and (min-width:992px) and (max-width:1280px) {
      width: 100%;

    }

    @media only screen and (min-width:641px) and (max-width:991px) {
      width: 44%;

      // .inner-text {
      //   transform: translate(-17%, -50%);
      // }

    }

    @media only screen and (min-width:320px) and (max-width:640px) {
      width: 100%;

      // .inner-text {
      //   transform: translate(-24%, -50%);
      // }

    }
  }
}

.service-req-img {
  img {
    width: 50px;
    height: 25px;
    object-fit: cover;
    border-radius: 6px;
  }
}

.bg-primary {
  background: #49484c !important;
}

.service-request-activity {
  background: #F7F7F7;

  .wrapper-progressBar {
    width: 100%;
    height: 95px;
  }

  .progressBar {
    overflow-x: auto;
    display: flex;
    justify-content: space-between;
  }

  .progressBar li {
    list-style-type: none;
    float: left;
    width: 600px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    font-size: 12px;
    padding-left: 24px;
  }

  .progressBar li:before {
    content: " ";
    line-height: 30px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 1px solid #ddd;
    display: block;
    text-align: center;
    margin: 0 auto 20px;
    background-color: #21477C;
    z-index: +2;
  }

  .progressBar li::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #21477C;
    top: 9px;
    left: -50%;
    z-index: +1;
  }

  .progressBar li:first-child::after {
    content: none;
  }

  .progressBar li.active {
    color: #21477C;
  }

  .progressBar li.active::before {
    border-color: #21477C;
    background-color: #ffff;
    position: relative;
  }

  .progressBar li.active+li::after {
    background-color: #21477C;
  }
}

.help-popup-modal {
  .accordion-button:not(.collapsed) {
    color: #49484c;
    background-color: #f7f7f7;
    box-shadow: inset 0 -1px 0 #eff2f5;
  }
}
a:hover{
  color:#000;
}

// End Broker Admin Dashboard
// Start My Team
.broker-det {
  align-items: center;
  justify-content: start;

  span {
    font-size: 16px;
    color: #1D1D1F;

    img {
      width: 42px;
      height: 42px;
    }
  }
}

.broker-team-page {
  .team-brokers-list {
    border: 1px solid rgba(112, 112, 112, 0.10);
    background-color: #FAFAFA;
    height: 100%;
    min-height: 340px;
    max-height: 340px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 7px;
      margin: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 10px;
      margin: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 10px;
      margin: 10px;
    }

    &:hover::-webkit-scrollbar-thumb {
      background-color: #49484c !important;
    }

    .table {
      white-space: nowrap;

      tr {
        &:nth-child(even) {
          background: #ffffff;

          td:first-child {
            border-radius: 5px 0 0 5px;
          }

          td:last-child {
            border-radius: 0 5px 5px 0;
          }
        }
      }

      thead {
        background: #ffffff;
        position: sticky;
        top: 0;

        th {
          color: #1D1D1F;
          font-size: 14px;
          font-weight: bold;
          position: sticky;
          top: 0;
        }

        th:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        th:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }

      td {
        font-size: 15px;
        color: #1D1D1F;
        font-weight: normal;
        padding: 5px 8px;
      }
    }

    .broker-det {
      align-items: center;
      justify-content: start;

      span {
        font-size: 16px;
        color: #1D1D1F;

        img {
          width: 42px;
          height: 42px;
        }
      }
    }
  }
}

.attach-images-video {
  color: #A4A4A4;
}

//add broker modal
.add-broker-modal {
  .modal-content {
    .modal-header {
      border-bottom: 1px solid transparent;
      padding: 40px 40px 30px;

      .modal-title {
        font-size: 18px;
        color: #3D3D3D;
        font-weight: 600;

        @media screen and (max-width:991px) {
          font-size: 16px;
        }

        @media screen and (max-width:768px) {
          font-size: 14px;
        }

        @media screen and (max-width:576px) {
          font-size: 12px;
        }
      }

      .btn-close {
        color: #707070;
        opacity: 1;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .modal-body {
      padding: 0 40px 10px;

      .form-label {
        color: #A4A4A4;
      }

      .form-control {
        border-radius: 5px;
        height: 42px;
      }
    }

    .modal-footer {
      border-top: 1px solid transparent;
      padding: 0 40px 40px;

      .btn-primary {
        margin: 0;
        font-size: 13px;
        font-weight: bold;
        height: 42px;
      }
    }
  }
}

//verification-modal
.verification-modal {
  .modal-dialog {
    max-width: 350px;
  }

  .modal-body {
    padding: 40px;

    .verification-box {
      img {
        width: 40px;
        height: 40px;
        margin-bottom: 5px;
      }

      p {
        font-size: 12px;
        color: #707070;
        font-weight: normal;
      }

      button {
        height: 42px;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}

// End My Team
// End Broker Admin Dashboard//

// Contracts/////////////////
.uninvoiced_main_div {
  padding: 20px;
  width: 63%;

  h3 {
    font-weight: 500 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .uninvoiced_div {
    display: flex;
    padding-top: 11px;

    p {
      color: #A6A6A6;
      font-size: 15px;
      margin-bottom: 5px !important;
    }

    .unitnumber {
      width: 50%;

      .unitnumber_p {
        color: #000;
      }

      .approved {
        color: #2AC940;
      }

      .rejected {
        color: #FF0000;
      }

      .inprogress {
        color: #F2A41E;
      }
    }
  }
}

/////// Contracts - Property Details ////////////

.properties_details_main_div1 {
  background: #FAFAFA;
  padding: 20px 15px;
  border: 1px solid #ECECEC;
  border-radius: 10px;
}

.properties_details_main_div1_right {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 15px;

  .lease-details {
    margin-bottom: 10px;

    p {
      margin-bottom: 7px !important;
      color: #A6A6A6;
      font-size: 15px;
    }

    h4 {
      color: #04377F;
      margin-bottom: 0 !important;
    }
  }
}

.property-uploads {
  label {
    color: #A6A6A6;
  }

  .fileupload {
    display: inline-block;
    background-color: #ECECEC;
    color: #000;
    padding: 15px;
    font-size: 17px;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 1rem;
    width: 100%;
    text-align: center;

    img {
      padding-left: 44px;
    }

    .bg-light {
      background-color: #FAFAFA !important;
    }
  }
}

.extendedoffer {
  background: #49484c;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  display: block;
  top: 58px;
  position: relative;
  border: none;
  padding: 16px;
  border-radius: 10px;
  font-weight: 600;
}

// Reschedule Popup ///////////

// Start Facility Contractor Soft Services Styles
.fc-ss-dashboard-page {
  @media only screen and (min-width:320px) and (max-width:640px) {
    .rec-request-sec .slick-slider .card {
      margin-right: 0 !important;
    }

  }

  @media only screen and (min-width:641px) and (max-width:991px) {}

  .rec-request-sec {
    .slick-slider {
      .slick-list {
        >.slick-track {
          .slick-slide {
            padding: 0 10px;

            &:nth-child(even) {
              >div {
                >div {
                  >a {
                    >.card {
                      margin-right: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .slick-prev {
        right: 27px;
        left: inherit;
      }

      .slick-next {
        right: 0;
      }

      .slick-prev,
      .slick-next {
        top: -14px;
        width: 23px;
        height: 23px;
      }

      .slick-prev:not(.slick-disabled):before,
      .slick-next:not(.slick-disabled):before {
        opacity: 0.5;
      }

      .slick-prev:before,
      .slick-next:before {
        font-size: 15px;
        color: #000000;
        font-weight: 600;
        font-family: 'Font Awesome 5 Free';
      }

      .slick-next:before {
        content: '\f061';
      }

      .slick-prev:before {
        content: '\f060';
      }

      .card {
        border-radius: 10px;
        border: 0;
        margin: 0;
        background-color: #F5F5F5;

        .card-body {
          border-radius: 10px;

          .card-title {
            color: #000000;
            font-size: 16px;
            line-height: normal;
            font-weight: 600;
            vertical-align: text-top;

            @media (max-width: 1199px) {
              font-size: 15px;
            }
          }

          .btn-primary {
            height: 40px;

            &:hover {
              box-shadow: 0 1rem 2rem 1rem rgb(0 0 0 / 10%) !important;
            }
          }
        }
      }

     
    }
  }

  .quick-link-sec {
    .slick-slider {
      .slick-list {
        padding: 0 60px 10px 0 !important;

        >.slick-track {
          .slick-slide {
            padding: 0 5px;

            &:last-child {
              >div {
                >div {
                  >a {
                    >.card {
                      margin-right: 2px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .slick-prev {
        right: 27px;
        left: inherit;
      }

      .slick-next {
        right: 0;
      }

      .slick-prev,
      .slick-next {
        top: -14px;
        width: 23px;
        height: 23px;
      }

      .slick-prev:not(.slick-disabled):before,
      .slick-next:not(.slick-disabled):before {
        opacity: 0.5;
      }

      .slick-prev:before,
      .slick-next:before {
        font-size: 15px;
        color: #000000;
        font-weight: 600;
        font-family: 'Font Awesome 5 Free';
      }

      .slick-next:before {
        content: '\f061';
      }

      .slick-prev:before {
        content: '\f060';
      }

      .card {
        border-radius: 11px;
        border: 1px solid #E3E3E3;
        margin: 0;

        .card-body {
          border-radius: 11px;

          img {
            width: 38px;
            height: 38px;
            object-fit: contain;
          }
        }

        .card-title {
          color: #000000;
          font-size: 14px;
          font-weight: 500;
          margin-left: 8px;
        }
      }

      .card:hover {
        box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
        background-color: #F5F5F5;
      }
    }
  }

  .visits-place-sec {
    .slick-slider {
      .slick-list {
        padding: 0 60px 10px 0 !important;

        >.slick-track {
          .slick-slide:nth-child(even) {
            >div {
              >div {
                >a {
                  >.card {
                    margin-right: 2px !important;
                  }
                }
              }
            }
          }
        }
      }

      .slick-prev {
        right: 27px;
        left: inherit;
      }

      .slick-next {
        right: 0;
      }

      .slick-prev,
      .slick-next {
        top: -14px;
        width: 23px;
        height: 23px;
      }

      .slick-prev:not(.slick-disabled):before,
      .slick-next:not(.slick-disabled):before {
        opacity: 0.5;
      }

      .slick-prev:before,
      .slick-next:before {
        font-size: 15px;
        color: #000000;
        font-weight: 600;
        font-family: 'Font Awesome 5 Free';
      }

      .slick-next:before {
        content: '\f061';
      }

      .slick-prev:before {
        content: '\f060';
      }

      .card {
        border-radius: 11px;
        border: 1px solid #E3E3E3;
        margin: 0;
        min-height: 140px;
        max-height: 140px;
        overflow-y: hidden;

        @media only screen and (min-width:320px) and (max-width:767px) {
          min-height: 244px;
          max-height: 244px;
        }

        .card-body {
          border-radius: 11px;
          padding: 18px;

          .img-bg {
            img {
              border-radius: 5px;
              height: 100%;
              min-height: 96px;
              max-height: 96px;
              width: 100%;
              object-fit: cover;
            }
          }
        }

        .card-title {
          color: #000000;
          font-size: 15px;
          line-height: normal;
          font-weight: 600;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .sub-title {
          color: #000000;
          font-size: 11px;
          line-height: normal;
          font-weight: 500;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .time-date {
          color: #252525;
          font-size: 12px;
          line-height: normal;
          font-weight: 500;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .count {
          color: #184581;
          font-size: 12px;
          line-height: normal;
          font-weight: 500;
          background-color: #F2F3F5;
          border-radius: 5px;
          padding: 3px 16px;
          height: 25px;

          @media (max-width: 1199px) {
            padding: 3px 10px;
          }
        }

        .sch-status {
          color: #252525;
          font-size: 12px;
          font-weight: 500;
        }
      }

      .card:hover {
        background-color: #F5F5F5;
      }
    }
  }

  .announcement-sec {
    .slick-slider {
      .slick-list {
        padding: 0 60px 10px 0 !important;

        >.slick-track {
          .slick-slide {
            padding: 0 10px;

            &:nth-child(even) {
              >div {
                >div {
                  >a {
                    >.card {
                      margin-right: 2px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .slick-prev {
        right: 27px;
        left: inherit;
      }

      .slick-next {
        right: 0;
      }

      .slick-prev,
      .slick-next {
        top: -14px;
        width: 23px;
        height: 23px;
      }

      .slick-prev:not(.slick-disabled):before,
      .slick-next:not(.slick-disabled):before {
        opacity: 0.5;
      }

      .slick-prev:before,
      .slick-next:before {
        font-size: 15px;
        color: #000000;
        font-weight: 600;
        font-family: 'Font Awesome 5 Free';
      }

      .slick-next:before {
        content: '\f061';
      }

      .slick-prev:before {
        content: '\f060';
      }

      .card {
        border-radius: 11px;
        border: 1px solid #E3E3E3;
        margin: 0;
        min-height: 112px;
        overflow-y: hidden;

        @media only screen and (min-width:320px) and (max-width:640px) {
          min-height: 200px;
        }

        .card-body {
          border-radius: 11px;
          padding: 18px;

          .announce-img {
            border-radius: 5px;
            height: 100%;
            min-height: 70px;
            max-height: 70px;
            width: 100%;
            object-fit: cover;
          }
        }

        .card-title {
          color: #000000;
          font-size: 16px;
          line-height: normal;
          font-weight: 700;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .sch-date {
          color: #000000;
          font-size: 12px;
          line-height: normal;
          font-weight: 500;
        }

        .sch-time {
          color: rgba(0, 0, 0, 0.5);
          font-size: 12px;
          font-weight: 400;
          line-height: normal;
        }
      }

      .card:hover {
        background-color: #F5F5F5;
      }
    }
  }

  .market-place-sec {
    .slick-slider {
      .slick-list {
        padding: 0 60px 10px 0 !important;

        >.slick-track {
          .slick-slide {
            padding: 0 10px;

            &:nth-child(even) {
              >div {
                >div {
                  >a {
                    >.card {
                      margin-right: 2px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .slick-prev {
        right: 27px;
        left: inherit;
      }

      .slick-next {
        right: 0;
      }

      .slick-prev,
      .slick-next {
        top: -14px;
        width: 23px;
        height: 23px;
      }

      .slick-prev:not(.slick-disabled):before,
      .slick-next:not(.slick-disabled):before {
        opacity: 0.5;
      }

      .slick-prev:before,
      .slick-next:before {
        font-size: 15px;
        color: #000000;
        font-weight: 600;
        font-family: 'Font Awesome 5 Free';
      }

      .slick-next:before {
        content: '\f061';
      }

      .slick-prev:before {
        content: '\f060';
      }

      .card {
        border-radius: 11px;
        border: 1px solid #E3E3E3;
        margin: 0;
        min-height: 155px;
        overflow-y: hidden;

        @media only screen and (min-width:320px) and (max-width:640px) {

          max-height: 256px;
        }

        .card-body {
          border-radius: 11px;
          padding: 18px;

          img {
            z-index: 2;
            width: 97px;

            @media (min-width: 1500px) {
              margin: 0 auto;
              width: 100px;
            }
          }

          .img-bg {
            @media (max-width: 768px) {
              margin-bottom: 10px;
            }

            img {
              @media (max-width: 768px) {
                height: 100px;
                object-fit: scale-down;
                width: 100%;
              }
            }

            &::after {
              background-color: #fff;
              border-radius: 10px;
              height: 115px;
              width: 98px;
              content: "";
              position: absolute;
              left: 17px;
              display: block;
              right: 0;
              top: 19px;
              z-index: 1;

              @media (max-width: 768px) {
                display: none;
              }

              @media (min-width: 1500px) {
                max-width: 125px;
                width: 100%;
              }
            }
          }
        }

        .card-title {
          color: #000000;
          font-size: 18px;
          line-height: normal;
          font-weight: 700;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @media (max-width: 991px) {
            font-size: 16px;
          }
        }

        .sub-title {
          color: #000000;
          font-size: 14px;
          line-height: normal;
          font-weight: 400;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @media (max-width: 991px) {
            font-size: 13px;
          }
        }

        .category {
          color: #ffa300;
          font-size: 14px;
          line-height: normal;
          font-weight: 400;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @media (max-width: 991px) {
            font-size: 13px;
          }
        }

        .count {
          color: #184581;
          font-size: 13px;
          line-height: 25px;
          font-weight: 500;
          background-color: #F2F3F5;
          border-radius: 5px;
          padding: 1px 3px;
          height: 28px;

          @media (max-width: 991px) {
            font-size: 12px;
            padding: 1px 3px;
            white-space: nowrap;
          }
        }

        .sch-time {
          color: #BEBEBE;
          font-size: 12px;
          font-weight: 400;

          .bullet {
            top: 6px;
            margin-right: 8px;
            position: relative;
            left: 6px;
          }

          .bg-success {
            background-color: #B7DA1D !important;
          }
        }
      }

      .card:hover {
        background-color: #F5F5F5;

        @media (max-width: 768px) {
          background-color: transparent;
        }
      }
    }
  }
}

.approval-accordion {
  .accordion-item {
    background-color: #ffffff;
    border: 0;
    border-radius: 10px;

    &:first-of-type {
      .accordion-button {
        border-radius: 10px;
      }
    }

    .accordion-header {
      .accordion-button {
        border-radius: 10px;
        font-size: 16px;
        color: #000000;
        background-color: #ffffff;
        border: 0;
        font-weight: 600;
        padding: 23px 20px;

        @media (max-width: 575px) {
          font-size: 14px;
        }

        &::after {
          background-image: url("../../../assets/images/arrow-up.png");
          width: 11px;
          height: 19px;
          background-size: auto;
          transform: none;
          padding: 0 13px;
          background-position: center;

          @media (max-width: 575px) {
            font-size: 14px;
          }
        }

        &:not(.collapsed) {
          font-size: 16px;
          color: #000000;
          background-color: #ffffff;
          font-weight: 600;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
          padding-bottom: 15px;
          border: 0;

          @media (max-width: 575px) {
            font-size: 14px;
          }

          &::after {
            background-image: url("../../../assets/images/arrow-down.png");
            width: 11px;
            height: 19px;
            background-size: auto;
            transform: none;
            padding: 0 13px;
            background-position: center;

            @media (max-width: 575px) {
              font-size: 14px;
            }
          }
        }
      }
    }

    .accordion-collapse {
      .accordion-body {
        font-size: 14px;
        color: #707070;
        line-height: 21px;
        padding: 8px 20px 20px;
      }
    }
  }
}

.new-request-incident {
  .card {
    border-radius: 11px;
    border: 1px solid #E3E3E3;
    box-shadow: 0 0.1rem 1.1rem 0.2rem rgb(0 0 0 / 5%) !important;

    &:hover {
      box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
      background-color: #F5F5F5;
    }

    .card-body {
      .icon-sh {
        box-shadow: 0 0.1rem 1.1rem 0.2rem rgb(0 0 0 / 5%) !important;
        height: 49px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.visitor-avatar {
  img {
    height: 100%;
    min-height: 160px;
    max-height: 160px;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 8px;
  }
}

.total-parking-info {
  .card {
    border-radius: 11px;
    border: 1px solid #E3E3E3;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;

    &:hover {
      box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
      background-color: #F5F5F5;
      cursor: pointer;
    }

    .card-body {
      .icon-sh {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        //background-color: #F5F5F5;
        box-shadow: 0 0.1rem 1.1rem 0.2rem rgb(0 0 0 / 5%) !important;
      }
    }
  }
}

.gate-number {
  color: #A6A6A6;
}

// End Facility Contractor Soft Services Styles
//Start Facility contractor styles
.add-more-btn {
  background-color: #E6E6E6 !important;
  border: 1px solid #E6E6E6 !important;
  font-size: 12px;
  color: #000000;

  &:hover {
    background-color: rgba(230, 230, 230, 0.60) !important;
  }

  i {
    color: #000000 !important;
  }
}

.remove-btn {
  background-color: #F5C4D0 !important;
  font-size: 12px;
  color: #65013D !important;
  border: 1px solid transparent !important;

  &:hover {
    background-color: rgba(245, 196, 208, 0.60) !important;
  }

  i {
    color: #65013D !important;
  }
}

.title-img {
  align-items: center;
  justify-content: start;

  span {
    font-size: 16px;
    color: #1D1D1F;

    @media only screen and (min-width:320px) and (max-width:641px) {
      white-space: nowrap;
    }

    img {
      width: 42px;
      height: 42px;
    }
  }
}

.announcement-sec {
  .bi-plus-circle-fill {
    font-size: 18px;
  }
}

@media only screen and (min-width:320px) and (max-width:641px) {
  table {

    td,
    th {
      white-space: nowrap;
    }
  }
}

.work-orders-sec {
  img {
    width: auto;
    height: 46px;
  }
}

.upload-image {
  position: relative;
  width: auto;
  margin: 10px 20px 0px 0px;
  display: inline-block;
}

.upload-image img {
  height: 85px;
  width: 85px;
  border-radius: 10px;
  object-fit: cover;
}

.close-icon {
  position: absolute;
  top: -7px;
  right: -10px;
  background-color: #ffffff;
  color: #000000;
  font-size: 18px;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 22px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  cursor: pointer;
}

.upcoming-work-order-sec {
  .slick-slider {
    .slick-list {
      padding: 0 60px 10px 0 !important;

      >.slick-track {
        .slick-slide {
          padding: 0 10px;

          &:nth-child(even) {
            >div {
              >div {
                >a {
                  >.card {
                    margin-right: 2px !important;
                  }
                }
              }
            }
          }
        }
      }
    }

    .slick-prev {
      right: 27px;
      left: inherit;
    }

    .slick-next {
      right: 0;
    }

    .slick-prev,
    .slick-next {
      top: -14px;
      width: 23px;
      height: 23px;
    }

    .slick-prev:not(.slick-disabled):before,
    .slick-next:not(.slick-disabled):before {
      opacity: 0.5;
    }

    .slick-prev:before,
    .slick-next:before {
      font-size: 15px;
      color: #000000;
      font-weight: 600;
      font-family: 'Font Awesome 5 Free';
    }

    .slick-next:before {
      content: '\f061';
    }

    .slick-prev:before {
      content: '\f060';
    }

    .card {
      border-radius: 11px;
      border: 1px solid #E3E3E3;
      margin: 0;
      min-height: 140px;
      overflow-y: hidden;

      .card-body {
        border-radius: 11px;
        padding: 20px;

        .img-bg {
          img {
            border-radius: 5px;
            height: 100%;
            min-height: 96px;
            max-height: 96px;
            width: 100%;
            object-fit: cover;
          }
        }
      }

      .card-title {
        color: #000000;
        font-size: 14px;
        line-height: normal;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .sub-title {
        color: #000000;
        font-size: 11px;
        line-height: normal;
        font-weight: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .ref-no {
        color: #ffa300;
        font-size: 12px;
        line-height: normal;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .count {
        color: #184581;
        font-size: 12px;
        line-height: normal;
        font-weight: 500;
        background-color: #F2F3F5;
        border-radius: 5px;
        padding: 3px 16px;
        height: 25px;
      }

      .sch-status {
        color: #BEBEBE;
        font-size: 12px;
        font-weight: 500;
      }
    }

    .card:hover {
      background-color: #F5F5F5;
    }
  }

  &.my-visits-sec {
    .card {
      .card-body {
        //border: 1px solid #DDDDDD;
        //-webkit-border-radius: 0;
        //-moz-border-radius: 0;
        //border-radius: 0;
        overflow: hidden;

        .img-bg{ 
          position: relative;
          overflow: hidden;
          img {
          border-radius: 0px;
          min-height: 168px;
          max-height: 168px;
          object-fit: cover;
          width: 100%;
          max-width: 100%;
        }
        &::after {
          content: "";
          position: absolute;
          left: 0; 
          right: 0;
          top: 0; 
          bottom: 0;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.16) 0%, #000000 131.3%);
        }
      }

        .card-title {
          color:#081F32;
          font-size:20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          font-weight: 500;
        }

        .price-amount {
          color: #1D1D1F;
          margin: 5px 0;
          font-size: 12px;

          strong {
            font-size: 15px;
            line-height: 22px;
          }
        }

        .location-color {
          color: #9D9C9E;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          font-size: 12px;
          font-weight: 400;
        }

        .rounded-pill,
        .rounded {
          background-color: #F6F6F6;
          padding: 8px;

          span {
            color: #656565;
          }

          img {
            margin-right: 6px;
            float: left;
            filter: grayscale(1);
          }
        }

        .visit-completed {
          font-size: 15px;
          color: #000000;

          img {
            margin-right: 6px;
          }
        }

        .view-review {
          background: #EFEFEF;
          color: #000000;
          font-size: 16px;
          display: block;
          border-radius: 6px;
          padding: 11px 16px;
          text-align: center;
          margin-top: 16px;
        }

        .offer-price {
          position: relative;
          bottom: 0;
          left: 0;
          right: 0;
          color: #000000;
          font-size: 14px;
          border-radius: 4px;

          strong {
            font-size: 20px;
          }
        }

        .pb-100 {
          padding-bottom: 98px !important;
        }
      }
    }
  }
}

.calender-card-sec {
  height: auto;
  white-space: nowrap;

  // min-height: 665px;
  // max-height: 665px;
  @media only screen and (min-width:320px) and (max-width:640px) {
    height: auto;
    min-height: 350px;
    white-space: nowrap;
  }

  @media only screen and (min-width:992px) and (max-width:1367px) {
    height: auto;
    min-height: 590px;
    max-height: 590px;
  }

  .calendar-card {
    width: 100%;
    border: none;
    line-height: 20px;

    .react-calendar__navigation {
      button {
        &:enabled {
          &:hover {
            background-color: #EAEFF5;
          }

          &:focus {
            background-color: #EAEFF5;
          }
        }
      }

      .react-calendar__navigation__label {
        .react-calendar__navigation__label__labelText {
          color: #1F2021;
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }

    .react-calendar__viewContainer {
      .react-calendar__month-view {
        .react-calendar__month-view__weekdays {
          background-color: #49484c;
          color: #fff;

          .react-calendar__month-view__weekdays__weekday {
            padding: 10px;

            abbr[title] {
              text-decoration: none;
              font-size: 15px;
              font-weight: 600;
            }
          }
        }

        .react-calendar__month-view__days {
          margin: 0px -5px;

          button {
            background-color: #ffffff;
            border: 1px solid #D6D9DE;
            flex-basis: 12.48% !important;
            max-width: 12.48% !important;
            margin: 5px;
            height: 55px;

            @media only screen and (min-width:320px) and (max-width:641px) {
              height: 46px;
            }

            @media only screen and (min-width:641px) and (max-width:991px) {
              height: 49px;
            }
          }

          .react-calendar__tile {
            text-align: end;
            font-size: 14px;
            font-weight: 500;
            color: #1F2021;

            @media only screen and (min-width:320px) and (max-width:641px) {
              padding: 0px 3px;
            }

            &.react-calendar__tile--active,
            &.react-calendar__tile--now {
              background-color: #49484c;
              font-size: 14px;
              font-weight: 500;
              color: #ffffff;

              &:hover {
                background-color: #49484c;
                font-size: 14px;
                font-weight: 500;
                color: #ffffff;
              }
            }

            &:hover {
              background-color: #EAEFF5;
            }

            abbr {
              line-height: 55px;
            }
          }
        }
      }

      .react-calendar__year-view {
        .react-calendar__tile {
          color: #1F2021;
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;

          &:hover {
            background-color: #EAEFF5;
          }

          &.react-calendar__tile--hasActive {
            background-color: #49484c;
            font-size: 14px;
            font-weight: normal;
            color: #ffffff;

            &:hover {
              background-color: #49484c;
              font-size: 14px;
              font-weight: normal;
              color: #ffffff;
            }
          }
        }

        .react-calendar__tile--now {
          background-color: #49484c;
          font-size: 14px;
          font-weight: normal;
          color: #ffffff;

          &:hover {
            background-color: #49484c;
            font-size: 14px;
            font-weight: normal;
            color: #ffffff;
          }
        }
      }

      .react-calendar__decade-view {
        .react-calendar__decade-view__years {
          .react-calendar__tile {
            &.react-calendar__tile--now {
              background-color: #49484c;
              font-size: 14px;
              font-weight: normal;
              color: #ffffff;

              &:hover {
                background-color: #49484c;
                font-size: 14px;
                font-weight: normal;
                color: #ffffff;
              }
            }
          }
        }
      }

      .react-calendar__century-view {
        .react-calendar__century-view__decades {
          .react-calendar__tile {
            &.react-calendar__tile--now {
              background-color: #49484c;
              font-size: 14px;
              font-weight: normal;
              color: #ffffff;

              &:hover {
                background-color: #49484c;
                font-size: 14px;
                font-weight: normal;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}

.full-Calendar {
  table {
    a {
      color: #000;
    }

    td {
      .fc-daygrid-day-frame {
        .fc-daygrid-day-events {
          .fc-daygrid-day-bottom {
            .fc-daygrid-more-link {
              @media (max-width: 575px) {
                font-size: 5px;
              }
            }
          }
        }
      }
    }

    thead {
      tr {
        th {
          background-color: #49484c;
          white-space: normal;

          a {
            color: #fff;
          }
        }
      }
    }
  }

  .fc .fc-daygrid-day.fc-day-today {
    background-color: #49484c;
    background-color: var(--fc-today-bg-color, #49484c);
    color: #fff !important;

    a {
      color: #fff;
    }
  }

  .fc .fc-col-header-cell-cushion {
    display: inline-block;
    padding: 2px 4px;

    @media (max-width: 575px) {
      font-size: 9px !important;
    }
  }

  .fc .fc-button-primary:not(:disabled):active,
  .fc .fc-button-primary:not(:disabled).fc-button-active {
    color: #000;
    color: var(--fc-button-text-color, #000);
    background-color: #fff;
    background-color: var(--fc-button-active-bg-color, #fff);
    border-color: #fff;
    border-color: var(--fc-button-active-border-color, #fff);
    text-transform: capitalize;
    font-weight: 600;
  }

  .fc-button-active:after {
    content: "";
    position: absolute;
    border: 2px solid #000;
    top: 33px;
    left: 0;
    right: 0;
    width: 20px;
    margin: 0 auto;
    background: #000;
    border-radius: 20px;
  }

  .fc .fc-button-primary:not(:disabled):active:focus,
  .fc .fc-button-primary:not(:disabled).fc-button-active:focus {
    box-shadow: 0 0 0 0 transparent !important;
  }

  .fc .fc-button-primary {
    color: #000;
    color: var(--fc-button-text-color, #000);
    background-color: #0045A6;
    background-color: var(--fc-button-bg-color, #fff);
    border-color: #fff;
    border-color: var(--fc-button-border-color, #fff);
    text-transform: capitalize;
    outline: 0 !important;
    border: 0 !important;
    box-shadow: none !important;
  }

  .fc .fc-daygrid-day-frame {
    border: 1px solid #ddd;
    border: 1px solid var(--fc-border-color, #ddd);
  }

  .fc-theme-standard td,
  .fc-theme-standard th {
    border: 1px solid #fff;
    border: 1px solid var(--fc-border-color, #fff);
  }

  .fc-theme-standard td {
    padding: 3px;
  }

  .fc-theme-standard .fc-scrollgrid {
    border: 1px solid #fff;
    border: 1px solid var(--fc-border-color, #fff);
  }

  @media only screen and (min-width:320px) and (max-width:640px) {
    .fc .fc-toolbar {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .fc-h-event,
  .fc-v-event {
    border: 1px solid #49484c !important;
    background-color: #49484c !important;

    .fc-event-title.fc-sticky {
      color: #ffffff !important;
    }
  }

  .fc-event {
    .fc-daygrid-event-dot {
      border: 4px solid #49484c !important;
    }

    .fc-event-time,
    .fc-event-title {
      color: #49484c !important;
    }
  }
}

.calendar_div_2 {
  display: flex;
  align-items: center;

  .corrective {
    display: flex;
    align-items: center;

    .box {
      width: 10px;
      height: 10px;
      border-radius: 50px;
    }

    .red {
      background-color: #F16D6D;
    }

    .blue {
      background-color: #3983BC;
    }

    .green {
      background-color: #73BF46;
    }

    .purple {
      background-color: #B739BC;
    }

    .sky-blue {
      background-color: #92E4EE;
    }

    .orange {
      background-color: #FFB013;
    }
  }

  @media only screen and (min-width:320px) and (max-width:640px) {
    display: flex;
    flex-wrap: wrap;
  }
}

.calendar-details-sec {
  .event-request {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin: 0;

    .event-sec {
      flex: 1 2;
      margin: 10px;
      background-color: #F5F5F5;
      text-align: center;
      flex-grow: 1;
      height: 120px;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      border-radius: 10px;
      padding: 20px 10px 10px;

      @media only screen and (min-width:320px) and (max-width:641px) {
        height: 95px;
        margin: 4px;
        padding: 10px 10px 10px;
        justify-content: center;
      }

      @media (min-width: 1500px) {
        flex: 25%;
      }

      h4 {
        font-size: 34px;
        font-weight: normal;
        color: #000000;

        @media (max-width:575px) {
          font-size: 20px;
        }
      }

      p {
        margin-bottom: 0;
        color: #000000;
        font-weight: normal;
        font-size: 14px;

        @media (max-width:575px) {
          font-size: 12px;
        }
      }
    }
  }

  .request-date {
    display: flex;
    padding: 10px;

    h4 {
      color: #252427;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .invoice-card {
    height: 100%;
    min-height: 455px;
    max-height: 455px;

    @media only screen and (min-width:991px) and (max-width:1367px) {
      min-height: 380px;
      max-height: 380px;
    }

    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 4px;
      margin: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #FAFAFA;
      border-radius: 10px;
      margin: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #FAFAFA;
      border-radius: 10px;
      margin: 10px;
    }

    &:hover::-webkit-scrollbar-thumb {
      background-color: #49484c !important;
    }

    &.finance-invoice-card {
      min-height: 322px;
      max-height: 322px;

      @media only screen and (min-width:991px) and (max-width:1367px) {
        min-height: 240px;
        max-height: 240px;
      }
    }

    .invoice-box {
      border: 1px solid #CBD6E2;
      margin: 10px;
      padding: 15px;
      border-radius: 10px;

      .invoice-info {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        .in-progress {
          background-color: #E7F1FE;
          border: 1px solid #7AB2FB;
          border-radius: 50px;
          padding: 8px;
          text-align: center;
          color: #707070;
        }
      }

      .inv-tkt {
        background-color: #EBF9FF;
        align-items: center;
        justify-content: center;
        display: flex;
        height: 28px;
        padding: 8px 24px;
        font-size: 12px;
        color: #707070;
        border-radius: 5px;

        @media only screen and (min-width:320px) and (max-width:641px) {
          height: 46px;
          padding: 4px 12px;
        }
      }

      .inv-num p {
        margin-bottom: 0;
        font-size: 12px;
        color: #c4c4c4;
        font-weight: normal;
      }

      h4 {
        font-size: 18px;
        font-weight: 500;
        color: #000000;
      }

      .t-name {
        color: #707070;
        font-size: 12px;
        margin-bottom: 0px;

        span {
          font-weight: bold;
          color: #000000;
        }
      }

      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
}

.work-history-card {
  display: flex;
  align-items: center;
  background-color: #F6F6F6;
  padding: 5px 10px;
  border-radius: 6px;
  flex-wrap: wrap;

  div {
    flex: auto;
  }
}

.on-hold-btn {
  &:hover {
    background-color: #EBF9FF !important;
    box-shadow: 0px 3px 6px #EBF9FF;
  }
}

.onHoldPopUp {
  .modal-dialog {
    max-width: 430px;
    border-radius: 10px;
  }
}

.checklist-box {
  background-color: #F5F5F5;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 15px;

  &.active {
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }

  &:hover {
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }

  .question-checklist {
    color: rgba(0, 0, 0, 0.24);
    font-size: 14px;
    margin-bottom: 10px;

    @media (max-width: 575px) {
      font-size: 13px;
    }
  }

  .checklist-action-items {
    span {
      display: inline-flex;
      vertical-align: middle;

      img {
        width: 13px;
        height: 13px;
      }
    }
  }

  .btn-group {
    display: flex;
    margin-bottom: 10px;


    label.btn {
      flex: 1;
      margin-right: 10px;
      border-radius: 5px;

      &.btn-pass {
        background-color: #ffffff;
        font-size: 15px;
        color: #12A03B;
        border: 1px solid #12A03B;
        border-radius: 6px;

        @media (max-width: 575px) {
          font-size: 13px;
        }

        &:hover {
          background-color: #95E4AC !important;
          color: #000000 !important;
          border: 1px solid transparent !important;

          @media (max-width: 575px) {
            font-size: 13px;
          }
        }
      }

      &.btn-fail {
        background-color: #ffffff;
        font-size: 15px;
        color: #65013D;
        border: 1px solid #65013D;
        border-radius: 6px;

        @media (max-width: 575px) {
          font-size: 13px;
        }

        &:hover {
          background-color: #F5C4D0 !important;
          color: #65013D !important;
          border: 1px solid transparent !important;

          @media (max-width: 575px) {
            font-size: 13px;
          }
        }
      }

      &.btn-na {
        background-color: #ffffff;
        font-size: 15px;
        color: #C8C8C8;
        border: 1px solid #C8C8C8;
        border-radius: 6px;

        @media (max-width: 575px) {
          font-size: 13px;
        }

        &:hover {
          background-color: #c7c7c7 !important;
          color: #333 !important;
          border: 1px solid transparent !important;

          @media (max-width: 575px) {
            font-size: 13px;
          }
        }
      }
    }

    &:first-child {
      label.btn {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

  }

  .btn-check:checked+.btn-outline-primary {
    &.btn-pass {
      background-color: #95E4AC;
      font-size: 15px;
      color: #000000;
      border: 1px solid transparent !important;

      @media (max-width: 575px) {
        font-size: 13px;
      }
    }
  }

  .btn-check:checked+.btn-outline-primary {
    &.btn-fail {
      background-color: #F5C4D0;
      font-size: 15px;
      color: #65013D;
      border: 1px solid transparent !important;

      @media (max-width: 575px) {
        font-size: 13px;
      }
    }
  }

  .btn-check:checked+.btn-outline-primary {
    &.btn-na {
      background-color: #c7c7c7 !important;
      font-size: 15px;
      color: #333 !important;
      border: 1px solid transparent !important;

      @media (max-width: 575px) {
        font-size: 13px;
      }
    }
  }
}

.moveout-inspection {
  .moveout-inspection-main-div {
    .divimg {
      overflow: hidden;

      img {
        width: 120px;
        height: 70px;
        object-fit: cover;
        border-radius: 6px;
      }
    }

    .moveout-inspection-div {
      width: 25%;

      p {
        color: #707070;
      }
    }

    @media only screen and (min-width:320px) and (max-width:991px) {
      display: block !important;

      .moveout-inspection-div {
        width: 100%;
      }
    }
  }
}

.bedroom {
  position: absolute;
  margin-top: 42px;
  margin-left: -101px;
}

.select {
  text-indent: 45px;
}

.btn-gray-btn {
  background: #E6E6E6;
  color: #8C8C8C;
}

//End Facility contractor styles

// Facility Contractor Supervisor - Dashboard ////////////

.fc_s_upcomingblock {
  color: #ffa300 !important;
}

.time {
  font-size: 10px !important;
  color: #BEBEBE !important;
}

.assign {
  color: #ffa300 !important;
}


#upload_img_video {
  text-align: left;
  font-size: 14px !important;
  padding-left: 0;
  font-weight: 500 !important;
  border: none !important;
  background: transparent !important;
}

.corrective_table {
  background-color: #F6F6F6 !important;
  border-radius: 6px;
}

//.table-striped{
//  thead{
//    background-color: #F6F6F6 !important;
//    border-radius: 6px;
//  }
//}
//.table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
//  background-color: #F6F6F6 !important;
//}
//.table-striped>tr:nth-child(odd){
//  background-color:red;
//}
.fc-wo-checkbox {
  width: 20px;
  height: 20px;
  border-radius: 0 !important;
  border-color: #707070 !important;
  background-size: 100% 60% !important;
  margin-top: 0 !important;
}

.fc-su-ser-tabel {
  table thead {
    background-color: #F6F6F6;
  }

  table tbody tr:nth-of-type(odd) {
    background-color: #ffffff;
    --bs-table-accent-bg: #fff;
    color: #1D1D1F;
  }

  table tbody tr:nth-of-type(even) {
    background-color: #F9F9F9;
  }
}

.fc-wo-checkbox:checked {
  background-color: #49484c;
}

.corrective_table {
  td {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    vertical-align: middle;
  }
}

.corrective_table_popup_bt {
  background: #49484c !important;
  color: #fff !important;
  border: none !important;
  border-radius: 5px !important;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.pop_model_c {
  width: 100%;
  display: block;
}

@media only screen and (min-width:320px) and (max-width:640px) {
  .fc-su-anu {
    overflow-x: scroll !important;
  }
}

.fc-super-calender-date {
  padding: 0 !important;

  .card {
    margin-top: 0 !important;
  }
}

.calender-overview {
  border-radius: 11px;
  border: 1px solid #E3E3E3;

  h4 {
    font-size: 24px;
    font-weight: bold;
  }
}

.cal-month-slectbox {
  background-color: #fff !important;
  border-color: #fff !important;
}

.horizontal_calc {
  padding: 0 !important;

  .horizontal_date {
    h1 {
      margin-bottom: 0 !important;
    }

    .btn-check:checked+.btn-outline-primary {
      background-color: #49484c !important;

      p {
        color: #fff;
      }

      h1 {
        color: #fff;
      }
    }
  }
}

#filter_btns {
  &:hover {
    background-color: #49484c;

    h1 {
      color: #fff;
    }
  }
}

.calender_card {
  .calender_card_right_div1 {
    h5 {
      color: #49484c;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    p {
      color: #C4C4C4;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .calender_card_right_div2 {
    .text-color {
      color: #49484c;
    }

    .text-red {
      color: #5E0740;
    }

    .in-progress {
      background-color: #E7F1FE;
      border: 1px solid #7AB2FB;
      border-radius: 50px;
      padding: 8px;
      text-align: center;
      color: #707070;
    }
  }

  .calender_card_right_div3 {
    .calender-section {
      align-items: center;
      display: flex;

      .calender_card_right_div3_img {
        img {
          min-height: 50px;
          max-height: 50px;
          object-fit: cover;
          width: 50px;
          max-width: 50px;
          border-radius: 9px !important;
        }
      }

      .calender_card_right_div3_text {
        h5 {
          color: #036978;
        }

        p {
          color: #C4C4C4;
        }
      }
    }
  }

  .icons_msg {
    display: flex;

    .icon_1 {
      width: 45px;
      height: 45px;
      background: #D7DFFF;
      border-radius: 50px;
      text-align: center;
      padding: 14px 4px 8px;
      color: #817889;
    }

    .icon_2 {
      background-color: #F8CECE;
    }
  }

}

// END Facility Contractor Supervisor - Dashboard ////////////

// Facility Contractor Supervisor Services ////////////
.addbt {
  background: #49484c;
  padding: 10px;
  text-align: center;
  border-radius: 5px;

  a {
    color: #fff !important;
    font-weight: 400 !important;
  }
}

.team-activity-visit-calendar {
  .react-calendar {
    width: 100%;
    margin-bottom: 30px;
    border: none;

    .react-calendar__navigation {
      background-color: #D2DEEF;
      margin-bottom: 0;

      .react-calendar__navigation__prev2-button,
      .react-calendar__navigation__next2-button {
        display: none;
      }

      .react-calendar__navigation__label {
        .react-calendar__navigation__label__labelText {
          font-size: 19px;
          color: #000000;
          font-weight: 500;
        }
      }

      button {
        &:enabled {
          &:hover {
            background-color: #D2DEEF;
          }
        }

        &:enabled {
          &:focus {
            background-color: #D2DEEF;
          }
        }
      }
    }

    .react-calendar__viewContainer {
      background-color: #F5F5F5;

      .react-calendar__month-view {
        .react-calendar__month-view__weekdays {
          padding: 10px 0px;

          .react-calendar__month-view__weekdays__weekday {
            cursor: default;

            abbr[title],
            abbr[data-original-title] {
              cursor: default;
              text-decoration: none;
              font-weight: normal;
              color: rgba(62, 62, 62, 0.50);
            }
          }
        }

        .react-calendar__month-view__days {
          .react-calendar__tile--now {
            background: rgba(7, 71, 166, 0.18);
          }

          .react-calendar__tile {
            color: #000;
            font-size: 12px;
            width: 48px;
            height: 50px;

            &:enabled {
              &:hover {
                background: #d2deef;
              }
            }

            &:enabled {
              &:focus {
                background: #d2deef;
              }
            }
          }
        }
      }

      .react-calendar__year-view {
        .react-calendar__year-view__months {
          .react-calendar__tile {
            color: #000;
            font-size: 12px;

            &:enabled {
              &:hover {
                background: #d2deef;
              }
            }

            &:enabled {
              &:focus {
                background: #d2deef;
              }
            }
          }

          .react-calendar__tile--now {
            background: rgba(7, 71, 166, 0.18);
          }
        }
      }

      .react-calendar__decade-view {
        .react-calendar__decade-view__years {
          .react-calendar__tile {
            color: #000;
            font-size: 12px;

            &:enabled {
              &:hover {
                background: #d2deef;
              }
            }

            &:enabled {
              &:focus {
                background: #d2deef;
              }
            }
          }

          .react-calendar__tile--now {
            background: rgba(7, 71, 166, 0.18);
          }
        }
      }

      .react-calendar__century-view {
        .react-calendar__century-view__decades {
          .react-calendar__tile {
            color: #000;
            font-size: 12px;

            &:enabled {
              &:hover {
                background: #d2deef;
              }
            }

            &:enabled {
              &:focus {
                background: #d2deef;
              }
            }
          }

          .react-calendar__tile--now {
            background: rgba(7, 71, 166, 0.18);
          }
        }
      }
    }
  }
}

.reschedule_time {
  .slick-slide {
    width: 80px !important;
  }

  .SliderWrapper {
    margin: 0px -30px;

    .slick-initialized .slick-slide {
      margin: 0px 5px;
    }

    .slick-prev {
      left: auto;
      right: 56px;
      top: -28px;

      &:hover {
        &:before {
          color: #49484c;
        }
      }

      &:before {
        opacity: 1;
        color: #e4e4e4;
      }
    }

    .slick-next {
      right: 25px;
      top: -28px;

      &:hover {
        &:before {
          color: #49484c;
        }
      }

      &:before {
        opacity: 1;
        color: #e4e4e4;
      }
    }

    .slick-track {
      width: max-content !important;
    }

    .slick-current .time-slot-item.disabled {
      background-color: #ccc;
    }

    .slick-current.time-slot-item {
      background-color: #49484c;
      color: #fff;
    }

    .slick-current.time-slot-item h3 {
      color: #ffffff;
    }

    .time-slot-item {
      background-color: #f5f5f5;
      border-radius: 5px;
      cursor: pointer;

      h3 {
        margin-bottom: 0;
        text-align: center;
        font-size: 13px;
        padding: 10px;
        color: #3d3d3d;
      }

      &.disabled {
        h3 {
          font-size: 12px;
          color: rgba(61, 61, 61, 0.3);
        }
      }
    }
  }
}

// END Facility Contractor Supervisor Services ////////////

// Start F C Supervisor MyTeam ////////////
.view-all-2 {
  text-align: right;
  color: #000;
  opacity: 50%;
  font-size: 12px;
}

.myteam_cards {
  height: 390px;
  overflow: hidden;

  @media only screen and (min-width:320px) and (max-width:641px) {
    height: 250px;

    .inner-text {
      h4 {
        font-weight: 400 !important;
        margin-left: -32px;
      }
    }
  }

  @media only screen and (min-width:641px) and (max-width:1367px) {
    .Doughnut-Chart .inner-text {
      left: 7px;
    }
  }

  @media only screen and (min-width:1368px) and (max-width:1920px) {
    .Doughnut-Chart .inner-text {
      left: -12px;
    }
  }
}

@media only screen and (min-width:320px) and (max-width:640px) {
  .myteamtital {
    margin-top: -33px !important;
  }
}

.technicians_card,
.myteamgraph_card {
  border-radius: 0.475rem !important;
}

@media only screen and (min-width:320px) and (max-width:641px) {
  .GenerateReport {
    margin-top: 15px;
  }
}

.fc-su-calender-inprogress {
  color: #10AE2E !important;
  font-weight: 500;
}

.su-calender_details_card {
  p {
    //font-size: 12px;
    margin-bottom: 5px !important;
  }

  .calender_details_card_name {
    color: #707070;
  }

  .calender_details_card_name2 {
    color: #000;
    font-weight: 500;
  }
}

.scheduledVisitDetailss {
  margin-top: -28px;
}

.calender-add-attendance {
  margin-top: -30px;

  .announcement-sec {
    h4 {
      margin-bottom: 0 !important;
    }
  }
}

.su-ca-add-attence {
  margin-bottom: 0 !important;
}

.work-order-input {
  color: #6E6E6E;
}

::placeholder {
  color: #6E6E6E !important;
  font-size: 14px;
}

// END F C Supervisor MyTeam ////////////

// Start Finance Team Styles
.serv-data {
  .ticket-no-bg {
    border-radius: 5px;
    background-color: #EBF9FF;
    padding: 5px 12px;
    font-size: 14px;
    font-weight: 400;
    color: #707070;
    display: inline-block;
  }

  .lease-expire-bg {
    border-radius: 5px;
    background-color: #FAE7CC;
    padding: 5px 8px;
    font-size: 14px;
    font-weight: 400;
    color: #FA9917;
    display: inline-block;

    i {
      color: #FA9917;
    }
  }
}

.term-charges {
  .bg-light-gray {
    background-color: #FAFAFA;
  }
}

.data-contain {
  background-color: transparent;
  height: auto;
  padding: 0 !important;

  h6 {
    color: #000;
    line-height: normal;
  }
}

.pay-alert-box {
  background-color: #FCFCFC;
  border: 1px solid #EAEAEA;

  .bg-gray {
    background-color: transparent;
    border-radius: 4px;

    img {
      max-height: 36px;
      min-height: 36px;
      height: 100%;
      max-width: 69px;
      object-fit: cover;
    }
  }
}

.bg-dark-grey {
  background-color: #F2F2F2;
}

.border-img {
  border: 1px solid #CBD6E2;
}

.bg-generate {
  background-color: #F5F5F5;
}

.payment-box {
  background-color: #FFFFFF;
  border: 1px solid #E3E2E2;
  padding: 15px 20px;
  border-radius: 9px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
}

.payment-progress {
  .step {
    position: relative;
    min-height: 1em;
    color: gray;

    .pay-status {
      line-height: 1.5em;
      font-weight: 400;
      color: #000000;
      font-size: 13px;
    }

    .time-info {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.4);
      font-size: 11px;
    }

    >div:first-child {
      position: static;
      height: 0;
    }

    >div:not(:first-child) {
      margin-left: 1.5em;
      padding-left: 1em;
    }

    .circle {
      i {
        line-height: 1.5em;
        position: relative;
        font-size: 18px;
      }

      &.success {
        i {
          color: #15A329;
        }
      }

      &.failed {
        i {
          color: #f1416c;
        }
      }

      &:after {
        content: ' ';
        position: absolute;
        display: block;
        top: 21px;
        right: 50%;
        bottom: 0;
        left: 9px;
        height: 38px;
        width: 1px;
        /* transform: scale(1, 2); */
        transform-origin: 50% -100%;
        background-color: rgba(0, 0, 0, 0.25);
        z-index: 1;
      }
    }

    &:last-child .circle:after {
      display: none
    }
  }

  .step+.step {
    margin-top: 1.5em
  }
}

.payment-st-body {
  >.col-12:last-child {
    margin-bottom: 0 !important;
  }
}

// End Finance Team Styles

// Strat Profile Style ////

.backtodashboard {
  color: #49484c;
  text-align: center;
}

.signout-link {
  color: #9C9FA6;
  font-weight: 600;
  font-size: 14px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 25px !important;

  &:hover {
    color: #49484c !important;
    font-weight: 600;
    font-size: 14px;
  }
}

.profile_wrapper {
  display: flex;
  align-items: center;

  .profile_imp {
    img {
      width: 100px;
      height: 100px;
      background: silver;
      border-radius: 50px;
      overflow: hidden;
      margin-right: 15px;
    }
  }

  .profile_div_headdeing {
    h2 {
      font-size: 35px;
      font-weight: 700;
      color: #49484c;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    p {
      color: #929292;
      font-size: 14px;
      margin-bottom: 0 !important;
    }
  }

  @media only screen and (min-width:320px) and (max-width:641px) {
    .profile_imp {
      width: 80px;
      height: 80px;
    }

    .profile_div_headdeing {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.personalinfor_form {
  label {
    color: #707070;
    font-size: 16px;
    font-weight: 400 !important;
  }

  .form-control {
    background-color: #F8F8F8 !important;
  }

  .Notyetverified {
    background: transparent;
    border: none;
    font-size: 14px;
    color: #49484c;
    font-weight: 400;
    float: right;
    margin-top: -35px;
    margin-right: 16px;
  }
}

.notification-my-profile {
  background-color: #fff !important;
}

.notification_main_card {
  height: 100%;
  min-height: 530px;
  max-height: 530px;
  overflow-y: auto;
  overflow-x: hidden;

  .notification_inner_div {
    display: flex;

    .notification_Star {
      width: 60px;
      background-color: #FFF6E5;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .notification_details {
      width: calc(100% - 70px);
      margin-left: 10px;
    }

    @media only screen and (min-width:320px) and (max-width:641px) {
      .notification_Star {
        width: 11%;
        padding-right: 10px;
      }

      .notification_details {
        width: 89%;
      }

      button {
        width: 100%;
        padding: 10px !important;
      }

      .btn-outline-danger {
        margin-top: 10px;
      }
    }
  }
}

@media only screen and (min-width:320px) and (max-width:641px) {
  .nav-line-tabs .nav-item .nav-link {
    width: max-content;
  }
}

.download-agrement {
  color: #49484c;
}

// End Profile Style ////

.MuiDataGrid-panel {
  .MuiDataGrid-panelWrapper {
    .MuiSwitch-switchBase.Mui-checked {
      color: #49484c !important;
    }

    .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
      background-color: rgba(0, 44, 106, 1) !important;
    }
  }
}

.MuiButton-textPrimary,
.MuiInputLabel-root.Mui-focused {
  color: #49484c !important;
}

//parking styles
.avail-parking {
  position: relative;
  color: #323232;
  font-size: 14px;
  font-weight: normal;

  &::before {
    content: "";
    position: absolute;
    background-color: #C8C8C8;
    width: 11px;
    height: 11px;
    border-radius: 10px;
    left: -20px;
    top: 5px;
  }
}

.booked-parking {
  position: relative;
  color: #323232;
  font-size: 14px;
  font-weight: normal;

  &::before {
    content: "";
    position: absolute;
    background-color: #49484c;
    width: 11px;
    height: 11px;
    border-radius: 10px;
    left: -20px;
    top: 5px;
  }
}

.parking-select {
  &:focus {
    outline: none;
  }
}

.signature-modal {
  .modal-dialog {
    max-width: 480px;
    width: 100%;
  }

  .modal-header {
    background-color: #ffffff;

    .btn-close {
      &:focus {
        outline: 0 !important;
        box-shadow: none !important;
      }
    }
  }

  .modal-body {
    background-color: #F4F7FB;

    .sigCanvas {
      background-color: #FFFFFF;
      border: 1px solid #C6C6C6;
      border-radius: 9px;
      height: 180px;
      width: 100%;
      margin: auto;
    }
  }

  .modal-footer {
    border-top: 0;
    background-color: #F4F7FB;
  }
}

.parking-success-modal {
  .modal-dialog {
    max-width: 430px;
    width: 100%;
  }

  .modal-body {
    img {
      width: 100px;
      height: 100px;
      margin-bottom: 15px;
    }
  }
}

/////////////////// Start Market Team Admin /////////////////////
.marketPlace {
  min-height: 285px;

  @media only screen and (min-width:991px) and (max-width:1200px) {
    min-height: 305px
  }

  @media only screen and (min-width:320px) and (max-width:575px) {
    min-height: 483px
  }

}

.marketPlace1 {
  height: 285px;

  @media only screen and (min-width:991px) and (max-width:1200px) {
    height: 305px
  }

  @media only screen and (min-width:320px) and (max-width:575px) {
    height: 290px
  }

}

.min-height-card {
  height: auto;
}

.min-height-card1 {
  height: 226px;
}

.market-place-tab {
  float: right;

  a {
    margin-right: 10px !important;
  }

  .nav-line-tabs .nav-item .nav-link.text-active-primary.active {
    color: #000 !important;
  }

  .nav-line-tabs .nav-item .nav-link.text-active-primary.active::after {
    border: none !important;
  }

  .nav-link {
    font-size: 12.5px !important;
  }
}

.market-place-card {
  h6 {
    font-size: 22px !important;

    @media only screen and (max-width:1199px) {
      font-size: 18px !important;
    }
  }

  p {
    color: #b2b2b2;
    font-size: 12px;

    @media only screen and (min-width:768px) and (max-width:1199px) {
      font-size: 9px !important;
    }

    @media only screen and (max-width:768px) {
      font-size: 11px !important;
    }
  }

  .card {
    background-color: #f7f7f7;
  }

  .cardimg {
    background-color: #fff;
    border: 1px solid #ebebeb;
    text-align: center;
    padding: 10px;
    border-radius: 4px;
  }
}

.market-team-myteam-card {
  .card {
    background-color: #f7f7f7;

    .cardimg {
      img {
        object-fit: cover;
        width: auto;
        border-radius: 9px !important;
      }
    }

    @media only screen and (min-width:1199px) and (max-width:1367px) {
      p {
        font-size: 11px !important;
      }
    }

    @media only screen and (min-width:768px) and (max-width:1200px) {
      p {
        font-size: 9px !important;
      }
    }

    @media only screen and (max-width:767px) {
      p {
        font-size: 11px !important;
      }
    }

    @media only screen and (min-width:768px) and (max-width:1200px) {
      .heading-card {
        font-size: 9px !important;
      }
    }

    @media only screen and (max-width:767px) {
      .heading-card {
        font-size: 11px !important;
      }
    }
  }
}

.sub-heading {
  @media only screen and (min-width:768px) and (max-width:1200px) {

    font-size: 11px !important;

  }

  @media only screen and (max-width:767px) {

    font-size: 11px !important;

  }
}

.campaign-analytics-tital {
  color: #575050;
  font-size: 16px;
}

.aed {
  font-size: 8px !important;
  color: #8C8C8C !important;
}

.NewCampaignTab {
  .responsive-tabs {
    padding: 1rem;
  }

  .responsive-tabs .nav-tabs {
    display: none;
  }

  @media (min-width: 768px) {
    .responsive-tabs .nav-tabs {
      display: flex;
      background-color: #fff;
      border-radius: 10px;
      margin-bottom: 10px;
    }

    .responsive-tabs .card {
      border: none;
    }

    .responsive-tabs .card .card-header {
      display: none;
    }

    .responsive-tabs .card .collapse {
      display: block;
    }
  }

  @media (max-width: 767px) {
    .responsive-tabs .tab-pane {
      display: block !important;
      opacity: 1;
    }
  }
}

.new-campaign-view-all {
  .view-all {
    color: #17437F;
    font-size: 16px;
    font-weight: 500;

    @media (max-width: 575px) {
      font-size: 14px;
    }
  }
}

.NewCampaignTab {

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #000;
    font-weight: 700;
    background-color: #ffffff;
    border-color: transparent;
    border-bottom-width: 0;
    font-size: 16px;
  }

  .nav-tabs .nav-link.active::after {
    content: "";
    position: relative;
    border: 2px solid #04377F;
    width: 23px;
    background: #04377F;
    border-radius: 20px;
    margin: 0 auto !important;
    display: block;
    top: 12px;
  }

  .nav-link {
    font-weight: normal;
    font-size: 15px;
    color: #8B8B8B;
    border-bottom-width: 0 !important;
    padding: 0.9rem 0;
    margin: 0 1rem;
  }

  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link:focus {
    border-color: #fff;
    color: #000;
    isolation: isolate;
  }
}

/////////////////// End Market Team Admin /////////////////////

/////////////////// Start Market Team Properties /////////////////////

.properties_card_view {
  .card {
    background-color: #F5F5F5;
    border-radius: 10px;
    overflow: hidden;

    .property_card_img {
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;

        @media screen and (max-width:991px) {
          height: auto;
          object-fit: cover;
        }
      }
    }

    .property_card_headding {
      margin-top: -45px;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));

      @media screen and (max-width:768px) {
        margin-top: -42px;
      }
    }

    .property_card_Details {
      h4 {
        span {
          font-size: 22px;

          @media screen and (max-width:576px) {
            font-size: 17px;
          }
        }
      }

      .offer_main_details_div_2 {
        display: flex;
        border-bottom: 2px solid #E6E6E6;
        padding-bottom: 8px;

        .sqft {
          width: 40%;
        }

        p {
          margin-bottom: 0 !important;
          width: 20%;
          font-size: 15px;
          color: #000;

          @media screen and (max-width:1200px) {
            font-size: 12px;
          }

          img {
            padding-right: 7px;
            width: 25px;
            height: 25px;
          }
        }
      }

      .offer_main_details_div_3 {
        p {
          color: #000;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          img {
            padding-right: 7px;
          }
        }
      }
    }
  }
}

.properties_list_view {
  .card {
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    overflow: hidden;

    .offer_main_img {
      overflow: hidden;
      border-radius: 10px;

      @media screen and (max-width:768px) {
        margin-bottom: 8px;
      }

      img {
        width: 100%;
        height: 120px;
        object-fit: cover;
        border-radius: 10px;
      }
    }

    .offer_main_img2 {
      overflow: hidden;
      border-radius: 10px;

      img {
        width: 100%;
        height: 84px;
        object-fit: cover;
        border-radius: 10px;
      }
    }

    .property_card_headding {
      margin-top: -45px;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
      position: relative;
      height: 35px;

      p {
        font-size: 10px;
      }
    }

    .offer_main_details {
      .offer_main_details_div_2 {
        display: flex;
        white-space: nowrap;

        .sqft {
          width: 83px;
        }

        p {
          margin-bottom: 0 !important;
          width: auto;
          font-size: 15px;
          color: #000;

          @media only screen and (max-width:1200px) {
            font-size: 12px;
          }

          img {
            padding-block: 1.5px;
            width: 23px;
            height: 16px;
          }
        }

        @media only screen and (min-width:991px) and (max-width:1367px) {
          .sqft {
            width: 85px;
          }

          p {
            width: auto;
            font-size: 12px;
            margin-right: 15px;
          }
        }
      }

      .offer_main_details_div_3 {
        p {
          color: #A3A8A8;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          img {
            padding-right: 7px;
          }
        }

        @media only screen and (min-width:991px) and (max-width:1367px) {
          h4 {
            margin-right: 3px !important;
            font-size: 13px;
          }
        }
      }
    }
  }
}

.properties_buttons {
  display: flex;
  flex-wrap: wrap;

  .card_list_view {
    .card_view {
      width: 60px;
      height: 44px;
      border-top-left-radius: 5px;
      border-top-right-radius: 0;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 0;
      background-position: center;
      border: none !important;
      background-color: #fff;

      @media screen and (max-width:640px) {
        width: 48px !important;
      }


      i {
        font-size: 28px;

        @media screen and (max-width:640px) {
          font-size: 20px !important;
        }

      }

      &:hover {
        background-color: #49484c;
        color: #fff;

        i {
          color: #fff !important;
        }
      }
    }

    .list_view {
      width: 60px;
      height: 44px;
      border-top-left-radius: 0;
      border-top-right-radius: 5px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 5px;
      background-position: center;
      border: none !important;
      background-color: #fff;

      @media screen and (max-width:640px) {
        width: 48px !important;
      }

      i {
        font-size: 28px;

        @media screen and (max-width:640px) {
          font-size: 20px !important;
        }
      }

      &:hover {
        background-color: #49484c;

        i {
          color: #fff !important;
        }
      }
    }

    .active {
      background-color: #49484c;

      i {
        color: #fff !important;
      }
    }
  }
}

.prop_details_headding {
  p {
    color: #002A66;
  }
}

.prop_details_facilities {
  background-color: #f5f5f5;
  padding: 10px;
  margin: 20px 0px 30px;
  border-radius: 5px;
  width: 61%;

  @media screen and (max-width:768px) {
    width: 100%;
  }

  .offer_main_details_div_2 {
    display: flex;

    p {
      margin-bottom: 0 !important;
      width: 20%;
      font-size: 15px;
      color: #002A66;

      @media screen and (max-width:1200px) {
        font-size: 12px;
      }

      img {
        padding-right: 7px;
        width: 23px;
        height: 16px;
      }
    }

    .sqft {
      width: 40%;
    }
  }
}

.OfferAnnounce {
  background-color: #f5f5f5;
  padding: 10px;
  margin: 20px 0px 30px;
  border-radius: 5px;

  p {
    span {
      font-size: 29px;

      @media screen and (max-width:1200px) {
        font-size: 17px;
      }
    }
  }

  .merchant-name {
    a {
      color: #fff;
      font-size: 1rem !important;
    }
  }

  button {
    cursor: no-drop;
  }
}

.property-details-edit-upload {
  background-color: #F7F7F7;
  border-radius: 7px;
  padding: 20px;

  .property-details-edit-upload-input {
    display: flex;
  }
}

.property-details-edit-details {
  .form-control {
    color: #000000;
    background-color: inherit;
    border-color: #DDDDDD;
    font-weight: 500 !important;
    font-size: 13px;
  }

  label {
    color: #000;
    font-size: 14px;
    font-weight: 500 !important;
  }

  #update_bt {
    border-color: #fff !important;
    border: 0 solid #fff !important;
    background: transparent;
  }
}

.button-sections {
  p {
    color: #49484c;
  }
}

.galery_img_main {
  img {
    width: 100%;
    height: 450px;
    border-radius: 10px;
    object-fit: cover;
  }
}

.galery_img {
  height: 450px;
  overflow-y: scroll;

  img {
    width: 100%;
    height: 150px;
    border-radius: 10px;
    object-fit: cover;
  }
}

.galery_img1 {

  overflow-y: scroll;
}

.pop-up-text {
  @media screen and (max-width:576px) {
    font-size: 16px;
  }
}

.select-property-type {
  ul {
    margin: 0;

    li {
      list-style-type: none;
      float: left;
      text-align: center;

      a {
        span {
          border-radius: 10px;
          border: 1px solid #dadada;
          padding: 20px;
          width: 173px;
          height: 120px;
          clear: both;
          display: block;
          margin-bottom: 21px;

          &:hover {
            background-color: #F2F2F2;
          }

          @media screen and (max-width:576px) {
            border-radius: 10px;
            border: 1px solid #dadada;
            padding: 0px;
            width: 119px;
            height: 77px;
            clear: both;
            display: block;
            margin-bottom: 9px;
          }
        }

        color: #2F3031;
        font-size: 16px;
        text-align: center;
        display: block;

        @media screen and (max-width:576px) {
          font-size: 13px;
        }
      }
    }
  }
}

#property_available {
  .form-check-input:checked {
    background-color: #103B78;
    border-color: transparent;
  }
}

.expected-rent-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.expected-rent-selectbox {
  border-color: #DDDDDD;
  border-top-right-radius: 0.475rem;
  border-bottom-right-radius: 0.475rem;
}

.movein-property-image {
  .upload-image img {
    height: 110px;
    width: 110px;
    border-radius: 10px;
    object-fit: cover;
  }

  .prop_img {
    display: flex;
  }
}

// .mta-chart{
//   .inner-text{
//     transform: translate(-20%, -50%) !important;
//     @media only screen and (min-width:320px) and (max-width:991px){
//       transform: translate(-18%, -50%) !important;
//     }
//   }
// }
/////////////////// End Market Team Properties /////////////////////

/////////////// Start Marketing Team MyTeam ////////////////////

.group-chart-txt {
  @media screen and (max-width:1366px) {
    font-size: 14px !important;
  }

  @media screen and (max-width:1199px) {
    font-size: 13px !important;
  }

}

.group-sub-txt {
  @media screen and (max-width:1366px) {
    font-size: 13px !important;
  }

  @media screen and (max-width:1199px) {
    font-size: 12px !important;
  }

}

.mta_my_team_cart {
  .Doughnut-Chart {
    width: 100%;
    top: 0px !important;
    left: 0;

    @media only screen and (min-width:991px) and (max-width:1199.98px) {
      width: 55%;
      top: -87px !important;
      left: 11%;
    }

    @media only screen and (min-width:767px) and (max-width:991.98px) {
      width: 52%;
      top: -59px !important;
      left: 31%;
    }

    @media only screen and (min-width:640px) and (max-width:767.98px) {
      width: 51%;
      top: -19px !important;
      left: 25%;
    }

    @media only screen and (min-width:320px) and (max-width:575px) {
      width: 86%;
      top: 27px !important;
      left: 6%;
    }

    // @media only screen and (min-width:641px) and (max-width:990px){
    //   width: 97%;
    //   top: -46px !important;
    //   left: 5%;
    //   .inner-text{
    //     left: -22px;
    //   }
    // }
    // @media only screen and (min-width:991px) and (max-width:1199px){
    //   width: 92%;
    //   left: 16px;
    //   top: 59px !important;
    // }
  }

  .total_unit {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;

    @media only screen and (min-width:991px) and (max-width:1367px) {
      bottom: 0;
      left: 0;
      right: 0;
      transform: translate(-15%, -50%);
    }
  }
}

.mta_my_team_cart .Doughnut-Chart canvas {
  width: 400px !important;
  height: 400px !important;
  margin: auto;
}

.analytic-txt {
  @media screen and (max-width:1199px) {
    font-size: 14px !important;
  }
}

.mta_my_team_upcoming_tasks {
  .offer_main_img img {
    height: 69px !important;
  }

  .offer_main_details {
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .count {
    color: #000;
    font-size: 12px;
    line-height: normal;
    font-weight: 400;
    background-color: #DFE4EC;
    border-radius: 5px;
    padding: 3px 16px;
    height: 25px;
  }

  .properties_list_view .card {
    background-color: #F7F7F7;
  }

  @media only screen and (min-width:641px) and (max-width:1200px) {
    min-height: 193px;
  }

  @media only screen and (min-width:320px) and (max-width:640px) {
    min-height: 356px;
  }
}

.mta_team_members_card {
  .card {
    background-color: #F7F7F7;
  }

  .offer_main_img {
    width: 100%;
    height: 70px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;

    .num {
      z-index: 9999;
      position: absolute;
      background: #49484c;
      color: #fff;
      width: 25px;
      height: 25px;
      text-align: center;
      border-radius: 5px;
    }

    img {
      width: 100%;
      height: 70px;
      object-fit: cover;
      border-radius: 10px;
    }
  }
}

.action_required {
  height: 520px;
  overflow-y: scroll;
}

.myteam_actionRequired {
  .card {
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    overflow: hidden;
  }

  .offer_main_img {
    img {
      width: 100%;
      height: 145px;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  .actionRequired_details {
    .offer_main_details_div_2 {
      display: flex;
      position: relative;
      align-items: baseline;

      p {
        color: #000;
        padding-right: 20px;

        img {
          width: 18px;
          height: 18px;
        }
      }
    }

    .offer_main_details_div_3 {
      p {
        color: #A3A8A8;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .btn_light {
    background-color: #DFE4EC;
    border: 1px solid #DBDBDB !important;
    color: #000;

    &:hover {
      background-color: #DBDBDB;
    }
  }

  .member_name {
    img {
      width: 33px;
      height: 33px;
      object-fit: cover;
      border-radius: 50px;
    }
  }
}

.assigned_properties {
  .card {
    .offer_main_img {
      img {
        width: 100%;
        height: 85px;
        object-fit: cover;
        border-radius: 10px;
      }
    }
  }
}

#Hover {
  display: none;

  .mta_team_members_card .card {
    background-color: #fff;
    border: 1px solid #e9e9e9;
  }
}

.mta_team_members_card {
  p {
    @media only screen and (min-width:991px) and (max-width:1367px) {
      font-size: 11px;
    }
  }
}

.pm_announcement {
  .card {
    border: 1px solid #E3E3E3;
    border-radius: 11px;

    .card_img {
      img {
        border-radius: 5px;
        height: 100%;
        min-height: 70px;
        max-height: 70px;
        width: 100%;
        object-fit: cover;
      }
    }

    .sch-time {
      color: rgba(0, 0, 0, 0.5);
    }

    &:hover {
      background-color: #F5F5F5;
    }

  }
}

.villa-text {
  @media only screen and (max-width:576px) {
    font-size: 14px !important;
  }
}

/////////////// End Marketing Team MyTeam ////////////////////

/////////////// Start Marketing Team Approvals ////////////////////
.aside-menu .mta-approvals-icon-menu .menu-item {
  span.menu-icon::after {
    content: "";
    background-image: url("../../../assets/images/approval-in-active.svg");
    width: 19px;
    height: 19px;
  }
}

.aside-menu .mta-approvals-icon-menu .menu-item .menu-link.active,
.aside-menu .mta-approvals-icon-menu .menu-item .menu-link:hover {
  span.menu-icon::after {
    content: "";
    background-image: url("../../../assets/images/approval-active.svg");
  }
}

.mta_details_event_pic {
  img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: 10px;
  }
}

/////////////// End Marketing Team Approvals ////////////////////

/////////////// Start Marketing Team App Settings ////////////////////

.aside-menu .app-settings-icon-menu .menu-item {
  span.menu-icon::after {
    content: "";
    background-image: url("../../../assets/images/property-management-icons/Settings.svg");
    width: 20px;
    height: 20px;
  }
}

.aside-menu .app-settings-icon-menu .menu-item .menu-link.active,
.aside-menu .app-settings-icon-menu .menu-item .menu-link:hover {
  span.menu-icon::after {
    content: "";
    background-image: url("../../../assets/images/property-management-icons/Settings_active.svg");
  }
}

.mta-app-setting-campaign {
  .add-bt {
    font-weight: 800;
    color: #103B78;

    .bi-plus::before {
      font-weight: 800 !important;
      color: #103B78;
      vertical-align: bottom;
    }

    &:hover {
      color: #3965a2;
    }
  }

  .cancel-bt {
    border: 1px solid #DDDDDD;
    border-top-right-radius: 0.475rem;
    border-bottom-right-radius: 0.475rem;
    border-left: none;
    margin-left: -2px;

    .bi-x-circle::before {
      vertical-align: bottom;
    }
  }

}

/////////////// End Marketing Team App Settings ////////////////////

/////////////// Start Marketing Team Web Pages ////////////////////

.aside-menu .mta-web-pages-icon-menu .menu-item {
  span.menu-icon::after {
    content: "";
    background-image: url("../../../assets/images/web_pages_un_active.png");
    width: 20px;
    height: 20px;
  }
}

.aside-menu .mta-web-pages-icon-menu .menu-item .menu-link.active,
.aside-menu .mta-web-pages-icon-menu .menu-item .menu-link:hover {
  span.menu-icon::after {
    content: "";
    background-image: url("../../../assets/images/web_pages_active.png");
  }
}

.publish {
  height: 42px;

  @media only screen and (min-width:320px) and (max-width:640px) {
    margin-top: 15px;
  }
}

.mta_webpage_left {
  .image_icon {
    i {
      font-size: 150px;
      color: #eeeeee;
    }
  }

  .upload-input {
    button {
      border: 1px solid #e1e1e1 !important;
    }

    .upload {
      width: 28%;
      display: block;
      margin: 0 auto;
      border: 1px solid #707070;
      padding: 6px;
      border-radius: 3px;

      @media only screen and (min-width:991px) and (max-width:1199px) {
        width: 36% !important;
      }

      @media only screen and (min-width:768px) and (max-width:991px) {
        width: 33% !important;
      }

      @media only screen and (min-width:320px) and (max-width:640px) {
        width: 58% !important;
      }
    }

    .upload:hover {
      color: #fff;
    }
  }

  .bi-check-circle-fill {
    color: #ccf4d6;
  }

  .cancel-bt {
    border: 1px solid #DDDDDD;
    border-top-right-radius: 0.475rem;
    border-bottom-right-radius: 0.475rem;
  }

  .feature_img {
    background-color: #F5F5F5;
    border: 1px solid #D1D1D1;
    border-radius: 8px;
  }

  .form-switch .form-check-input:checked {
    background-color: #6B6B6B;
    border-color: #6B6B6B;
  }
}

.mta_webpage_right {
  .accordion-body {
    .text-secondaryyy {
      color: #aba7a7;
    }
  }

  .accordion-button::after {
    width: 1rem;
    height: 1rem;
    background-size: 1rem;
    background-position: center;
  }

  .contents_main_menu {
    li {
      list-style-type: none;
      padding-bottom: 10px;
    }
  }

  .add-text {
    font-size: 11px;

    @media screen and (max-width:576px) {
      font-size: 10px;
    }
  }

  .webpage_ad_bt {
    background-color: #F2F2F2;
    border: 1px solid #DCDCDC !important;

    img {
      //object-fit: cover;
      //width:30px;
      height: 25px;
    }
  }
}

/////////////// End Marketing Team Web Pages ////////////////////

/////////////// Start Marketing Team Campaign ////////////////////
.campaign-tiles {
  .text-count {
    color: #575050;

    span {
      color: #8C8C8C;
    }
  }
}

.create-campaign-popup {
  .modal-dialog {
    max-width: 520px;

    &.modal-dialog-scrollable {
      .modal-content {
        max-height: 100%;
        overflow: inherit;
      }
    }

    .modal-body {
      &::-webkit-scrollbar {
        width: 9px;
        height: 9px;
      }

      &::-webkit-scrollbar-track {
        background: #ffffff;
        border-radius: 30px;
      }

      &::-webkit-scrollbar-thumb {
        background: #ffffff;
        border-radius: 30px;
      }

      &:hover::-webkit-scrollbar-thumb {
        background-color: #CFCFCF !important;
      }

      .campaign-card {
        border: 1px solid #CDCACA;

        .card-body {
          span {
            i {
              color: #036CE4;
            }

            img {
              height: 15px;
              width: 15px;
            }
          }

          p {
            color: #575050;
          }

          i.bi-chevron-right {
            color: rgba(0, 0, 0, 0.5);
          }
        }

        &:last-child {
          margin-bottom: 0 !important;
        }
      }

      .step-one {
        p {
          color: #C5C4C4;
        }

        .text-successful {
          color: #A4A4A4;
        }

        .campaign-card {
          p {
            color: #C5C4C4;
          }

          span {
            img {
              height: 17px;
              width: 17px;
            }
          }
        }

        .lead-details {
          span {
            i {
              color: #036CE4;
            }
          }

          .form-check-input[type=checkbox] {
            width: 22px;
            height: 22px;
            border-radius: 3px;
            border-color: #CDCACA;
            margin-top: 0.101rem;
          }

          select.form-control {
            color: #ffffff;
            background-color: #49484c;
            border-color: #49484c;
            font-weight: 600 !important;
            font-size: 10px;
            border-radius: 3px;
            padding-right: 12px;

            &:focus {
              background-color: #49484c;
              border-color: #49484c;
              color: #ffffff;
            }
          }

          .form-select {
            background-image: url("../../../assets/images/Dropdown_arrow_white.svg") !important;
            background-size: 10px 5px;
          }

          .sync-status {
            color: rgba(0, 0, 0, 0.5);

            .pending-dot::before {
              content: "";
              background-color: #FFAA16;
              height: 6px;
              width: 6px;
              border-radius: 50%;
              display: inline-block;
              margin-right: 5px;
              vertical-align: middle;
            }
          }
        }

        .lead-selected {
          .campaign-card {
            background-color: #F0F3F6;
            border: 0;
            margin-bottom: 1.25rem !important;

            .input-group {
              .bi-x {
                color: rgba(0, 0, 0, 0.2);
              }
            }
          }
        }

        .form-check.custom-radio {
          .form-check-input {
            width: 1.05rem;
            height: 1.05rem;
            margin-top: 0.2rem;
            margin-left: -2.05rem;
          }
        }

        .add-on-text {
          a {
            color: #0091AE;
          }
        }

        .form-add-design {
          label {
            font-weight: 600 !important;
          }

          .input-group-text {
            .form-select {
              background-position: right 0rem center;
            }
          }
        }
      }

      .MuiStepper-root.multiform-stepper {
        background-color: transparent;
        align-items: inherit;
        justify-content: space-between;
        width: 100%;

        .MuiStep-root {
          padding: 20px 0 0;
          width: 100%;

          @media (min-width: 1900px) {
            padding: 20px 0 0;
          }

          &.active {
            background: #ffffff;

            .MuiStepLabel-root {
              .MuiStepLabel-iconContainer {
                border: 2px solid rgba(0, 44, 106, 1);
              }
            }
          }

          &.Mui-completed {
            .MuiStepLabel-root {
              .MuiStepLabel-iconContainer {
                background-color: #49484c;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                  &.Mui-completed {
                    color: #ffffff;
                    border-radius: 0;
                    width: 16px;
                    height: 16px;
                  }
                }
              }
            }
          }

          .MuiStepLabel-root {
            flex-direction: column;
            height: 100%;
            justify-content: start;

            .MuiStepLabel-iconContainer {
              padding: 0;
              border: 2px solid rgba(0, 44, 106, 0.3);
              border-radius: 5px;
              height: 28px;
              width: 28px;

              svg {
                width: 25px;
                height: 25px;
                color: transparent;
                font-weight: 500;

                &.Mui-active {
                  color: transparent;
                }

                &.Mui-completed {
                  color: #29C41E;
                }

                .MuiStepIcon-text {
                  font-size: 12px;
                  font-family: Poppins, 'sans-serif';
                  color: #49484c;
                  fill: #49484c;
                }
              }
            }

            .MuiStepLabel-labelContainer {
              color: rgba(0, 44, 106, 0.3);
              display: inline-block;
              width: inherit;
              margin: 12px 10px;

              .MuiStepLabel-label {
                text-align: center;
                font-family: Poppins, 'sans-serif';
                font-size: 12px;
                font-weight: 500;
                color: rgba(0, 44, 106, 0.3);

                &.Mui-active {
                  color: #49484c;
                }

                &.Mui-completed {
                  color: #49484c;
                }
              }
            }
          }
        }

        .MuiStepConnector-root {
          position: relative;
          display: block;

          &.Mui-active,
          &.Mui-completed {
            .MuiStepConnector-line {
              border-color: rgba(0, 44, 106, 1);
            }
          }

          .MuiStepConnector-line {
            display: block;
            border-color: rgba(0, 44, 106, 0.3);
            border-top-style: solid;
            border-top-width: 2px;
            position: absolute;
            max-width: 122px;
            width: 122px;
            height: 100%;
            left: -61px;
            top: 34px;
          }
        }
      }
    }
  }

  &.tenant-info-popup {
    .modal-dialog {
      max-width: 570px;

      .modal-content {
        padding: 30px 20px;
        background: #F5F5F5;

        .modal-header {
          border: none;
          margin-bottom: 24px;
          background: transparent;

          .modal-title {
            color: #000000;
            font-size: 22px;
            min-height: 32px;
            line-height: 33px;

          }

          .btn-close {
            right: 10px;
            top: 10px;
          }
        }

        .modal-body {
          padding: 0;

          .card {
            border: none;
            border-radius: 8px;

            .card-body {
              border: none;
              padding: 23px 20px;

              i.bi-chevron-right {
                color: #000000;
              }
            }
          }

          .MuiStepper-root.multiform-stepper {
            .MuiStep-root {
              background: transparent;
            }
          }

          label {
            color: #707070;
            font-weight: normal;
          }

          .form-control {
            border: 1px solid #B2BFD2;
            color: #49484c;
            font-size: 14px;
            font-weight: normal;
            line-height: 26px;
          }

          .btn-primary {
            width: 100%;
            background: #49484c;
            font-size: 18px;
            line-height: 27px;
          }

          .btn-outline-primary {
            width: 100%;
            line-height: 27px;
          }

          .upload-input {
            .btn-outline-primary {
              border: 1px dashed #707070 !important;
              background: rgba(255, 255, 255, 0.39) !important;
              padding: 23px 14px !important;
              color: #536475 !important;
              font-size: 16px;
              outline: none;
              box-shadow: initial;

              &:hover {
                background: rgba(255, 255, 255, 0.39) !important;
              }

              img {
                vertical-align: top;
              }
            }

          }

        }

        .row {
          margin: 0;

          .col-md-12 {
            padding: 0;
          }

          &.em-row {
            margin: 0 -8px;
          }
        }

        .upload-title {
          margin: 11px 0 16px;
          color: #536475;
          font-size: 18px;
          line-height: 27px;
          font-weight: 600;
        }

        .success-active {
          color: #49484c;
          font-weight: 600;
          font-size: 24px;
        }

        .text-short {
          color: #707070;
        }
      }
    }
  }
}


/////////////// End Marketing Team Campaign ////////////////////

// Start Commercial Tenant Styles //
.topbar-tenant-search{
  .ant-select-selector{
  background-color: #FAFAFA !important;
  border-color: transparent !important;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  background-image: url("../../../assets/images/Dropdown_circle.png")  !important;
  background-size: 15px 17px !important;
  background-position: right 0.8rem center;
  padding-left: 15px;
  min-width: 250px;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-repeat: no-repeat;
  border-radius: 8px !important;
  height: 40px !important;
  padding: 6px !important;
}
}
.ant-select-item {
  position: relative;
  display: block;
  min-height:32px;   
  padding:5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  background:transition 0.3s ;
 }
 .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #fde3a4;   
 }
.move-out-accordion {
  .accordion-item {
    background-color: #F9F9F9 !important;
    margin-bottom: 15px;
    border-radius: 10px !important;
    border: 0;

    .accordion-button {
      border-radius: 10px !important;
      font-weight: 700;
      font-size: 14px;
      background-color: #F9F9F9;
      height: 55px;
      padding-left: 25px;
      padding-right: 25px;

      &:not(.collapsed) {
        color: #000;
        background-color: #F9F9F9;
        box-shadow: none;
        //border-bottom-left-radius: 0 !important;
        //border-bottom-right-radius: 0 !important;
      }
    }

    .accordion-body {
      background-color: #F9F9F9;
      border-bottom-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
      padding-left: 25px;
      padding-right: 25px;
      @media only screen and (max-width: 767.98px) {
        padding-left: 20px;
        padding-right: 20px;
      }
      table {
        color: #000000;
        font-size: 14px;

        thead {
          tr {
            th {
              font-weight: 600;

              &:nth-child(2) {
                text-align: center;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              font-weight: 400;

              &:nth-child(2) {
                text-align: center;
              }
            }

            &:last-child {
              background-color: #ffffff;

              td:nth-child(1) {
                padding-left: 35px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                font-weight: 600;
              }

              td:nth-child(2) {
                text-align: center;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}

.move-out-inner-accordion {
  .accordion-item {
    background-color: #FFFFFF !important;
    margin-bottom: 12px;
    border-radius: 9px !important;
    border: 1px solid #C6C6C6;
    margin-top: 10px;

    .accordion-button {
      border-radius: 9px !important;
      font-weight: 400;
      font-size: 14px;
      background-color: #FFFFFF;
      height: 48px;
      padding-left: 25px;
      padding-right: 25px;

      &:not(.collapsed) {
        color: #000;
        background-color: #FFFFFF;
        box-shadow: none;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }

      &::after {
        background-size: 1.15rem;
      }

      >span::before {
        content: '';
        display: inline-block;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        margin-right: 10px;
      }

      >span.active::before {
        content: '';
        background-color: #0EB03B;
      }

      >span.in-active::before {
        content: '';
        background-color: #FA9917;
      }
    }

    .accordion-body {
      background-color: #FFFFFF;
      border-bottom-left-radius: 9px !important;
      border-bottom-right-radius: 9px !important;
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}

.permit-work-card {
  background-color: #F9F9F9 !important;
  height: 55px;
  cursor: pointer;
}

.completion-modal {
  .modal-dialog {
    max-width: 550px;
    width: 100%;
  }

  .modal-header {
    background-color: #ffffff;

    .modal-title {
      color: #202020;
    }

    .btn-close {
      &:focus {
        outline: 0 !important;
        box-shadow: none !important;
      }
    }
  }

  .modal-body {
    .form-sec {
      .total-que-count {
        h2 {
          color: rgba(0, 44, 106, 0.3);

          span {
            &.active {
              color: rgba(0, 44, 106, 1);
            }
          }
        }
      }

      .progress {
        height: 10px;
        background-color: rgba(200, 200, 200, 0.25);

        .progress-bar {
          background-color: #49484c;
          border-radius: 100px;
        }
      }

      .btn-group.question-options {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        .btn-outline-primary {
          width: 100%;
          border: 2px solid rgba(0, 44, 106, 0.4) !important;
          border-radius: 5px;
          margin-bottom: 22px;
          color: #49484c;
          font-size: 14px;
          font-weight: 600;

          &.yes:hover {
            background-color: #EAEDF3 !important;
            color: #49484c !important;
            border: 2px solid rgba(0, 44, 106, 0.4) !important;
          }

          &.no:hover {
            background-color: #FFE7E7 !important;
            color: #D90508 !important;
            border: 2px solid rgba(217, 5, 8, 0.4) !important;
          }

          &.notapplicable:hover {
            background-color: rgba(0, 44, 106, 0.1) !important;
            color: #49484c !important;
            border: 2px solid rgba(0, 44, 106, 0.1) !important;
          }

          &:last-child {
            margin-bottom: 0 !important;
          }
        }

        .btn-check:checked+.btn-outline-primary {
          &.yes {
            background-color: #EAEDF3 !important;
            color: #49484c !important;
            border: 2px solid rgba(0, 44, 106, 0.4) !important;
          }

          &.no {
            background-color: #FFE7E7 !important;
            color: #D90508 !important;
            border: 2px solid rgba(217, 5, 8, 0.4) !important;
          }

          &.notapplicable {
            background-color: rgba(0, 44, 106, 0.1) !important;
            color: #49484c !important;
            border: 2px solid rgba(0, 44, 106, 0.1) !important;
          }
        }
      }
    }
  }
}

.m-details-overview {
  .market-place-logo-sec {
    background-color: #F2F2F2;

    img {
      width: auto;
      height: auto;
    }
  }

  .offer-count {
    background-color: #3D7BBF;
    color: #ffffff;
    border-radius: 5px;
    padding: 4px 0;
    font-size: 11px;
    font-weight: 400;
    width: 100%;
  }

  .offer-per {
    background-color: #D02A53;
    color: #ffffff;
    border-radius: 5px;
    padding: 4px 0;
    font-size: 11px;
    font-weight: 400;
    width: 100%;
    margin-left: 10px;
  }

  .full-details {
    label {
      color: rgba(0, 0, 0, 0.3);
    }

    span {
      .icon-bg {
        background-color: #EAEAEA;
        color: #000000;
        border-radius: 50%;
        margin-right: 8px;
        height: 24px;
        width: 24px;
        display: inline-flex;
        text-align: center;
        align-items: center;
        justify-content: center;

        i {
          font-size: 13px;
          font-weight: 900;
          color: #000000;
        }
      }
    }
  }
}

.stepper-actions {
  .btn-back:disabled {
    opacity: 0;
  }
}

.MuiStepper-root.multiform-stepper {
  background-color: #F5F5F5;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  align-items: inherit;
  justify-content: space-between;
  width: 100%;
  overflow-y: scroll;

  .MuiStep-root {
    padding: 20px 0;
    width: 100%;

    @media (min-width: 1900px) {
      padding: 20px 0;
    }

    &.active {
      background: #ffffff;

      &:first-child {
        border-top-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius: 10px;
      }
    }

    .MuiStepLabel-root {
      flex-direction: row-reverse;
      height: 100%;
      justify-content: center;

      .MuiStepLabel-iconContainer {
        padding-left: 4px;

        @media screen and (max-width:576px) {
          padding-left: 0px;
        }

        svg {
          width: 17px;
          height: 17px;
          color: rgba(164, 164, 164, 0.2);
          font-weight: 500;

          &.Mui-active {
            color: rgba(164, 164, 164, 0.2);
          }

          &.Mui-completed {
            color: #29C41E;
          }

          .MuiStepIcon-text {
            font-size: 12px;
            font-family: Poppins, 'sans-serif';
          }
        }
      }

      .MuiStepLabel-labelContainer {
        color: #A4A4A4;
        display: inline-block;
        width: 110px !important;

        .MuiStepLabel-label {
          text-align: center;
          font-family: Poppins, 'sans-serif';
          font-size: 12px;
          font-weight: 500;
          color: #A4A4A4;

          &.Mui-active {
            color: #000000;
          }

          &.Mui-completed {
            color: #000000;
          }
        }
      }
    }
  }

  .MuiStepConnector-root {
    display: none;
  }
}

#new-fit-out-permit{
  // background-color: #d9d9d9;
  .MuiStepLabel-labelContainer {
    width: 220px !important;
  }
}

.rmsc .dropdown-container {
  border: 1px solid #DDDDDD !important;
  border-radius: 0.475rem !important;
  color: #000000 !important;
  font-weight: 500 !important;
  height: 43px !important;

  &:focus-within {
    box-shadow: none !important;
    border-color: #DDDDDD !important;
  }

  .dropdown-heading {
    padding: 0 1rem !important;
    height: 43px !important;
    color: #000000 !important;
  }
}

.request-refund-bg {
  background-color: #F5F5F5;
}

// End Commercial Tenant Styles //

// Start Property Manager Styles //
.aside-menu .pm-approval-icon-menu .menu-item {
  span.menu-icon::after {
    content: "";
    background-image: url("../../../assets/images/approval-in-active-icon.svg");
    width: 19px;
    height: 19px;
  }
}

.aside-menu .pm-approval-icon-menu .menu-item .menu-link.active,
.aside-menu .pm-approval-icon-menu .menu-item .menu-link:hover {
  span.menu-icon::after {
    content: "";
    background-image: url("../../../assets/images/approval-active-icon.svg");
  }
}

.mta_details_event_pic {
  img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: 10px;
  }
}

.unit-summary-sec {
  //height: 340px;
  //max-height: 340px;
  min-height: 340px;

  @media only screen and (min-width:991px) and (max-width:1367px) {
    min-height: 375px;
  }
}

.reject {
  background-color: #FEECEC;

  &:hover {
    background-color: #f1416c !important;
    color: #fff;
  }
}

.request-more-info {
  background-color: #E8ECF2;

  &:hover {
    background-color: #194078 !important;
    border-color: #194078 !important;
    color: #ffffff !important;
  }
}

@media only screen and (min-width:320px) and (max-width:640px) {
  .pm_approval_buttons {
    button {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .pm-topbar-buttons {
    button {

      font-size: 10px;
    }
  }
}

@media only screen and (min-width:768px) and (max-width:991px) {
  .history_main_div {
    .history_div {
      label {
        font-size: 10px !important;
      }

      h3 {
        font-size: 13px;
      }
    }
  }

  // .pm-topbar-buttons{
  //   button{

  //     font-size: 11px;
  //   }
  // }
}

@media only screen and (max-width:1367px) {
  .history_main_div {
    .history_div {
      label {
        font-size: 13px !important;
      }

      h3 {
        font-size: 16px;
      }
    }
  }
}

@media only screen and (min-width:991px) and (max-width:1199px) {
  .history_main_div {
    .history_div {
      label {
        font-size: 11px !important;
      }

      h3 {
        font-size: 14px;
      }
    }
  }

  // .pm-topbar-buttons{
  //   button{
  //     white-space: nowrap;
  //     font-size: 12px;
  //   }
  // }
}

.history_div {
  margin-right: 20px;
}

.available_checkbox {
  text-align: right;
  position: absolute;
  padding: 10px;
}

.availability_card_view {
  height: 700px;
  overflow-y: scroll;
}

#update_bt {
  border-color: #fff !important;
  border: 0 solid #fff !important;
  background: transparent;
}

.make-an-offer-year {
  background: #F7F7F7;
}

.check-date-details .accordion-item {
  background-color: #ffffff !important;
  margin-bottom: 15px;
  border-radius: 10px !important;
  border: 0;
}

.check-date-details .accordion-item .accordion-button {
  border-radius: 10px !important;
  font-weight: 700;
  font-size: 14px;
  background-color: #ffffff;
  height: 55px;
  padding-left: 25px;
  padding-right: 25px;
}

.check-date-details .accordion-item .accordion-button:not(.collapsed) {
  color: #000;
  background-color: #ffffff;
  box-shadow: none;
}

.pm-offers-and-renewals {
  .card {
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    overflow: hidden;

    .offer_main_img {
      position: relative;
      z-index: 1;
      .img-inner {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
          max-height: 200px;
          position: relative;
          z-index: 9999;
        }
        &::after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.16) 0%, #000000 131.3%);
          //z-index: 9999;
        }
      }
      .property-overlay {
        padding: 10px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        color: #fff;
        z-index: 9999;
        display: block;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.16) 0%, #000000 131.3%);

        h6 {
          font-size: 14px;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0em;
          text-align: left;
          color: #FFFFFF;
          margin-bottom: 0;
        }
        .price-amount {
          color: #FFFFFF;
        }
      }
    }

    .mainCard_d_etails_div_2 {
      p {
        border-radius: 50px;
        width: auto;
        text-align: center;
        color:#000;
        white-space: nowrap;
        img{
          filter:grayscale(1);
        }

        @media only screen and (min-width:320px) and (max-width:767.98px) {
          width: auto !important;
          border-radius: 5px;
        }

        @media only screen and (min-width:641px) and (max-width:1025px) {
          width: 50%;
        }
      }

      .sqft-my-offer {
        width: 125px !important;
      }
    }
    .offer_main_details_div_3{
      img{
        filter: grayscale(1);
      }
    }

    .my-offer-property-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    .offer-price-detail {
      background-color: #F6F6F6;
      text-align: center;
      width: 70%;
      padding: 10px;
      border-radius: 10px;

      @media only screen and (min-width:320px) and (max-width:640px) {
        width: 100%;
      }

      @media only screen and (min-width:641px) and (max-width:1025px) {
        width: 80%;
      }
    }

    @media only screen and (min-width:320px) and (max-width:640px) {
      .offer_main_details {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    @media only screen and (max-width: 991.98px) {
      .offer_main_details {
        .offer_main_details_div_3 {
          line-height: 28px;
        }
      }
    }

    @media only screen and (max-width: 575.98px) {
      .visit-confirmed-date-di {
        p.font-17 {
          font-size: 12px !important;
          margin-bottom: 2px !important;
        }

        .visit-confirmed {
          margin-right: 3px !important;
        }

        p {
          font-size: 11px;
        }
      }
    }
  }
}

.pm-offers-and-renewals-details-card{
  .card{
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    overflow: hidden;
    .offer_main_img{
      img{
        height: 100%;
        width: 100%;
        max-height: 167px;
        object-fit: cover;
        border-radius: 10px;
      }
      .prop-type-badge{
        right: 0;
        border-radius: 0px 10px !important;
        padding: 10px;
        left: 80%;
      }
    }
  }
}

.input-field-new{
  background: #FFFFFF;
  border: 1px solid rgba(67, 67, 67, 0.35);
  border-radius: 8px;
  padding: 10px;
  height: 53px;
  label{
    position: relative;
    top: -23px;
    background: #fff;
    padding: 2px;
  }
  .data-contain{
    margin-top: -24px;
    margin-left: 10px;
  }
}

.makeon-offer-div {
  background: #F3F3F3;
  text-align: center;
  padding: 10px 0;
  border-radius: 6px;
}

.out-of-office {
  margin: -26px auto 0 auto;
  display: block;
  position: relative;
}

.propertymanager-card {
  min-height: 457px;
  max-height: 457px;
}

// Start Tenant Search //
.tanent-select{
  padding: 10px !important;
  font-size: 14px !important;
  &:hover{
    color: #000;
    background-color: #fde3a4 !important;
  }
}

.pm-tenant {
  @media only screen and (min-width:320px) and (max-width:640px) {
    display: block !important;
  }
}

@media only screen and (min-width:320px) and (max-width:640px) {
  .filter_tenant {
    display: none;
  }
}

.table> :not(:first-child) {
  border-top: 0px solid currentColor;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: rgb(255 255 255 / 75%);
  color: var(--bs-table-striped-color);
}

.move-in-left-img {
  overflow: hidden;

  img {
    width: 70px;
    height: 65px;
    object-fit: cover;
  }
}

.other-srch-details {
  background-color: #F0F4F9;
}

.other-srch-details .border-right:after {
  content: "";
  border-right: 1px solid rgba(112, 112, 112, 0.4);
  position: absolute;
  left: 0;
  right: 12%;
  display: inline-block;
  height: 76px;
  top: -13px;
  bottom: 0;
}

// END Tenant Search //

// End Property Manager Styles //


// Start Prospective Tenant Styles //

.data-contain2 {
  background-color: #E6E9EC;
  height: 45px;
}

.aside-menu .mydocument-icon-menu .menu-item {
  span.menu-icon::after {
    content: "";
    background-image: url("../../../assets/images/property-management-icons/Documents_inactive.svg");
    width: 20px;
    height: 20px;
  }
}

.aside-menu .mydocument-icon-menu .menu-item .menu-link.active,
.aside-menu .mydocument-icon-menu .menu-item .menu-link:hover {
  span.menu-icon::after {
    content: "";
    background-image: url("../../../assets/images/property-management-icons/Documents_active.svg");
  }
}

.aside-menu .faq-icon-menu .menu-item {
  span.menu-icon::after {
    content: "";
    background-image: url("../../../assets/images/property-management-icons/Question_inactive.svg");
    width: 20px;
    height: 20px;
  }
}

.aside-menu .faq-icon-menu .menu-item .menu-link.active,
.aside-menu .faq-icon-menu .menu-item .menu-link:hover {
  span.menu-icon::after {
    content: "";
    background-image: url("../../../assets/images/property-management-icons/Question.svg");
  }
}

.aside-menu .settings-icon-menu .menu-item {
  span.menu-icon::after {
    content: "";
    background-image: url("../../../assets/images/property-management-icons/Settings.svg");
    width: 20px;
    height: 20px;
  }
}

.aside-menu .settings-icon-menu .menu-item .menu-link.active,
.aside-menu .settings-icon-menu .menu-item .menu-link:hover {
  span.menu-icon::after {
    content: "";
    background-image: url("../../../assets/images/property-management-icons/Settings_active.svg");
  }
}

.pt-search {
  background-image: url("../../../assets/images/pt-search-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.aside-menu .personal-inforamation-icon-menu .menu-item {
  span.menu-icon::after {
    content: "";
    background-image: url("../../../assets/images/user-ioc.png");
    width: 20px;
    height: 20px;
  }
}

.aside-menu .personal-inforamation-icon-menu .menu-item .menu-link.active,
.aside-menu .personal-inforamation-icon-menu .menu-item .menu-link:hover {
  span.menu-icon::after {
    content: "";
    background-image: url("../../../assets/images/user-ioc-active.png");
  }
}

.pt-search-bg {
  background-color: rgba(0, 0, 0, 0.3)
}

.pt-dashbord-myoffer {
  position: absolute;
  z-index: 5;
  text-align: center;
  right: 10px;
  top: 10px;
  span.liked-prop {
    font-size: 20px !important;
    color: #fff !important;
    font-family: "Font Awesome 5 Pro" !important;
    font-style: normal;
    &::before {
      content: "\f004";
    }
    &::after {
      content: "\f004";
      color: rgb(244,67,54);
      font-size: 16px;
      border: 0px solid transparent;
      border-radius: 0;
      z-index: 5;
      position: absolute;
      font-weight: 900;
      top: 3px;
      left: 2px;
    }
  }
}


.check-date-details.pros-offer .accordion-item {
  background-color: #fff !important;
  border: 1px solid #efefef;
}

.pros-offer .data-contain {
  background-color: #ffffff
}

.pt-password {
  .bg-dark-grey {
    background-color: #FAFAFA !important;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: inherit !important;
    -webkit-box-shadow: 0 0 0px 1000px #FFFFFF inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

#tenant-work-Information {
  .custom-control-input[type=checkbox] {
    width: 16px;
    height: 16px;
    border-radius: 0;
    border-color: #707070;
    background-size: 100% 60%;
  }

  .custom-control-input:checked {
    background-color: #49484c;
    border-color: #49484c;
  }
}

.visit-confirmed {
  color: #CDDD00;
}

.visit-confirmed-date-div {
  background-color: #F6F6F6;
}

.pt-my-links {
  position: relative;
  margin: 0 auto;
  display: inline-block;

  .form-check-label {
    font-family: "Font Awesome 5 Pro" !important;
    .form-check-input[type=checkbox] {
      border-radius: inherit;
      width: inherit;
      height: inherit;
    }

    &:before {
      content: "\f004";
      position: absolute;
      z-index: 5;
      left: 4px;
      top: -10px;
      color: #000;
      font-size: 20px;
      animation: 0.55s cubic-bezier(0.38, 0.7, 0.6, 0.29) 0s 1 normal none running leKisK;
      @media only screen and (max-width: 767.98px) {
        color: #fff;
      }
    }

    .form-check-input:checked {
      background-color: inherit !important;
      border-color: inherit !important;
      border: 0 !important;
      position: relative;
      background-image: none;
    }

    .form-check-input:checked:after {
      top: -19px;
      left: 6px;
      visibility: inherit;
      content: "\f004";
      color: rgb(244,67,54);
      font-size: 16px;
      border: 0px solid transparent;
      border-radius: 0;
      z-index: 5;
      position: absolute;
      font-weight: 900;
      animation: 0.4s cubic-bezier(0.38, 0.7, 0.6, 0.29) 0s 1 normal none running gcegeJ;
    }
    @keyframes gcegeJ {
      0%   {
        transform: scale(1) rotate(0deg);
      }
      50%  {
        transform: scale(1.34) rotateY(90deg);
      }
    }
    @keyframes leKisK {
      0% {
        transform: scale(1.34) rotateY(90deg);
      }
      50% {
        transform: scale(1) rotateY(0deg);
      }
    }
  }
}

.search-area {
  z-index: 999;
}

.refund-block {
  background: #EFEFEF;
}

.accept-offer {
  color: #536475 !important;
}

@media only screen and (min-width:641px) and (max-width:991px) {}

@media only screen and (min-width:320px) and (max-width:640px) {
  .cpt-btn {
    margin: 10px 0;
  }
}

.plugin-input {
  padding: 0.75rem 1rem;
  border: 1px solid #e4e6ef;
  border-radius: 0.475rem;
  background: #fff;

  .PhoneInputInput {
    border: 0;
    background: transparent;

    &:focus-visible {
      outline: -webkit-focus-ring-color auto 0px;
    }
  }
}

.react-tel-input {
  input {
    width: 100% !important;
    height: 42px !important;
  }
}


// Bootstrap Loading Skeleton
%loading-skeleton {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;

  &::placeholder {
    color: transparent;
  }
}

@keyframes loading-skeleton {
  from {
    opacity: .4;
  }

  to {
    opacity: 1;
  }
}

.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;

  img {
    filter: grayscale(100) contrast(0%) brightness(1.8);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  .btn,
  label,
  .form-control {
    @extend %loading-skeleton;
  }
}

.announcement-main-images {
  overflow: hidden;
  background: #f9f9f9;
  border-radius: 6px;
  img {
    width: 100%;
    max-height: 250px;
    object-fit: cover;
    border-radius: 10px;

    @media (min-width: 1200px) {
      object-fit: contain;
    }

    @media (max-width: 767.98px) {
      height: 290px;
    }
  }
}

.dropdown-toggle {
  &:after {
    border: none;
  }
}

.btn-sections {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  @media (max-width: 575px) {
    justify-content: flex-start;
  }
}

.table-responsive {
  .MuiDataGrid-root {
    .MuiDataGrid-main {
      .MuiDataGrid-virtualScroller {
        overflow-x: auto !important;
        position: relative;
        overflow-y: hidden !important;
      }
    }
  }
}

.menu-item.menu-accordion.show:not(.hiding):not(.menu-dropdown)>.menu-link .menu-arrow:after,
.aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-arrow:after {
  filter: invert(75%);
}

.aside-menu .menu-item {
  margin-bottom: 8px;
}

.com-card-section {
  .nav-line-tabs .nav-item .nav-link {
    white-space: nowrap;
    border-radius: 5px;
  }
}

.history_main_div {
  flex-direction: row;
  flex-wrap: wrap;

  .history_div {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-right: 20px;
    flex: 1;

    @media (max-width: 1199px) {
      flex: auto;
      margin-bottom: 20px;
    }
  }
}

.parking-slot-sec {
  img {
    width: 19px;
    height: 38px;
  }
}

canvas.sigCanvas {
  max-width: 100%;
}

.text-normal {
  color: #A4A4A4;
}

.com-docx-sec {
  border: 1px solid #d9d9d9;
  border-radius: 7px;
  padding: 10px;
}

.services-badge {
  position: absolute;
  right: 68px;
  top: 16px;
  font-size: 12px;
  background-color: rgba(214, 182, 18, 0.14);
  color: #D6B612;
  padding: 10px 30px;
  border-radius: 4px;
  z-index: 3;

  @media only screen and (min-width:320px) and (max-width:767.98px) {
    right: 49px;
    top: 18px;
    padding: 6px 16px;
  }
}

.services-badge-approve {
  position: absolute;
  right: 68px;
  top: 16px;
  font-size: 12px;
  background-color: rgba(62, 233, 0, 0.14);
  color: #3EE900;
  padding: 10px 30px;
  border-radius: 4px;
  z-index: 3;
}

@media (max-width: 480px) {
  .pt-search {
    padding: 20px !important;
  }
}

@media (max-width: 1199px) {


  .content .container,
  .content .container-fluid,
  .content .container-sm,
  .content .container-md,
  .content .container-lg,
  .content .container-xl,
  .content .container-xxl {
    padding: 0 15px 0 20px;
  }
}



@media (max-width: 991px) {
  .move-in-main-div {
    h2 {
      font-size: 16px;
    }
  }

  .upcoming_main_div {
    .upcoming-Div {
      h4 {
        font-size: 14px;
      }

      p {
        font-size: 13px;
      }

      .up-co-cl {
        .form-label {
          font-size: 12px;
        }

        .upcoming-date {
          font-size: 12px;
        }
      }

      .visit-confirm {
        .visit-c-img {
          p {
            font-size: 12px;
            text-align: center;
          }
        }
      }
    }
  }
}

.filter-img1 {
  @media screen and (max-width:768px) {
    width: 18px !important;
  }
}

@media (max-width: 767.98px) {
  body {
    padding-top: 55px;
  }

  .history_main_div {
    .history_div {
      label {
        font-size: 11px !important;
      }

      h3 {
        font-size: 14px;
      }
    }
  }

  .rec-payment-act-sec {
    .table-responsive {
      .table {
        thead {
          tr {
            th {
              font-size: 13px;
              white-space: nowrap;
            }
          }
        }

        tbody {
          tr {
            td {
              font-size: 12px;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  .com-card-section {
    font-size: 13px;

    .card {
      &.payment-card-sec {
        label {
          font-size: 11px !important;
        }

        h3 {
          font-size: 14px;
        }
      }
    }

    .btn-primary {
      font-size: 12px !important;
    }

    .btn-outline-primary {
      font-size: 12px !important;
    }

    .btn-outline-danger {
      font-size: 12px !important;
    }

    .nav-line-tabs .nav-item .nav-link {
      font-size: 14px;
      margin: 0;
    }
  }

  .com-card-section {
    .card-title {
      font-size: 15px;
    }

    .table-responsive {
      .MuiDataGrid-root {
        .MuiDataGrid-main {
          .MuiDataGrid-columnHeaders {
            .MuiDataGrid-columnHeadersInner {
              font-size: 13px;

            }
          }
        }

        .MuiDataGrid-row {
          .MuiDataGrid-cell {
            font-size: 13px;
          }
        }
      }
    }

    .card {
      .data_table {
        .table-responsive {
          .MuiDataGrid-root {
            .MuiDataGrid-main {
              .MuiDataGrid-columnHeaders {
                .MuiDataGrid-columnHeadersInner {
                  font-size: 13px;

                }
              }
            }

            .MuiDataGrid-row {
              .MuiDataGrid-cell {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }

  .faq-accordian {
    .accordion-item {
      .accordion-header {
        .accordion-button {
          font-size: 13px;
          padding: 15px;

          &:not(.collapsed) {
            font-size: 13px;
          }
        }
      }

      .accordion-collapse {
        .accordion-body {
          font-size: 13px;
          padding: 0 15px 15px;
        }
      }
    }
  }

  .properties_list_view {
    height: auto;
  }

  .settings-card {
    .reset-btn {
      @media (max-width:576px) {
        width: 100%;
      }
    }
  }

  .terminatiom-hd {
    @media (max-width:1200px) {
      font-size: 14px !important;
    }
  }

  .text-start {
    @media (max-width:640px) {
      font-size: 10px;
    }
  }

  .filter,
  .add-txt {
    @media (max-width:575.98px) {
      font-size: 13px !important;
      height: 34px !important;
    }
  }

  .filter-img {
    @media (max-width:576.98px) {
      height: 20px !important;
    }
  }
}

// End Prospective Tenant Styles //


///////////////////////////////////

.cpt-tenant-det {
  .nav {
    border-bottom: 1px solid #707070;
  }

  .nav-item {
    width: 48%;
  }

  .nav-pills .nav-link {
    color: #000;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    color: #49484c;
    background-color: transparent;
    border-bottom: 2px solid #49484c;
    border-radius: 0;
  }
}

.ten-checkbox {
  position: relative;
  right: 0;
  left: 17%;
}

.cpt-tenant-detail {
  .MuiStepper-horizontal {
    display: none;
  }
}

.cpt-headding {
  margin-top: -25px;
  font-size: 18px;
  padding-bottom: 15px;
}

.t-myservice-inprogress-bt {
  float: right;
  position: relative;
  top: -56px;
  right: 65px;
  color: #D6B612;
  background: #d6b61226;
  border: 0 !important;
  display: block;
  z-index: 999999;
}

.photo-view {
  float: right;
  top: -33px;
  right: 10px;
  position: relative;
  border: 0;
  background: transparent;
}

.bg-light {
  background: #f7f7f7 !important;
}

.prop-offer_main_img {
  overflow: hidden;
  height: 100%;

  img {
    object-fit: cover;
    border-radius: 8px;
    height: 100px;
    width: 100%;
  }
}

.p-of-edi-input {
  padding-left: 69px;
}

.prop-det-icon {
  width: 50px;
  position: absolute;
  margin-top: -30px;
  padding-left: 14px;
  z-index: 99999;
}

.mta_team_members_card {
  .offer_main_img_new {
    min-height: 50px;
  }
}

.member-status {
  &.active {
    position: relative;
    color: #A4A4A4;
    padding-left: 15px;
    font-size: 12px;

    &::before {
      content: "";
      position: absolute;
      background-color: #147ad6;
      width: 8px;
      height: 8px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      left: 0;
      top: 5px;
    }
  }

  &.inactive {
    position: relative;
    color: #A4A4A4;
    padding-left: 15px;
    font-size: 12px;

    &::before {
      content: "";
      position: absolute;
      background-color: #ff5f58;
      width: 8px;
      height: 8px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      left: 0;
      top: 5px;
    }
  }
}

.card-status {
  overflow: hidden;

  .pending {
    background-color: #BFA541;
    color: #fff;
    display: inline-block;
    padding: 9px 25px;
    font-size: 15px;
    position: absolute;
    z-index: 1;
    right: -1px;
    top: -1px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;

    @media (max-width: 575.98px) {
      font-size: 11px;
      top: 0;
      padding: 4px 10px;
      font-weight: 600;
    }
  }

  .rejected {
    background-color: rgba(190, 41, 30, 0.34);
    color: #BE291E;
    display: inline-block;
    padding: 9px 25px;
    font-size: 15px;
    position: absolute;
    z-index: 1;
    right: -1px;
    top: -1px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;

    @media (max-width: 575.98px) {
      font-size: 11px;
      top: 0;
      padding: 4px 10px;
      font-weight: 600;
    }
  }

  .approved {
    background-color: #579B26;
    color: #fff;
    display: inline-block;
    padding: 9px 25px;
    font-size: 15px;
    position: absolute;
    z-index: 1;
    right: -1px;
    top: -1px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;

    @media (max-width: 575.98px) {
      font-size: 11px;
      top: 0;
      padding: 4px 10px;
      background-color: rgba(33, 212, 56, 0.5);
      color: #fff;
      font-weight: 600;
    }
  }
}

.gray-card {
  background-color: #F7F7F7;
  padding: 10px;
  border: 1px solid #D6D4D4;
  border-radius: 8px;
  position: relative;
}

.modal:nth-of-type(even) {
  z-index: 1052 !important;
}

.modal-backdrop.show:nth-of-type(even) {
  z-index: 1051 !important;
}

.market-place-card .cardimg img {
  width: 25px;
  height: 30px;
}

.text-short {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

// .card-title {
//   @media (max-width: 1200px) {
//     font-size: 13px !important;
//   }
// }
.Depaertment-menu-dropdown {
  .menu-link {
    color: #727171 !important;
  }
}

.create-campaign-popup.tenant-info-popup .modal-dialog .modal-content .modal-body .upload-input .btn-outline-primary.cheque-upload {
  border: 1px solid #707070 !important;
  background: rgba(255, 255, 255, 0.39) !important;
  padding: 9px 14px !important;
  color: #536475 !important;
  font-size: 13px;
  outline: none;
  box-shadow: initial;
}

// #unitsumary{
//   height: 100px;
// }
// #leasesummary{
//   height: 100px;
// }
.approved-popup {
  padding: 10.5px 10.5px;
  border-radius: 15px;
  max-width: 407px;
  width: 407px;
  background-color: #cccccc;

}

.heading {
  background-color: #e5e5e5;
  border-radius: 3px;
}

//#unitsumary{
//  height: 100px;
//}
//#leasesummary{
//  height: 100px;
//}

.attachment-div label:not(.error) {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  margin: 0;
  background: transparent;
  border: 1px solid #DDDDDD;
  position: relative;
  display: block;
  text-align: left;
  padding: 0.67rem 0.75rem 0.75rem;
  color: #000;
  cursor: pointer;
  padding-right: 110px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 13px;
  line-height: 27px;
}

.attachment-div label:not(.error):after {
  content: '';
  position: absolute;
  font-size: 12px;
  font-weight: normal;
  right: 11px;
  top: 12px;
  border: none;
  padding: 4px 10px;
  border-radius: 5px;
  transition: all 0.2s ease;
  background-image: url("../../../assets/images/download-arrow.png") !important;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
}

.attachment-div input {
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  margin-top: -15px;
  visibility: hidden;
}

.addons-check {
  background-color: #f4f4f4;
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 10px;

  .form-check-input:checked {
    background-color: #103B78;
    border-color: transparent;
  }

  .form-check-label {
    font-size: 12px;
    color: #000000;
  }
}

.off-details-banner {
  &::before {
    content: "";
    height: 260px;
    min-height: 260px;
    width: 100%;
    position: absolute;
    inset: 0px;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
    border-radius: 10px;
  }

  img.offer-detail-banner {
    width: 100%;
    object-fit: cover;
    height: 100%;
    min-height: 260px;
    max-height: 260px;
    border-radius: 10px;
  }
}

.fmteam-approval-udertaking {
  background: #ECECEC;
  border-radius: 8px;

  .sign-box {
    width: 100%;
    height: 115px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 0.475rem;
    overflow: auto;
    border: 1px solid #dfdede;

    img {
      width: auto;
      height: auto;
    }
  }
}

.sign-box1 {
  width: 100%;
  height: 115px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 0.475rem;
  border: 1px solid #dfdede;
  overflow: auto;

  img {
    width: auto;
    height: auto;
  }
}

.building-name-fcss {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.fcss-propert-code {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.fcss-open-td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 200px;
}

.approval-move {
  overflow: hidden;

  img {
    height: 175px;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    border-radius: 9px;
    border: 1px solid rgb(211, 211, 211);
  }
}

.form-check-input:checked {
  background-color: #49484C !important;
  border-color: #49484C !important;
}

.fcs-unit-number {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/// ///////////////////////

// End Prospective Tenant Styles //
.float-revert {
  float: revert;
}

.height-19 {
  height: 19px;
}

//  Button Close
button.btn-close:focus {
  outline: 0 !important;
  box-shadow: none;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.w-150 {
  width: 150px;
}

// .no-data-found-cd img {
//   height: 150px;
// }

.form-check.form-switch .form-check-input:checked {
  background-color: #49484c;
  border-color: #49484c;
}

.tenant-dashboard-marketplace {
  img {
    background-color: #fff;
    border-radius: 8px;
    height: 115px;
    width: 100%;
    object-fit: cover;
  }
}

.actionmenu-tenant {
  overflow: hidden;
  width: 185px;
}

.fcss-header-select {
  .ant-select-selector {
    background-color: transparent !important;
    border: 0 solid #d9d9d9 !important;
  }

  .ant-select-arrow {
    display: none;
  }
}

@media only screen and (min-width:991px) and (max-width:1367px) {
  .pm_unit_summary_cart {
    canvas {
      display: block;
      box-sizing: border-box;
      height: 335px !important;
      width: 335px !important;
    }
  }

  .pm_lease-summarycart {
    canvas {
      display: block;
      box-sizing: border-box;
      height: 335px !important;
      width: 335px !important;
    }
  }
}

.pm_my_team_cart {
  canvas {
    display: block;
    box-sizing: border-box;
    height: 335px;
    width: 335px;
  }
}

.prop-type-badge {
  position: absolute;
  left: 0;
  top: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 10px;
}

body .react-date-picker__calendar {
  z-index: 2;

  @media only screen and (max-width: 575.98px) {
    width: 100% !important;
    top: 42px !important;
  }

  .react-calendar__navigation button {
    @media only screen and (max-width: 575.98px) {
      min-width: 32px;
    }
  }
}

.chat-leftsidebar {
  background-color: white;
  border-radius: 7px;
  overflow: hidden;

  .chat-profile {
    padding: 30px 30px 0;
    text-align: center;
    position: relative;
    z-index: 1;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      left: 0;
      background-color: #D3E3FA;
      z-index: -1;
      height: 50%;
    }

    .chat-proimg {
      position: relative;
      display: inline-block;

      img.profile-chat {
        width: 166px;
        height: 166px;
        border-radius: 50%;
      }

      img.profile-status {
        position: absolute;
        top: 30px;
        right: 0px;
      }
    }
  }

  ul {
    padding: 0 30px;

    li {
      margin: 35px 0;

      img {
        width: 36px;
        height: 36px;
      }

      div {
        padding-left: 20px;
      }

      span {
        font-size: 15px;
        font-weight: 600;
        display: block;
      }
    }
  }
}

.payment-success {
  img {
    margin-bottom: 22px;
    height: 70px;
  }

  i.bi {
    margin-bottom: 22px;
    font-size: 60px;
    display: block;

    &.bi-info-circle,
    &.bi-exclamation-circle {
      color: #49484c;
    }

    &.bi-x-circle {
      color: #ff4d4f;
    }
  }

  h3 {
    color: #49484c;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 14px;
    color: #707070;
  }
}

.ant-spin-dot-item {
  background-color: #49484c;
}

.slick-track {
  margin-left: inherit;
  margin-right: inherit;
}

.fcss-card-number-error-msg {
  position: absolute;
  margin-top: 68px;
}

@media only screen and (min-width:320px) and (max-width:640px) {
  .my-unit-madeofpay {
    margin-bottom: 8px;
  }
}

.react-date-picker__inputGroup {
  input[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.permit-type-cd img.img-fluid {
  height: 38px;
  object-fit: contain;
  width: 38px;
}

.lang-sec {
  .switch {
    background-color: #f2f4f5;
    border-radius: 8px;
    display: inline-block;
    height: 36px;
    padding: 4px;
    position: relative;
    width: 169px;
  }

  .switch-light {
    align-items: center;
    display: block;
    justify-content: center;
    margin-left: 0;
    overflow: visible;
    padding: 3px 12px;
    position: relative;
  }

  .switch-light input {
    opacity: 0;
    position: absolute;
    z-index: 5;
  }

  .switch-light * {
    box-sizing: border-box;
  }

  .switch-light>span {
    left: -100px;
    margin: 0;
    padding-right: 100px;
    position: absolute;
    text-align: left;
    width: 100%;
  }

  .switch-light label,
  .switch-light>span {
    line-height: 20px;
    vertical-align: middle;
  }

  .switch-light>span {
    color: #000;
  }

  .switch-light label,
  .switch-light span span {
    color: #49484c;
    cursor: pointer;
    font-size: 12px;
  }

  .switch-light>span>span.active,
  .switch-light>span>span.active i {
    color: #006bb7;
  }

  .switch-light>span>span:last-child {
    left: 50%;
  }

  .switch-light>span>span {
    display: block;
    left: 0;
    margin-left: 100px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 50%;
    z-index: 5;
  }

  .switch-light input:checked~a {
    right: 0;
  }

  .btn.btn-toggle-white {
    background: #fff;
    border: 0 !important;
    border-radius: 6px;
    box-shadow: none;
    color: #7e8299;
    height: 27px;
    width: 80px;
    padding: 2px !important;
    top: -3px;
  }

  .switch-light a {
    display: block;
    height: 100%;
    padding: 0;
    position: absolute;
    right: 50%;
    top: 0;
    width: 50%;
    z-index: 4;
  }
}

.mydoc_bt_file:hover i {
  color: #fff !important;
}

.text-tune {
  color: #f6a41c;
}

// RTL Direction - Arabic Start
body[dir="rtl"] {
  text-align: right;
  overflow-x: hidden !important;

  .aside-fixed .aside {
    right: 0;
    left: inherit;
  }

  .cond-overview {
    .padding_left {
      padding-right: 60px;
      padding-left: 0;
    }
  }
  .form-add-design .form-switch .form-check-input {
    margin-right: 5px;
    margin-left: inherit;
  }
  .ms-20-ar {
    margin-right: 5rem !important;
    margin-left: inherit !important;
  }
  .mta_webpage_left .bi-check-circle-fill {
    margin-left: 0.75rem !important;
    margin-right: 0 !important;
  }
  .custom-modal .redeem-offer-percent .form-check .form-check-input[type=checkbox] {
    margin-left: 0.75rem !important;
    margin-right: 0 !important;
  }
  .upload-input .btn .bi-plus-circle-fill {
    margin-right: 0.5rem !important;
    margin-left: 0 !important;
  }
  .property-details-edit-details .form-control {
    padding-left: 0;
    padding-right: 69px;
  }
  .property-details-edit-details .prop-det-icon {
    padding-left: 0;
    padding-right: 14px;
  }
  .property-details-edit-details .prop-det-icon img {
    margin-right: 0 !important;
    margin-left: 0.75rem !important;
  }
  .fc-ss-dashboard-page .rec-request-sec .slick-slider .card img.me-5 {
    margin-left: 0 !important;
    margin-right: 1.25rem !important;
  }
  .text-end {
    text-align: left !important;
  }

  .custom-modal .modal-header .btn-close {
    left: -12px;
    right: inherit !important;
  }

  .custom-modal.create-campaign-popup.tenant-info-popup .modal-header .btn-close {
    left: -20px;
    top: -20px !important;
  }

  .modal-header .btn-close {
    margin: -0.875rem auto -0.875rem -0.875rem;
  }

  .end-0 {
    left: 0 !important;
    right: inherit !important;
  }

  .prop-type-badge {
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    right: 0;
    left: inherit;
  }

  .card-status .pending,
  .card-status .approved {
    left: -1px;
    right: inherit;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    z-index: 0;
  }

  .com-card-section.myunit-page .card-status .pending,
  .com-card-section.myunit-page .card-status .approved {
    z-index: 1;
  }

  .fc .fc-button-group {
    direction: rtl;
  }

  .fc-icon-chevron-left:before {
    content: "\e901";
  }

  .fc-icon-chevron-right:before {
    content: "\e900";
  }
.login{
    .card-body{padding: 10px 0 10px 10px !important;}
}
.login-left {
  img {
    border-radius: 8px !important;
    @media (max-width: 767px) {
      display: none;
    }
  }
}


  .login-password button {
    left: 0;
    right: inherit;
  }

  .menu-gray-600 .menu-item .menu-link .item-icon::after {
    margin-right: 0;
    margin-left: 15px;
  }

  .dashboard-page .quick-link-sec .slick-slider .slick-prev {
    left: -3px;
    right: inherit;
  }

  .dashboard-page .quick-link-sec .slick-slider .slick-next {
    left: 22px;
  }

  .fc-ss-dashboard-page .rec-request-sec .slick-slider .slick-prev,
  .upcoming-work-order-sec .slick-slider .slick-prev,
  .fc-ss-dashboard-page .market-place-sec .slick-slider .slick-prev {
    left: 10px;
    right: inherit;
  }

  .fc-ss-dashboard-page .rec-request-sec .slick-slider .slick-next,
  .upcoming-work-order-sec .slick-slider .slick-next,
  .fc-ss-dashboard-page .market-place-sec .slick-slider .slick-next {
    left: 35px;
  }
  .fc-ss-dashboard-page .market-place-sec .slick-slider .card .sch-time .bullet {
    margin-right: 0;
    margin-left: 8px;
  }
  .aside-menu .menu-item .menu-link .menu-arrow {
    transform: rotate(180deg);
  }
  .rec-payment-act-sec .pagination-sec-table {
    direction: ltr;
  }
  .chat-main-page span.chat-window-name {
    padding-right: 1.25rem !important;
    padding-left: 0 !important;
  }
  @media (min-width: 992px) {
    .header.align-items-stretch span.user-name {
      margin-right: 30px !important;
      margin-left: 15px !important;
    }
  }

  @media (min-width: 576px) {
    .dashboard-page .offset-sm-3 {
      margin-right: 25%;
      margin-left: inherit;
    }
  }

  .dashboard-page .quick-link-sec .slick-slider .card {
    margin: 20px 5px 0 17px;
  }

  .dashboard-page .announcement-sec .slick-slider .card {
    margin: 0 0px 0 17px;
  }

  .view-all img {
    transform: rotate(180deg);
  }

  .market-team-myteam-card .card .cardimg img {
    margin-left: 0.75rem !important;
    margin-right: 0 !important;
  }

  .MuiSvgIcon-root.MuiPaginationItem-icon {
    transform: rotate(180deg);
  }

  .MuiStepper-root.multiform-stepper .MuiStep-root.active:first-child {
    border-top-right-radius: 10px;
    border-top-left-radius: 0;
  }

  .MuiStepper-root.multiform-stepper .MuiStep-root.active:last-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 0;
  }

  .bi-chevron-right::before {
    transform: rotate(180deg);
  }

  .total-que-count {
    display: flex;
    justify-content: center;

    h2 {
      span {
        float: right;
      }
    }
  }

  .btn i.bi-plus-circle-fill {
    padding-left: 0.35rem;
    padding-right: 0;
  }

  img[alt=back],
  img[alt=Back] {
    transform: rotate(180deg);
  }

  .table-responsive .dropdown-menu {
    min-width: 7rem;
    text-align: right;
  }

  .form-check .form-check-input {
    float: right;
  }

  .form-check .form-check-label {
    margin-right: 25px !important;
  }

  .services-badge {
    left: 68px;
    right: inherit;
  }

  a.ShowMoreText span i {
    margin-right: 0.5rem !important;
    margin-left: 0 !important;

    &::before {
      transform: rotate(180deg);
    }
  }

  @media (min-width: 992px) {
    .ps-lg-20 {
      padding-right: 5rem !important;
    }
  }

  img.docx-icon {
    margin-left: 0.75rem !important;
    margin-right: 0 !important;
  }

  .me-2 {
    margin-left: 0.5rem !important;
    margin-right: inherit !important;
  }

  .me-1 {
    margin-left: 0.25rem !important;
    margin-right: inherit !important;
  }

  // .me-3 {
  //   margin-left: 0.75rem !important;
  //   margin-right: inherit !important;
  // }
  .me-4 {
    margin-left: 1rem !important;
    margin-right: inherit !important;
  }

  .me-5 {
    margin-left: 1.25rem !important;
    margin-right: inherit !important;
  }

  .me-6 {
    margin-left: 1.5rem !important;
    margin-right: inherit !important;
  }

  .me-7 {
    margin-left: 1.75rem !important;
    margin-right: inherit !important;
  }

  .me-8 {
    margin-left: 2rem !important;
    margin-right: inherit !important;
  }

  .me-9 {
    margin-left: 2.25rem !important;
    margin-right: inherit !important;
  }

  .me-10 {
    margin-left: 2.5rem !important;
    margin-right: inherit !important;
  }

  .me-11 {
    margin-left: 2.75rem !important;
    margin-right: inherit !important;
  }

  .me-12 {
    margin-left: 3rem !important;
    margin-right: inherit !important;
  }

  .me-13 {
    margin-left: 3.25rem !important;
    margin-right: inherit !important;
  }

  .me-14 {
    margin-left: 3.5rem !important;
    margin-right: inherit !important;
  }

  .me-15 {
    margin-left: 3.75rem !important;
    margin-right: inherit !important;
  }

  .me-16 {
    margin-left: 4rem !important;
    margin-right: inherit !important;
  }

  .me-17 {
    margin-left: 4.25rem !important;
    margin-right: inherit !important;
  }

  .me-18 {
    margin-left: 4.5rem !important;
    margin-right: inherit !important;
  }

  .me-19 {
    margin-left: 4.75rem !important;
    margin-right: inherit !important;
  }

  .me-20 {
    margin-left: 5rem !important;
    margin-right: inherit !important;
  }

  .pe-2 {
    padding-left: 0.5rem !important;
    padding-right: inherit !important;
  }

  .pe-1 {
    padding-left: 0.25rem !important;
    padding-right: inherit !important;
  }

  .pe-3 {
    padding-left: 0.75rem !important;
    padding-right: inherit !important;
  }

  .pe-4 {
    padding-left: 1rem !important;
    padding-right: inherit !important;
  }

  .pe-5 {
    padding-left: 1.25rem !important;
    padding-right: inherit !important;
  }

  .pe-6 {
    padding-left: 1.5rem !important;
    padding-right: inherit !important;
  }

  .pe-7 {
    padding-left: 1.75rem !important;
    padding-right: inherit !important;
  }

  .pe-8 {
    padding-left: 2rem !important;
    padding-right: inherit !important;
  }

  .pe-9 {
    padding-left: 2.25rem !important;
    padding-right: inherit !important;
  }

  .pe-10 {
    padding-left: 2.5rem !important;
    padding-right: inherit !important;
  }

  .pe-11 {
    padding-left: 2.75rem !important;
    padding-right: inherit !important;
  }

  .pe-12 {
    padding-left: 3rem !important;
    padding-right: inherit !important;
  }

  .pe-13 {
    padding-left: 3.25rem !important;
    padding-right: inherit !important;
  }

  .pe-14 {
    padding-left: 3.5rem !important;
    padding-right: inherit !important;
  }

  .pe-15 {
    padding-left: 3.75rem !important;
    padding-right: inherit !important;
  }

  .pe-16 {
    padding-left: 4rem !important;
    padding-right: inherit !important;
  }

  .pe-17 {
    padding-left: 4.25rem !important;
    padding-right: inherit !important;
  }

  .pe-18 {
    padding-left: 4.5rem !important;
    padding-right: inherit !important;
  }

  .pe-19 {
    padding-left: 4.75rem !important;
    padding-right: inherit !important;
  }

  .pe-20 {
    padding-left: 5rem !important;
    padding-right: inherit !important;
  }

  .ps-d-3 {
    padding-right: 0.75rem !important;
    padding-left: 0 !important;
  }

  .ms-d-5 {
    margin-right: 1.25rem !important;
    margin-left: 0 !important;
  }

  .fc-ss-dashboard-page .quick-link-sec .slick-slider .slick-list {
    padding: 0 0 0 60px !important;
  }

  .nav-line-tabs.nav-line-tabs-2x {
    padding-right: 0;
  }

  .move-out-accordion .accordion-item .accordion-button {
    text-align: right;
  }

  .accordion-item .accordion-button::after {
    margin-right: auto;
    margin-left: inherit;
  }

  .text-start {
    text-align: right !important;
  }

  .noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
    right: -17px;
    left: auto;
  }

  .noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
    right: 0;
    left: auto;
  }

  .react-tel-input .form-control {
    padding-right: 48px;
    padding-left: 0;
  }

  .react-tel-input .selected-flag .arrow {
    right: 20px;
    left: inherit;
  }

  .react-tel-input .selected-flag {
    padding: 0 8px 0 0;
  }

  .react-tel-input .country-list .country {
    display: flex;
    align-items: center;
  }

  .react-tel-input .country-list .flag {
    margin-left: 7px;
    margin-right: inherit;
  }

  .react-tel-input .country-list .country-name {
    margin-left: 6px;
    margin-right: inherit;
  }

  .react-tel-input .country-list .search {
    text-align: right;
  }

  .react-tel-input .flag-dropdown {
    border-radius: 0 5px 5px 0;
  }

  .react-tel-input .flag-dropdown.open .selected-flag {
    border-radius: 0 5px 5px 0;
  }

  .react-tel-input .selected-flag {
    border-radius: 0 5px 5px 0;
  }

  .dashboard-page .announcement-sec .slick-slider .card>.card-body>.row,
  .dashboard-page .quick-link-sec .slick-slider .card>.card-body>.row,
  .dashboard-page .market-place-sec .slick-slider .card>.card-body>.row,
  .fc-ss-dashboard-page .rec-request-sec .slick-slider .card>.card-body>.row {
    flex-direction: row-reverse;
  }

  .fc-ss-dashboard-page .rec-request-sec .slick-slider .card>.card-body>.row img.me-8 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }

  .dashboard-page .quick-link-sec.work-orders-sec .slick-slider .card>.card-body>.row,
  .fc-ss-dashboard-page .announcement-sec .slick-slider .card>.card-body>.row {
    flex-direction: inherit;
  }

  .fc-ss-dashboard-page .announcement-sec .slick-slider .slick-list {
    padding: 0 0 0 60px !important;
  }

  .dashboard-page .quick-link-sec .slick-slider .card .card-body img {
    float: right;
  }

  .form-add-design #maintanance_time {
    direction: ltr;
  }

  img[alt=go],
  img.left-arrow-back {
    transform: rotate(180deg);
  }

  .stepper.stepper-links .stepper-nav .stepper-item.current .current-step {
    padding-left: inherit !important;
    padding-right: 0.75rem !important;
  }

  .fdrr-rtl {
    flex-direction: row-reverse;
  }

  .offer-section .offer-details .user-info img {
    margin-left: 0.75rem !important;
    margin-right: 0 !important;
  }

  .offer-section .offer-icon {
    right: 10px;
    left: inherit;
  }

  .properties_list_view .card .offer_main_details .offer_main_details_div_3 p img {
    padding-left: 7px;
    padding-right: 0;
    img{
      filter: brightness(0.5);
    }
  }

  .m-details-overview .offer-per {
    margin-right: 10px;
    margin-left: 0;
  }

  .form-add-design .input-group {
    flex-direction: row-reverse;
  }

  .myteam_actionRequired .actionRequired_details .offer_main_details_div_2 p {
    padding-left: 20px;
    padding-right: 0;
  }

  .myteam_actionRequired .actionRequired_details .offer_main_details_div_2 p img {
    margin-left: 0.75rem !important;
    margin-right: 0 !important;
  }

  .myteam_actionRequired .actionRequired_details .offer_main_details_div_3 p>img {
    margin-right: 0 !important;
    margin-left: 0.75rem !important;
  }

  .myteam_actionRequired .member_name img {
    margin-left: 0.75rem !important;
    margin-right: 0 !important;
  }

  .react-date-picker__inputGroup {
    direction: ltr;
  }

  .pm-topbar-buttons.float-end {
    float: left !important;
  }

  .pm-topbar-buttons {
    input.form-control {
      padding-right: 2.5rem !important;
      padding-left: 1rem !important;
    }
  }

  .header-menu .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .header-menu .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-left: 18px;
    padding-right: 0;
  }

  .header.align-items-stretch .form-select.form-select-solid {
    background-position: left 1.2rem center !important;
  }

  .dashboard-page .com-card-section .card .view-all span {
    margin-left: 5px;
    margin-right: 0;
  }

  .dashboard-page .com-card-section .card .view-all {
    margin-left: 8px;
  }

  .upcoming-work-order-sec .slick-slider .card>.card-body>.row {
    flex-direction: row-reverse;
  }

  .upcoming-work-order-sec .slick-slider .card .sch-status>img {
    margin-right: 0.25rem !important;
    margin-left: 0 !important;
  }

  .fc-ss-dashboard-page .announcement-sec .slick-slider .card .sch-time {
    direction: ltr;
  }

  ul.nav-pills.make-offer-notification,
  ul.nav-pills.security-deposit {
    padding-left: 0;
    padding-right: 0;
  }

  ul.nav-pills.make-offer-notification li:first-child {
    margin-left: 0.3rem;
    width: 49% !important;
  }

  ul.nav-pills.make-offer-notification li {
    margin-left: 0;
    margin-right: 0 !important;
    width: 50%;
  }

  ul.nav-pills.security-deposit li:first-child,
  ul.nav-pills.security-deposit li:nth-child(2) {
    margin-left: 0.5rem;
    margin-right: 0 !important;
  }

  @media (min-width: 992px) {
    &.aside-enabled.aside-fixed.header-fixed .header.align-items-stretch span.user-name::before {
      right: 0;
      left: inherit;
    }

    &.aside-enabled.aside-fixed.header-fixed .header.align-items-stretch .start-70 {
      left: 30%;
    }
  }

  .other-srch-details .border-right:after {
    content: "";
    right: 80%;
  }

  .dashboard-page .rec-payment-act-sec table thead th:first-child,
  .dashboard-page .rec-payment-act-sec table thead .rdt_TableCol:first-child,
  .dashboard-page .rec-payment-act-sec table .rdt_TableHeadRow th:first-child,
  .dashboard-page .rec-payment-act-sec table .rdt_TableHeadRow .rdt_TableCol:first-child,
  .dashboard-page .rec-payment-act-sec .rdt_Table thead th:first-child,
  .dashboard-page .rec-payment-act-sec .rdt_Table thead .rdt_TableCol:first-child,
  .dashboard-page .rec-payment-act-sec .rdt_Table .rdt_TableHeadRow th:first-child,
  .dashboard-page .rec-payment-act-sec .rdt_Table .rdt_TableHeadRow .rdt_TableCol:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .dashboard-page .rec-payment-act-sec table thead th:last-child,
  .dashboard-page .rec-payment-act-sec table thead .rdt_TableCol:last-child,
  .dashboard-page .rec-payment-act-sec table .rdt_TableHeadRow th:last-child,
  .dashboard-page .rec-payment-act-sec table .rdt_TableHeadRow .rdt_TableCol:last-child,
  .dashboard-page .rec-payment-act-sec .rdt_Table thead th:last-child,
  .dashboard-page .rec-payment-act-sec .rdt_Table thead .rdt_TableCol:last-child,
  .dashboard-page .rec-payment-act-sec .rdt_Table .rdt_TableHeadRow th:last-child,
  .dashboard-page .rec-payment-act-sec .rdt_Table .rdt_TableHeadRow .rdt_TableCol:last-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .dashboard-page .rec-payment-act-sec table tbody tr td:first-child,
  .dashboard-page .rec-payment-act-sec table tbody tr .rdt_TableCell:first-child,
  .dashboard-page .rec-payment-act-sec table tbody .rdt_TableRow td:first-child,
  .dashboard-page .rec-payment-act-sec table tbody .rdt_TableRow .rdt_TableCell:first-child,
  .dashboard-page .rec-payment-act-sec table .rdt_TableBody tr td:first-child,
  .dashboard-page .rec-payment-act-sec table .rdt_TableBody tr .rdt_TableCell:first-child,
  .dashboard-page .rec-payment-act-sec table .rdt_TableBody .rdt_TableRow td:first-child,
  .dashboard-page .rec-payment-act-sec table .rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child,
  .dashboard-page .rec-payment-act-sec .rdt_Table tbody tr td:first-child,
  .dashboard-page .rec-payment-act-sec .rdt_Table tbody tr .rdt_TableCell:first-child,
  .dashboard-page .rec-payment-act-sec .rdt_Table tbody .rdt_TableRow td:first-child,
  .dashboard-page .rec-payment-act-sec .rdt_Table tbody .rdt_TableRow .rdt_TableCell:first-child,
  .dashboard-page .rec-payment-act-sec .rdt_Table .rdt_TableBody tr td:first-child,
  .dashboard-page .rec-payment-act-sec .rdt_Table .rdt_TableBody tr .rdt_TableCell:first-child,
  .dashboard-page .rec-payment-act-sec .rdt_Table .rdt_TableBody .rdt_TableRow td:first-child,
  .dashboard-page .rec-payment-act-sec .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .dashboard-page .rec-payment-act-sec table tbody tr td:last-child,
  .dashboard-page .rec-payment-act-sec table tbody tr .rdt_TableCell:first-child,
  .dashboard-page .rec-payment-act-sec table tbody .rdt_TableRow td:last-child,
  .dashboard-page .rec-payment-act-sec table tbody .rdt_TableRow .rdt_TableCell:first-child,
  .dashboard-page .rec-payment-act-sec table .rdt_TableBody tr td:last-child,
  .dashboard-page .rec-payment-act-sec table .rdt_TableBody tr .rdt_TableCell:first-child,
  .dashboard-page .rec-payment-act-sec table .rdt_TableBody .rdt_TableRow td:last-child,
  .dashboard-page .rec-payment-act-sec table .rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child,
  .dashboard-page .rec-payment-act-sec .rdt_Table tbody tr td:last-child,
  .dashboard-page .rec-payment-act-sec .rdt_Table tbody tr .rdt_TableCell:first-child,
  .dashboard-page .rec-payment-act-sec .rdt_Table tbody .rdt_TableRow td:last-child,
  .dashboard-page .rec-payment-act-sec .rdt_Table tbody .rdt_TableRow .rdt_TableCell:first-child,
  .dashboard-page .rec-payment-act-sec .rdt_Table .rdt_TableBody tr td:last-child,
  .dashboard-page .rec-payment-act-sec .rdt_Table .rdt_TableBody tr .rdt_TableCell:first-child,
  .dashboard-page .rec-payment-act-sec .rdt_Table .rdt_TableBody .rdt_TableRow td:last-child,
  .dashboard-page .rec-payment-act-sec .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .float-end {
    float: left !important;
  }

  .properties_buttons .card_list_view .card_view {
    border-top-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 5px;
  }

  .properties_buttons .card_list_view .list_view {
    border-top-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 0;
  }

  .properties_card_view .card .property_card_Details .offer_main_details_div_2 p img {
    padding-left: 7px;
    padding-right: 0;
  }

  .properties_card_view .card .property_card_Details .offer_main_details_div_3 p img {
    padding-left: 7px;
    padding-right: 0;
  }

  .prop_details_facilities .offer_main_details_div_2 p img {
    padding-left: 7px;
    padding-right: 0;
  }

  .galery_img img {
    margin-left: 0.75rem !important;
    margin-right: 0 !important;
  }

  .galery_img_main img {
    margin-left: 0.75rem !important;
    margin-right: 0 !important;
  }

  .offer_main_details_div_3{
    p{
      img{
        filter: grayscale(1);
      }
    }
  }

  .properties_add_bt>a>i {
    padding-left: 0.35rem;
    padding-right: 0;
  }

  .upload-image {
    margin: 10px 0 0 20px;
  }

  .dashboard-page .uploadbt {
    margin-right: 0.75rem !important;
    margin-left: 0 !important;
  }

  .form-add-design .form-check.custom.float-end {
    padding-right: 2.25rem !important;
    padding-left: 0 !important;
  }

  .form-add-design .form-select {
    padding-left: 3rem;
    padding-right: 1rem;
    background-position: 12px center;
  }

  .mta_team_members_card .offer_main_img img {
    margin-left: 0.75rem !important;
    margin-right: 0 !important;
  }

  button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit {
    transform: rotate(180deg);
  }

  span.fc-icon.fc-icon-chevron-right {
    transform: rotate(180deg);
  }

  span.fc-icon.fc-icon-chevron-left {
    transform: rotate(180deg);
  }
}

@media (min-width: 992px) {
  body[dir="rtl"] {
    .aside {
      margin-right: 15px;
      margin-left: inherit;
      right: 0;
      left: inherit;
    }

    &.aside-enabled.aside-fixed .wrapper {
      padding-right: 265px !important;
      padding-left: 0;
    }

    &.aside-enabled.aside-fixed.header-fixed .header {
      right: 265px;
      left: 0;
    }
  }
}

// RTL Direction - Arabic End

.doc-view-bt {
  position: absolute;
  //left: 128px;
  bottom: 0;
  top: -84px;  
  //z-index: 999;
  button{
    z-index: 888;
    display: block;
    position: relative;
  }
  &.marketing{
    top: 0
  }
}
.upload-name{
  background: #F3F3F3;
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 999;
  &.marketing{
    top: 80px;
    bottom: unset;
  }
}
.upload-img{
  position: absolute;
  height: 118px;
  width: 100%;
  top: -34px;
  bottom: 0;
  overflow: hidden;
  border-radius: 8px;
  &::after{
    content: ""; 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(120deg, #000, #000);
    opacity: .7;
  }
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;    
  }
}



.action-dropdown {
  min-width: 12rem;
}

.react-calendar__tile--active {
  background: #49484c;
  color: #fff !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #49484c !important;
  border-color: #49484c !important;
}

.ant-checkbox-checked::after {
  border: 1px solid #49484c;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #49484c;
}

.visit-confirmed-date-res {
  @media only screen and (max-width: 575.98px) {
    p.font-17 {
      font-size: 12px !important;
      margin-bottom: 2px !important;
    }

    .visit-confirmed {
      margin-right: 3px !important;
    }

    p {
      font-size: 11px;
    }
  }
}

#reschedule-popup {
  @media only screen and (max-width: 575.98px) {
    .modal-dialog {
      margin: 2rem 0.5rem;
    }

    .modal-footer {
      justify-content: center;
    }

    .modal-footer>* {
      margin: 0;
    }
  }
  @media only screen and (max-width: 767.98px) {
    .modal-footer > .btn {
      width: 100%;
      display: block;
      margin-top: 10px;
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }
}

.custom-modal {
  .modal-header .btn-close {
    @media only screen and (max-width: 575.98px) {
      margin: 15px;
      width: 25px;
      height: 25px;
      font-size: 22px;
      line-height: 20px;
    }
  }

  .modal-header .modal-title {
    @media only screen and (max-width: 575.98px) {
      font-size: 16px;
    }
  }
}

.logout-popup {
    .modal-dialog{
        max-width: 460px;
    }
  .modal-header{
    background-color: #fff;
    justify-content: left;
    padding: 15px 25px;
    .modal-title{
      color: #000;
    }
    .btn-close{
      right: 26px;
      top: 30px;
    }
  }
  img{
    width: 112px;
    height: 112px;
  }
  p{
    color:#5E5E5E;
  }
  button{
    width: 46%;
  }
  @media only screen and (max-width: 575.98px) {
    h1 {
      font-size: 16px;
    }

    .btn {
      height: 40px;
    }
  }
}

.no-data-found-cd {
  span.font-18 {
    @media only screen and (max-width: 767.98px) {
      font-size: 15px !important;
    }
  }
}

.offer-price-sec {
  @media only screen and (max-width: 767.98px) {
    padding: 16px;
    background: #EFEFEF;
    color: #000000;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .text-sub-title,
    h1 {
      margin-bottom: 0 !important;
      font-size: 14px !important;
    }

    p {
      font-size: 14px !important;
    }
  }
}

.mainCard_d_etails_div_2.d-flex {
  @media only screen and (max-width: 767.98px) {
    flex-wrap: wrap;
  }
}

.notification_main_card {
  .notif-title {
    @media only screen and (max-width: 767.98px) {
      font-size: 13px !important;
    }
  }

  .notif-desc {
    @media only screen and (max-width: 767.98px) {
      font-size: 13px !important;
      margin-bottom: 0 !important;
    }
  }

  .notif-time {
    @media only screen and (max-width: 767.98px) {
      font-size: 12px !important;
    }
  }

  .notification_inner_div button {
    @media only screen and (max-width: 767.98px) {
      padding: 8px 20px !important;
      font-size: 12px;
    }
  }
}

.card.no-data-found-cd span.text-tune {
  @media only screen and (max-width: 767.98px) {
    font-size: 13px !important;
    margin-top: 5px;
  }
}

.card.no-data-found-cd span.font-22 {
  @media only screen and (max-width: 767.98px) {
    font-size: 14px;
    margin-top: 15px;
  }
}

.com-card-section .quick-link-sec .card-title {
  @media only screen and (max-width: 767.98px) {
    font-size: 13px;
  }
}

.com-card-section .announcement-sec .card-title,
.com-card-section .market-place-sec .card-title {
  @media only screen and (max-width: 767.98px) {
    font-size: 14px;
  }
}

.move-in-main-div h2,
.move-in-main-div .h2 {
  @media only screen and (max-width: 767.98px) {
    font-size: 15px;
  }
}

.pm-offers-and-renewals .card .offer_main_details {
  p.text-short {
    @media only screen and (max-width: 767.98px) {
      font-size: 14px !important;
    }
  }

  h1 {
    @media only screen and (max-width: 767.98px) {
      font-size: 14px;
    }
  }
}

.left-arrow-back {
  @media only screen and (max-width: 767.98px) {
    width: 14px;
    height: 14px;
  }
}

.makeon-offer-div p.font-17 {
  @media only screen and (max-width: 767.98px) {
    font-size: 14px !important;
  }
}

#dynamic-popup .redeem-offer-QR h1 {
  @media only screen and (max-width: 767.98px) {
    font-size: 16px;
    line-height: 23px;
  }
}

#dynamic-popup .redeem-offer-QR img,
.thankyou-res img {
  @media only screen and (max-width: 767.98px) {
    width: 38px;
  }
}

@media only screen and (max-width: 767.98px) {
  .notif-popup .modal-body {
    padding: 15px;

    .make-offer-notification .tab_nav_li {
      width: inherit;
      flex: 1 1 0;

      .nav-link {
        font-size: 13px;
        padding: 10px !important;
      }
    }

    .security-deposit .tab_nav_li {
      width: inherit;
      flex: 1 1 0;

      .nav-link {
        font-size: 12px;
        padding: 10px 8px !important;

        .icons {
          width: 27px;
          height: 27px;

          i {
            font-size: 13px !important;
          }
        }
      }
    }
  }

  .fc-wo-checkbox {
    width: 16px;
    height: 16px;
  }

  .com-card-section .card-body.p-7,
  .com-card-section .card-body.px-8.py-6 {
    padding: 15px !important;
  }

  .gray-card h4.font-15 {
    font-size: 14px;
    font-weight: 500;
  }

  .create-campaign-popup.tenant-info-popup {
    .modal-dialog .modal-content {
      .modal-header {
        .btn-close {
          right: 0;
          top: 0;
          margin: 0;
        }

        .modal-title {
          margin-bottom: 12px;
        }
      }

      .modal-body .card .card-body {
        padding: 15px 8px;

        h5.font-16 {
          font-size: 14px;
        }
      }

      .MuiStepper-root.multiform-stepper {
        overflow-y: hidden;
        overflow-x: auto;
      }

      .MuiStepper-root.multiform-stepper .MuiStep-root .MuiStepLabel-root .MuiStepLabel-labelContainer {
        width: 100% !important;
        margin: 12px 0;
      }

      .MuiStepper-root.multiform-stepper .MuiStepConnector-root .MuiStepConnector-line {
        position: inherit;
        width: 80px;
        left: 0;
        right: 0;
      }
    }
  }

  #view-popUp .modal-body {
    padding-top: 50px !important;
  }

  #view-popUp .modal-body iframe {
    width: 100% !important;
  }

  .form-add-design #maintanance_time .btn-check:checked+.btn-outline-primary {
    color: #fff !important;
  }

  .form-add-design #maintanance_time .btn-check:checked+.btn-outline-primary::before {
    background-color: #fff;
    border: 1px solid #fff;
  }

  .thankyou-res {
    p.font-16 {
      font-size: 14px;
    }
  }

  .symbol span.user-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 75px;
    display: inline-block;
    vertical-align: middle;
  }

  .pt-my-links {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .move-in-main-div {
    h6 {
      line-height: 20px;
    }
  }
  .Doughnut-Chart .inner-text.res-767 {
    top: 14%;
  }
}

#view-popUp{
  .modal-dialog{
    overflow:hidden;
  }
}

@media only screen and (max-width: 575.98px) {
  .section-w-res {
    .btn {
      width: 100% !important;
      margin-bottom: 8px !important;
      padding: 9px !important;
    }
  }
}

.cond-overview {
  .padding_left {
    padding-left: 60px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #000000;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;

    @media only screen and (max-width: 767.98px) {
      font-size: 14px;
    }
  }

  p {
    color: #7d7d7d;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 30px;

    ul li {
      list-style-type: disc;

      &.mb-25 {
        margin-bottom: 25px;
      }
    }
  }
}

.terms-con-popup .modal-dialog-scrollable .modal-content {
  overflow: initial;
}
.hide-mobile {
  display: none !important;
}
.drawer.drawer-end {
  left: inherit;
  transform: inherit;
  right: inherit;
}
@media (max-width: 575.98px) {
  .header .header-menu.mobile-header-menu {
    display: block;
    position: fixed;
    left: 0;
    top: 49px;
    right: 0;
    .menu.menu-lg-row {
      justify-content: center;
      background-color: #fff;
      padding-top: 0;
      padding-bottom: 4px;
      .form-select.form-select-solid {
        font-size: 13px;
        height: 40px;
        max-width: 75%;
        margin: auto;
        background-size: 11px 11px !important;
      }
    }
  }
}
@media  (min-width:575.98px) and (max-width: 991.98px) {
  .header .header-menu.mobile-header-menu {
    display: block;
    margin: auto;
    .menu.menu-lg-row {
      justify-content: center;
      .form-select.form-select-solid {
        font-size: 13px;
        height: 40px;
        background-size: 11px 11px !important;
      }
    }
  }
}
@media  (min-width:575.98px) and (max-width: 767.98px) {
  .content {
    padding: 15px 0 !important;
  }
}
.welcome-banner-sec {
  .slick-next, 
  .slick-prev {
    top: 25px;
    z-index: 10;
  }
  .slick-next {
    right: 0;
  }
  .slick-prev {
    left: -25px;
    right: 27px;
    left: inherit;
  }
}
.grid-match>* {
  display: flex;
  flex-wrap: wrap;
}
.grid-match>*>:not([class*=w]) {
  box-sizing: border-box;
  width: 100%;
  flex: auto;
}
iframe .Cx-MuiFab-primary {
  background-color: #49484c !important;
  border-color: #49484c !important;
}
.Cx-MuiFab-primary:hover,
.Cx-MuiFab-primary:focus {
  background-color: #194078 !important;
  border-color: #194078 !important;
}
.mxg-header-main,
.mxg-message-bubble.mxg-inbound {
  background-color: #49484c !important;
}
.Cx-MuiCircularProgress-colorPrimary {
  color: #49484c !important;
}
.btn-block {
  display: block;
  width: 100%;
}

 .login-main{
    position: relative;
 }

 .login-main input.form-control:hover{
     outline: 1px solid rgb(0 44 106 / 25%);
     box-shadow: 0 0 0 0.25rem rgb(0 44 106 / 25%) !important;
 }
 .login-main input:focus{background: #ffffff}
 .login-main input:focus ~ .floating-label,
 .login-main  input:not(:placeholder-shown) + label{
       top: -10px !important;
       /* bottom: 9px; */
       left: 20px ;
       font-size: 12px;
       opacity: 1;
       background: #ffffff;
       padding: 0 2px;
   }

 .login-main .floating-label {
   position: absolute;
   pointer-events: none;
   left: 20px ;
   top: 13px !important ;
   transition: 0.2s ease all;
 }
 .card:hover {
   box-shadow: 0px 0px 10px 0px rgb(76 87 125 / 12%);
 }

.login-password button {
    right: 8px;
  }
  .banner {
    .banner-inner {
      .banner-img {
        width: 100%;
        max-width: 250px;
        border-radius: 4px;
      }
    }
  }
  .btn.btn-dark {
    background-color: #000;
    border-color: #000;
  }
  .move-in-main-div {
    img {
      width: 100%;
      max-width: 68px;
      height: 68px;
      border-radius: 4px;
      object-fit: contain;
    }
  }
  .text-gray-01 {
    color: #82868C;
  }
  .text-gray-02 {
    color: #3A3C40;
  }
  .lease-card:hover {
    box-shadow: none;
  }
  .text-black {
    color: #000;
  }
  .dash-table {
    &.table {
      tr {
        border-bottom: 1px solid #E3E3E3;
        td {
          color: #000000;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: left;
          padding: 6px 8px;
        }
        &.last-child {
          border: none;
        }
      }
    }
  }
  .my-unit-address {
    min-height: 82px;
    h6 {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #000000;
    }
    p {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(0, 0, 0, 0.4);
      margin-bottom: 0;
    }
  }
  .my-unit-address-owner{
    min-height: 82px;
    h6 {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #000000;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      color: rgba(0, 0, 0, 0.4);
      margin-bottom: 5px;
    }
  }
  .border-bottom-1 {
    border-bottom: 1px solid rgba(29, 29, 31, 0.29)!important;
}
@media (min-width: 992px) {
.aside-enabled.aside-fixed.header-fixed .header {
    left: 0 !important;
    transition: left 0.3s ease;
    .container-fluid {
      border-radius: 0px;
    }
}
.aside-fixed .aside {
  position: fixed;
  top: calc(15px + var(--kt-toolbar-height)) !important;
  bottom: 0;
  left: 0;
  z-index: 101;
  overflow: hidden;
}
}
.top-logo {
  max-width: 265px;
  width: 100%;
}
.aside-menu .menu-item .menu-icon {
  justify-content: center !important;
  width: 30px;
  height: 30px;
  background-color: rgba(91, 90, 94, 0.10);
  border-radius: 4px;
}
.aside-menu .menu-item span.menu-icon::after {
  opacity: 0.6;
}
.aside.aside-dark .menu .menu-item .menu-link.active span.menu-icon, .aside.aside-dark .menu .menu-item .menu-link:hover span.menu-icon {
  background-color: rgba(255, 163, 0, 0.10);
}
.banner-title {
  h2 {
    font-size: 48px;
    font-weight: 600;
    line-height: 72px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #4D4D4D;
    margin-bottom: 0;
  }
}
.banner-inner {
  background-image: url("../../../assets/images/banner-gradient.png") !important;
  background-repeat: no-repeat;
  padding: 25px;
  border-radius: 20px;
}
.notify-icon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: -6px;
    right: 5px;
    border-radius: 50%;
    bottom: 0;
  span {
    font-size: 10px;
    color: #fff;
    background-color: #F6A41C !important;
  }
}
.symbol.symbol-md-40px > img {
  -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
  clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
}

.topbar-poligon{
  display: inline-block;
  position: relative;
  width: 50.45px;
  height: 50.45px;
  background: #F7B23E;
  box-sizing: border-box;
  -webkit-clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
  clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
  img{
    position: absolute;
    top: 4px;
    left: 4px;
    width: 42.95px;
    height: 42.95px;
    -webkit-clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
    clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
  }
}
.d-contents{
  display:contents;
}
.my-alert-image {
  img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    height: 243px;
    max-height: 243px;
    border-radius: 8px;
  }
}
.alert-text {
  .d-flex {
    margin-bottom: 15px;
  }
  h6 {
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;
    text-align: left;
    color: #676767;
    margin-bottom: 0;
    flex: 1;
  }
  p{
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-bottom: 0;
    flex: 1;
    height: 37px;  
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    }
}
span.square-feet {
  display: flex;
  align-items: center;
  color: #000;
  img {
    margin-right: 10px;
  }
}
.upcoming-work-order-sec.my-visits-sec .card .card-body {
.property-overlay {
  position: absolute;
  bottom: 5px;
  left: 5px;
  right: 5px;
  color: #fff;
  z-index: 1;
  padding: 10px;
  h6 {
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 0;
  }
  .price-amount {
    color: #FFFFFF;
  }
}
}

.nav-line-tabs .nav-item:first-child .nav-link{
    margin-left: 0 !important;
}
.like-icon {
    position: absolute;
    top: 15px;
    right: 8px;
}
.noUi-connect {
  background: #F3B521;
}
body {
  .aside-dark .hover-scroll-overlay-y:hover::-webkit-scrollbar-thumb {
  background-color: #f1bc00;
  }
}
.my-unit-image {
    max-height: 150px;
    object-fit: cover;
}
.image-preview-box {
.img-preview {
width: 100%;
max-width: 100%;
object-fit: cover;
height: 178px;
}
&::after {
  content: "";
  position: absolute;
  left: 0; 
  right: 0;
  top: 0; 
  bottom: 0;
border-radius: 4px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.16) 0%, #000000 131.3%);
}
.image-preview {
  position: absolute;
  left: 0; 
  right: 0;
  top: 0; 
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
}
[title^="Vacant"] {
  color: #50cd89;
}
.text-theme-pdf {
  color: #F70000 !important;
}
[title^="Occupied"] {
  color: #7239ea;
}
[title^="Under Construction"] {
  color: #ffc700;
}
[title^="Cleared"] {
  color: #3DBC06;
}
[title^="Upcoming"] {
  color: #E6B906;
}
[title^="In progress"] {
  color: #ffc700 !important;
}
[title^="Open"] {
  color: #f1416c !important;
}
[title^="Closed"] {
  color: #50cd89 !important;
}
.dev-border {
  position: relative;
  border: 1px solid #ddd;
  margin: 10px 0 20px;
  button {
    position: absolute;
    right: -5px;
    top: -18px;
  }
}

.step_tab {
  .nav-item {
    .nav-link{
      &.active{
        background-color: #DFDFDF; 
        &::before {
          content: " ";
          position: absolute;
          border-top: 25px solid transparent;
          border-bottom: 23px solid transparent;
          left: 100%;
          border-left: 11px solid #DFDFDF;
          top: 0;
          bottom: 0;
        }
      }
    }
    &:first-child {
      .nav-link {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }

  }
}
.community-wall-card{
    background-color: #F5F5F5;
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    padding: 10px;
    &:hover{
      box-shadow: 0px 0px 10px 0px rgba(76, 87, 125, 0.12);
    }

}
.cpt-tenant-det1{
  .nav-item {
    width: auto;
  }
  .nav-pills .nav-link {
    color: #A4A6AD;
    font-size: 15px;
  }
  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #070707;
    background-color: transparent;
    border-bottom: 4px solid #ff9f40;
    border-radius: 0;
    font-size: 15px;
  }
}
.replace .modal-header .btn-close {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ffffff;
    color: #000;
    position: absolute;
    right: 24px;
    top: 24px;
    opacity: 1;
    padding: 0;
    font-size: 18px;
    z-index: 10;
    line-height: 1.25;
    background-image: inherit;
}
.cheques .table thead {
  color: #000;
  background-color: #F6F6F6;
  border-radius: 10px;
  height: 24px;
  line-height: 34px !important;
  min-height: 47px !important;
  font-weight: 600;
  font-size: 14px;
}
.cheques .table tr {
  background-color: #F6F6F6;
  border-radius: 10px;
  height: 24px;
  line-height: 34px !important;
  min-height: 47px !important;
}
.accodion-tab {
  background-color: #efefef;
  border-radius: 0.35rem !important;
  margin-bottom: 20px;
  padding: 1rem 1.5rem !important;
  font-size: 1rem;
  font-weight: 600;
}
.border-dashed-black {
  background-color: #f8f9fc;
  border: 1px dashed;
  border-radius: 10px;
  margin: 10px 0;
  padding: 15px;
  .previewimg {
    img {
      height: 100px;
      object-fit: cover;
      width: 100px;
    }
    span {
      background: red;
      border-radius: 50px;
      color: #fff;
      cursor: pointer;
      font-size: 12px;
      font-weight: 700;
      height: 20px;
      text-align: center;
      width: 20px;
  }
  } 
  .upload-btn-wrapper {
    align-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    height: 100px;
    justify-content: center;
    position: relative;
    width: 100px;
    .font-44 {
      font-size: 44px;
    }
    input[type=file] {
      bottom: 0;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
.custom-antdropdown-menu {
  min-width: 120px;
}
.dot-increase-20{
  font-size: 20px;
}